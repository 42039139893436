import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CandidateLoginForm from '../../components/CandidateLoginForm'

const CandidateLogin = () => {

    {
        /**
            Purpose: The purpose of this program is to show Candidate login Page
            Author:   Arslan Mukhtar
            Date Created:    2022-12-19
            Last Date Modified:  2022-12-22
            Modified By: Arslan Mukhtar
        */
    }

    return (
        <>
            <Header bgColor={false} />
            <CandidateLoginForm />
            <CallToActionBanner bgColored={false} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#143975" />
            <Footer />
        </>
    )
}

export default CandidateLogin