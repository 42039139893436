import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Footer from '../../components/Footer'
import DashboardHeader from '../../components/DashboardHeader'
import ProfileSection from '../../components/ProfileSection'
import ProtectedRoute from '../../components/ProtectedRoute'

const Profile = () => {
    return (
        <>
            <ProtectedRoute>
                <DashboardHeader bgColor={true} />
                <ProfileSection />
                <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default Profile