import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ContactUsForm from '../../components/ContactUsForm'
import { useNavigate } from 'react-router-dom'
import ProtectedRoute from '../../components/ProtectedRoute'

const ThankYou = () => {

    const navigate = useNavigate()

    return (
        <>
            <ProtectedRoute>
                <Header bgColor={true} />
                <div className='main-container'>
                    <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
                        <h1 className='primary-heading-color text-center font-family-700 font-medium mb-5'>
                            Job applied successfully!
                        </h1>
                        <p className='secondary-heading-color block w-full text-center font-family-500 font-normal'>
                            Thank you for applying for the job. Our team will get back to you.
                        </p>
                        <a
                            onClick={() => {
                                navigate('/cart')
                            }}
                            className='flex align-middle mt-5 justify-center px-22 rounded-full w-max border call-to-action-border-color bg-white mx-auto cursor-pointer h-11'>
                            <button className='call-to-action-color  text-center text-xl font-family-500 font-medium'>
                                Go back to Cart
                            </button>
                        </a>
                    </div>
                </div>
                <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default ThankYou