import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
import BannerImageMobile from '../assets/images/banner-mobile.png';
import { BiLoaderAlt } from 'react-icons/bi'
import axios from '../api/axios';
const ForgotPasswordForm = () => {
    const [username, setUsername] = useState("")
    const [apiResponse, setApiResponse] = useState("");
    const [loader, setLoader] = useState(false)
    const [userNameError, setUserNameError] = useState("")
    const [companyEmails, setCompanyEmails] = useState([]);

    const navigate = useNavigate()

    const FORGOT_PASSWORD = "/forgot_password"
    const COMPANY_EMAILS_API = "/dd";
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    const fetchCompanyEmails = async () => {
        try {
            const response = await axios.get(COMPANY_EMAILS_API);
            //   console.log("response?.data ", response?.data);
            setCompanyEmails(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCompanyEmails()
    }, [])

    const resetPassword = async () => {
        if (userNameError == "") {
            setLoader(true)
            const obj = {
                "email": username
            }
            try {
                const response = await axios.post(
                    FORGOT_PASSWORD,
                    obj,
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                console.log('response ', response)
                if (!response.data.error) {
                    setApiResponse(response?.data?.msg)
                } else {
                    setApiResponse(response?.data?.msg)
                }
            } catch (error) {
                console.log(error);
            }


            setTimeout(() => {
                setLoader(false)
                setApiResponse("")
            }, 3000)
        }
    }

    const validateEmail = () => {
        const address = username.split("@").pop();
        if (username == "") {
            setUserNameError("Email can not be empty")
        } else if (!regex.test(username)) {
            setUserNameError("Email must be a valid Email Address")
        }
        //  else if (companyEmails?.includes(address)) {
        //     setUserNameError("Email must be a valid Email Address")
        // }
        else {
            setUserNameError("");
        }
    }


    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-10 md:pt-16 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-5 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" className='xl:block lg:block md:block sm:hidden hidden' />
                    <img src={BannerImageMobile} alt="" className='xl:hidden lg:hidden md:hidden sm:block block' />
                </div>
                <div className="content flex align-middle xl:w-2/5 lg:w-1/2 md:w-11/12 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:mb-9 lg:mb-9 md:mb-9 sm:mb-5 mb-5 text-center font-family-700 font-extrabold'>
                        Forgot Password
                    </h1>
                    <div className={`flex relative flex-col align-middle justify-between ${userNameError == "" ? "xl:mb-6 lg:mb-6 md:mb-6 sm:mb-5 mb-5" : ""} items-center w-full gap-0`}>
                        <input
                            type="email"
                            autoComplete='off'
                            className={`form-control font-family-700 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${userNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}
                            id="email"
                            value={username}
                            onBlur={validateEmail}
                            placeholder={userNameError != "" ? userNameError : "Email"}
                        />
                        {
                            userNameError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        userNameError ?
                            <>
                                <span className={`text-red-600 ${userNameError == "" ? "" : "xl:my-4 lg:my-4 md:my-4 sm:my-4 my-4"} block font-family-500 font-medium text-sm`}>
                                    {
                                        userNameError
                                    }
                                </span>
                            </>
                            : ""
                    }
                    <button
                        onClick={resetPassword}
                        disabled={userNameError || loader}
                        className='primary-bg-color w-full rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Submit
                        {
                            loader ?
                                <BiLoaderAlt className='loader-spinner ml-2' />
                                :
                                null
                        }
                    </button>
                    {
                        apiResponse ?
                            <span className='text-red-600 text-sm font-family-500 font-medium py-3 block w-full'>
                                {apiResponse}
                            </span>
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordForm