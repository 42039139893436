import React from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Footer from '../../components/Footer'
import PostAJobForm from '../../components/PostAJobForm'
import DashboardHeader from '../../components/DashboardHeader'
import ProtectedRoute from '../../components/ProtectedRoute'


const PostAJob = () => {
    return (
        <ProtectedRoute>
            <DashboardHeader bgColor={true} />
            <PostAJobForm />
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </ProtectedRoute>
    )
}

export default PostAJob