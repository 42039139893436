import React, { Component, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
import BannerImageMobile from '../assets/images/banner-mobile.png';
import axios from '../api/axios';
import { BiLoaderAlt } from 'react-icons/bi'
const EmployerLoginForm = () => {
    const LOGIN_URL = "/login";
    const [username, setUsername] = useState("")
    const [userNameError, setUserNameError] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)
    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    const handleUserNameChange = (e) => {
        setUsername(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }


    const validateUserName = () => {
        console.log(regex.test(username))
        if (username == "") {
            setUserNameError("Username can not be empty")
        } else if (!regex.test(username)) {
            setUserNameError("Username must be a valid Email Address")
        } else {
            const spacesRemoved = username.replace(/\s/g, '')
            setUsername(spacesRemoved)
            setUserNameError("")
        }
    }

    const validatePassword = () => {
        if (password == "") {
            setPasswordError("Password can not be empty")
        }
        // else if (password.length < 6) {
        //     setPasswordError("Password must be at least 6 characters long")
        // }
        else {
            setPasswordError("")
        }
    }

    const loginWithAPi = async () => {
        validateUserName()
        validatePassword()
        if (userNameError == "" && passwordError == "") {
            setLoader(true)
            try {
                let post_data = {
                    "email": username,
                    "password": `A${password}`
                }

                const response = await axios.post(
                    LOGIN_URL,
                    JSON.stringify(post_data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                console.log("response", response)
                if (!response.data.error) {
                    const obj = {
                        userRole: "2",
                        token: response.data.token
                    }
                    localStorage.setItem('userInfo', JSON.stringify(obj))
                    navigate('/employer-dashboard')
                } else {
                    if (response?.data?.error?.password) {
                        setApiResponseError(response?.data?.error?.password)
                    }
                }
            } catch (err) {
                console.log('error ', err.response.data.message)
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
            setTimeout(() => {
                setLoader(false)
                setApiResponseError("")
            }, 10000)
        }
    }

    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-10 md:pt-16 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-5 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" className='xl:block lg:block md:block sm:hidden hidden' />
                    <img src={BannerImageMobile} alt="" className='xl:hidden lg:hidden md:hidden sm:block block' />
                </div>
                <div className="content flex align-middle xl:w-2/5 lg:w-1/2 md:w-11/12 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:mb-9 lg:mb-9 md:mb-9 sm:mb-5 mb-5 text-center font-family-700 font-extrabold'>
                        Sign In
                    </h1>
                    <div className={`relative  ${userNameError == "" ? "xl:mb-6 lg:mb-6 md:mb-6 sm:mb-5 mb-5" : ""} `}>
                        <input
                            type="text"
                            autoComplete='off'
                            className={`form-control font-family-500 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border-solid ${userNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-white secondary-heading-color  focus:border-white"} border  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                handleUserNameChange(e)
                                // setUsername(e.target.value)
                            }}
                            id="username"
                            onBlur={validateUserName}
                            value={username}
                            placeholder={"Username"}
                        />
                        {
                            userNameError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        userNameError ?
                            <>
                                <span className={`text-red-600 ${userNameError == "" ? "" : "xl:my-4 lg:my-4 md:my-4 sm:my-4 my-4"} block font-family-500 font-medium text-sm`}>
                                    {
                                        userNameError
                                    }
                                </span>
                            </>
                            : ""
                    }
                    <div className={`relative ${passwordError == "" ? "xl:mb-6 lg:mb-6 md:mb-6 sm:mb-5 mb-5" : ""}`} >
                        <input
                            type="password"
                            className={`form-control font-family-500 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid ${passwordError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-white secondary-heading-color  focus:border-white"} rounded-full transition ease-in-out focus:bg-whitefocus:outline-none`}
                            onChange={(e) => {
                                handlePasswordChange(e)
                            }}
                            id="password"
                            onKeyUp={(e) => {
                                if (e.key == "Enter" || e.keyCode === 13) {
                                    loginWithAPi()
                                }
                            }}
                            onBlur={validatePassword}
                            value={password}
                            autoComplete='off'
                            placeholder={"Password"}
                        />
                        {
                            passwordError ?
                                // <span className='text-red-600 text-sm font-family-500 pb-3 font-medium block w-full'>
                                //     {passwordError}
                                // </span>
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        passwordError ?
                            <>
                                <span className={`text-red-600 ${passwordError == "" ? "" : "xl:my-4 lg:my-4 md:my-4 sm:my-4 my-4"} block font-family-500 font-medium text-sm`}>
                                    {
                                        passwordError
                                    }
                                </span>
                            </>
                            : ""
                    }
                    <div className="flex align-middle items-center xl:mb-10 lg:mb-10 md:mb-10 sm:mb-5 mb-5">
                        <label className="relative flex align-middle justify-center items-center custom-checkbox">
                            <input type="checkbox" onChange={(e) => {
                                setRememberMe(e.target.checked)
                            }} value={rememberMe} />
                            <span className="checkmark"></span>
                            <span className="form-check-label xl:leading-none lg:leading-none md:leading-none sm:leading-none leading-tight pl-9 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 font-medium inline-block text-white" htmlFor="rememberMe">
                                Remember me
                            </span>
                        </label>
                        {/* <div className="form-group flex align-middle items-center form-check">
                            <input
                                type="checkbox"
                                className="form-check-input h-5 w-5 border border-white rounded-sm bg-transparent checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-300 align-top bg-no-repeat bg-center bg-contain float-left mr-3 cursor-pointer"
                                id="rememberMe"
                                onChange={(e) => {
                                    setRememberMe(e.target.checked)
                                }}
                                checked={rememberMe}
                            />
                            <label className="form-check-label text-lg font-family-500 font-medium inline-block text-white" htmlFor="rememberMe">Remember me</label>
                        </div> */}
                    </div>
                    <button onClick={loginWithAPi} disabled={userNameError || passwordError || loader} className='primary-bg-color disabled:opacity-80 disabled:cursor-not-allowed mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Sign In
                        {
                            loader ?
                                <BiLoaderAlt className='loader-spinner ml-2' />
                                :
                                null
                        }
                    </button>
                    {
                        apiResponseError ?
                            <span className='text-red-500 text-sm font-family-500 font-medium pb-3 block w-full'>
                                {apiResponseError}
                            </span>
                            :
                            ""
                    }
                    <a className='text-white w-full text-center xl:mb-2 lg:mb-2 md:mb-2 sm:mb-3 mb-3 block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                        Don’t have an account?
                        <span onClick={() => {
                            navigate('/employer-signup')
                        }} className='secondary-link-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                            Sign up
                        </span>
                    </a>
                    <a onClick={() => {
                        navigate('/forgot-pass')
                    }} className='secondary-link-color block text-center w-full mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                        Forgot password?
                    </a>
                </div>
            </div>
        </div>
    )
}

export default EmployerLoginForm