import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CanadaLogo from '../assets/images/canada.png'
import USALogo from '../assets/images/usa.png'
import PakistanLogo from '../assets/images/pakistan.png'
import MacedoniaLogo from '../assets/images/Macedonia.png'
import AlbaniaLogo from '../assets/images/albania.png'
import BosniaLogo from '../assets/images/bosnia.png'
import BrazilLogo from '../assets/images/brazil.png'
import { useState } from 'react'
import axios from '../api/axios'
import { useEffect } from 'react'
const CountryList = () => {
    const COUNTRIES_API_URL = "/countries";
    const [countries, setCouuntries] = useState([])

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCouuntries(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCountriesData()
    }, [])

    const navigate = useNavigate()
    return (
        <div className="xl:grid lg:grid md:grid sm:grid flex flex-wrap xl:grid-cols-7 lg:grid-cols-7 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 gap-6 md:grid-cols-5 sm:grid-cols-3 align-middle justify-center items-center">
            {
                countries?.map((item, index) => {
                    return (
                        <div
                            onClick={() => {
                                navigate(`/${item?.name.toLowerCase().replaceAll(" ", "")}`)
                                window.scrollTo(0, 0);
                            }}
                            key={index} className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                            <img src={
                                item.name == "Canada"
                                    ?
                                    CanadaLogo
                                    :
                                    item.name == "Pakistan"
                                        ?
                                        PakistanLogo
                                        : item.name == "United States"
                                            ?
                                            USALogo
                                            :
                                            item.name == "Macedonia"
                                                ?
                                                MacedoniaLogo
                                                :
                                                item.name == "Albania"
                                                    ?
                                                    AlbaniaLogo
                                                    :
                                                    item.name == "Bosnia"
                                                        ?
                                                        BosniaLogo
                                                        :
                                                        BrazilLogo
                            } alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                            <p className="text-center text-xl font-normal secondary-heading-color font-family-500">
                                {item.name}
                            </p>
                        </div>
                    )
                })
            }
            {/* <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={USALogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">USA</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={PakistanLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Pakistan</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={BosniaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Macedonia</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={AlbaniaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Albania</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={MacedoniaLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Bosnia</p>
            </div>
            <div className="flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ">
                <img src={BrazilLogo} alt="" className='mb-8 xl:h-24 lg:h-24 h-auto ' />
                <p className="text-center text-xl font-normal secondary-heading-color font-family-500">Brazil</p>
            </div> */}
        </div>
    )
}

export default CountryList