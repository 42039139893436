import React, { Component, useEffect, useState } from 'react'
import CompanyCard from '../../components/CompanyCard'
import CompanyInfoCard from '../../components/CompanyInfoCard'
import axios from '../../api/axios'
import CallToActionBanner from '../../components/CallToActionBanner'
import CountryList from '../../components/CountryList'
import RecommendedJobs from '../../components/RecommendedJobs'
import HomeBanner from '../../components/HomeBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'


const LandingPage = () => {
    const navigate = useNavigate()
    const TOP_COMPANIES = "top_companies"
    const FEATURED_COMPANIES = "featured_companies"
    const JOBSLIST = "alljobs"
    const CART_API_URL = '/carts'
    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)


    const [topCompanies, setTopCompanies] = useState([])
    const [featuredCompanies, setFeaturedCompanies] = useState([])
    const [cartJobs, setCartJobs] = useState([])
    const [reloadPage, setReloadPage] = useState(false)
    const [jobList, setJobList] = useState([])


    const fetchTopCompanies = async () => {
        // https://enthostalenthub.com/et/public/api/companies
        try {
            const response = await axios.get(TOP_COMPANIES);
            setTopCompanies(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchFeaturedCompanies = async () => {
        // https://enthostalenthub.com/et/public/api/companies
        try {
            const response = await axios.get(FEATURED_COMPANIES);
            setFeaturedCompanies(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchJobList = async () => {
        try {
            const response = await axios.get(JOBSLIST,
                {
                    params: {
                        token: userInfo?.token
                    }
                },
            );
            setJobList(response.data?.reverse())
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchTopCompanies()
        fetchFeaturedCompanies()
        fetchJobList()
    }, [])


    const fetchCartJobs = async () => {
        try {
            const response = await axios.get(CART_API_URL, {
                params: {
                    token: userInfo?.token
                }
            });
            setCartJobs(response.data)
        }
        catch (error) {
            console.log(error);
        }
        setReloadPage(false)
    }

    useEffect(() => {
        console.log('reload Page ', reloadPage)
        if (reloadPage) {
            fetchCartJobs()
        }
    }, [reloadPage])

    const refetchJobsForHeader = (props) => {
        console.log('props ', props)
        setReloadPage(props)
    }

    return (
        <>
            <Header countFromParent={cartJobs?.length} bgColor={false} />
            <HomeBanner />
            <CompanyInfoCard route="home" countriesList={topCompanies} />
            <div className="main-container">
                <h1 className='text-center primary-heading-color  w-full mx-auto font-semibold  xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                    We're here to ensure you achieve
                    breakthrough success
                </h1>
            </div>
            <div className="main-container">
                <div className="inner-container">
                    {
                        featuredCompanies?.map((item, index) => {
                            if (index < 5) {
                                if (index % 2 == 0) {
                                    return (
                                        <CompanyCard
                                            imagePosition="right"
                                            companyTtitle={item?.company_name}
                                            companyLogo={item?.logo}
                                            companyImage={item?.logo}
                                            id={item?.id}
                                            website_url={item?.website_url}
                                            companyDescription={item?.company_info}
                                        />
                                    )
                                } else {
                                    return (
                                        <CompanyCard
                                            id={item?.id}
                                            imagePosition="left"
                                            companyTtitle={item?.company_name}
                                            companyLogo={item?.logo}
                                            website_url={item?.website_url}
                                            companyImage={item?.logo}
                                            companyDescription={item?.company_info}
                                        />
                                    )
                                }
                            }
                        })
                    }
                </div>
            </div>
            <div className="call-to-action xl:py-20 lg:py-14 md:py-10 py-10">
                <div className="main-container">
                    <h2 className={`text-white text-center ${jobList.length > 0 ? "mb-14" : "mb-0"} font-semibold font-family-700 `}>
                        Recomended Jobs
                    </h2>
                    {
                        jobList?.length > 0 ?
                            jobList?.map((item, index) => {
                                if (index < 25)
                                    if (index == 0) {

                                        return (
                                            <>
                                                <hr key={index} className='border-t-2 mb-10' />
                                                <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="home" job={item} bgColored={true} />
                                                <hr key={index} className='border-t-2 mt-10 mb-14' />
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="home" job={item} bgColored={true} />
                                                <hr key={index} className='border mt-10 mb-14' />
                                            </>
                                        )
                                    }
                            })
                            :
                            null
                        // <RecommendedJobs />                    
                    }
                    {
                        jobList?.length > 0 ?
                            <a onClick={() => {
                                navigate('/find-job')
                            }} className='flex align-middle justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                                <button className='text-white text-center text-xl font-family-500 font-medium'>
                                    See more
                                </button>
                            </a>
                            :
                            ""
                    }
                </div>
            </div>
            <div className="main-container">
                <div className="xl:py-20 lg:py-14 md:py-10 py-10">
                    <h2 className='primary-heading-color font-semibold text-center xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                        Countries, where we work
                    </h2>
                    <CountryList />
                </div>
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default LandingPage