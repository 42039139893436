import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
const ProtectedRoute = (props) => {
    const navigate = useNavigate()
    useEffect(() => {
        const userFromLocalStorage = localStorage.getItem("userInfo")
        if (!userFromLocalStorage) {
            navigate('/')
        }

    }, [])

    return (
        <>
            {
                props.children
            }
        </>
    )


}

export default ProtectedRoute