import React, { Component, useEffect } from 'react'
import { useState } from 'react'
import axios from '../api/axios'
import { RxUpload } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import { WithContext as ReactTags } from 'react-tag-input';
const CandidateProfileSection = ({ userRole, userInformation }) => {
    const navigate = useNavigate()
    const COUNTRIES_API_URL = "/countries";
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    const UPDATED_API_URL = 'user/update/'
    const UPDATED_USER_LOGO_API_URL = '/user/logo'
    const TAGS_API_URL = "/tags";
    const GET_API_URL = 'candidate'
    const INTERESTEDIN_API_URL = "/intin";


    const [userInfo, setUserInfo] = useState([])
    const [countriesList, setCountriesList] = useState()
    const [selectedMobileCode, setSelectedMobileCode] = useState("")
    const [updatedEmail, setUpdatedEmail] = useState(userInformation?.user?.email)

    const [uploadedResume, setUploadedResume] = useState([])
    const [imageData, setImageData] = useState("")

    const [intrestedIn, setIntrestedIn] = useState([])
    const [interestedInList, setInterestedInList] = useState([])


    const [userNameError, setUserNameError] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [phoneCode, setPhoneCode] = useState()
    const [phoneLength, setPhoneLength] = useState()
    const [phone, setPhone] = useState("")
    const [updatedName, setUpdatedName] = useState("")
    const [tellUs, setTellUs] = useState("")
    const [updatedLInkedInUrl, setUpdatedLInkedInUrl] = useState("")
    const [LinkedInError, setLinkedInError] = useState("")
    const [tags, setTags] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [apiResponseError, setApiResponseError] = useState("")

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCountriesList(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchTagsData = async () => {
        try {
            const response = await axios.get(TAGS_API_URL);
            setTagsList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCountriesData()
        fetchInterestedInData()
        fetchTagsData()
        setUserInfo(userInformation)

        const mobileCode = countriesList?.filter((item) => item.id == userInformation?.user?.country_id)
        setSelectedMobileCode(mobileCode?.length > 0 ? mobileCode[0]?.phone_code : "")

        const mobileNumber = mobileCode?.length > 0 ? userInformation?.user?.mobile.replace(mobileCode[0]?.phone_code, "") : ""
        console.log(mobileNumber)
        setPhone(mobileNumber)

        setUpdatedName(userInformation?.user?.name)
        setTellUs(userInformation?.user?.tell_us)
        setUpdatedEmail(userInformation?.user?.email)
        setUpdatedLInkedInUrl(userInformation?.user?.linkedin_url)





    }, [userInformation])

    useEffect(() => {
        console.log('selectedMobileCode ', selectedMobileCode)
    }, [selectedMobileCode])

    const [editView, setEditView] = useState(false)

    const validateEmailAddress = () => {
        if (updatedEmail == "") {
            setUserNameError("Username can not be empty")
        } else if (!regex.test(updatedEmail)) {
            setUserNameError("Username must be a valid Email Address")
        } else {
            setUserNameError("")
        }
    }

    useEffect(() => {
        if (phoneCode == "+1") {
            setPhoneLength(10)
        } else if (phoneCode == "+92") {
            setPhoneLength(10)
        } else if (phoneCode == "+389") {
            setPhoneLength(8)
        } else if (phoneCode == "+355") {
            setPhoneLength(8)
        } else if (phoneCode == "+387") {
            setPhoneLength(8)
        } else if (phoneCode == "+55") {
            setPhoneLength(11)
        }
    }, [phoneCode])

    const validatePhoneNumber = () => {
        console.log(phone.length)
        if (phone == "") {
            setPhoneError("Phone can not be empty")
        } else if (phone.length < phoneLength) {
            setPhoneError(`Please enter a valid phone having ${phoneLength} numbers`)
        } else {
            setPhoneError("")
        }
    }

    const validateLInkedInURL = () => {
        if (updatedLInkedInUrl == "" || !updatedLInkedInUrl.includes("www.linkedin.com")) {
            setLinkedInError("Linkedin Url can not be empty")
        } else {
            setLinkedInError("")
        }
    }

    const updateUserInformation = async () => {
        try {

            const intrestTagsArr = []
            const techTagsArr = []

            const intrestedInClone = intrestedIn
            const tech_tagsClone = tags

            for (let i = 0; i < intrestedInClone.length; i++) {
                intrestTagsArr.push(parseInt(intrestedInClone[i].id))
            }
            for (let i = 0; i < userInfo?.interested_in?.length; i++) {
                intrestTagsArr.push(parseInt(userInfo?.interested_in[i].intin_id))
            }
            const uniqueIntrestedIn = [...new Set(intrestTagsArr)];
            for (let i = 0; i < tech_tagsClone.length; i++) {
                techTagsArr.push(parseInt(tech_tagsClone[i].id))
            }
            for (let i = 0; i < userInfo?.tech_tags?.length; i++) {
                techTagsArr.push(parseInt(userInfo?.tech_tags[i].tag_id))
            }
            const uniqueTechTags = [...new Set(techTagsArr)];

            const file = uploadedResume
            let post_data = {
                "name": updatedName != "" ? updatedName : userInfo?.user?.name,
                "tech_tags": uniqueTechTags,
                "interested_in": uniqueIntrestedIn,
                "name": updatedName != "" ? updatedName : userInfo?.user?.name,
                "email": updatedEmail != "" ? updatedEmail : userInfo?.user?.email,
                "mobile": phone != "" ? `${phoneCode}${phone}` : userInfo?.user?.mobile,
                "linkedin_url": updatedLInkedInUrl != "" ? updatedLInkedInUrl : userInfo?.user?.linkedin_url,
                "token": userRole?.token,
                "tell_us": tellUs != "" ? tellUs : userInfo?.user?.tell_us,
                "user_type": userInfo?.user?.user_type
            }

            // console.log('post date', post_data)

            const response = await axios.put(
                `${UPDATED_API_URL}${userInfo?.user?.id}`,
                JSON.stringify(post_data),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            console.log("response", response)
            if (!response.data.error) {
                const userData = localStorage.getItem("userInfo")
                const user = JSON.parse(userData)
                const formData = new FormData();
                // Update the formData obzject
                formData.append("logo", file);
                formData.append("user_id", userInfo?.user?.id);
                formData.append("token", user?.token);

                if (file) {
                    const responseOfImage = await axios.post(
                        UPDATED_USER_LOGO_API_URL,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                "Authorization": `Bearer ${user?.token}`
                            },
                        }
                    )
                    console.log("responseOfImage", responseOfImage)
                    if (!responseOfImage?.data?.error) {

                    }
                    setApiResponseError(response.data.message)
                }
                try {
                    let post_data = {
                        "token": userRole?.token,
                    }

                    const response = await axios.get(
                        GET_API_URL,
                        {
                            params: {
                                token: post_data?.token
                            }
                        },
                        // JSON.stringify(post_data),
                        {
                            headers: {
                                "Content-Type": "application/json",
                                // "token": post_data.token,
                                // 'Authorization': `token ${post_data.token}`
                            },
                        }
                    )
                    setUserInfo(response?.data)
                    // const obj = {
                    //     userRole: "1",
                    //     token: response.data.token
                    // }
                    // localStorage.setItem('userInfo', JSON.stringify(obj))
                    // navigate('/profile')
                } catch (err) {
                    console.log('error ', err.response.data.message)
                    // setApiResponseError(err.response.data.message)
                    //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
                }

                setIntrestedIn([])
                setTags([])
                // navigate('/profile')
                setEditView(!editView)
                navigate('/profile')
            }
        } catch (err) {
            console.log('error ', err.response.data.message)
            setApiResponseError(err.response.data.message)
            //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
        }

        setTimeout(() => {
            setApiResponseError("")
        }, 10000)

        setEditView(false)
    }
    const fetchInterestedInData = async () => {
        try {
            const response = await axios.get(INTERESTEDIN_API_URL);
            setInterestedInList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }


    const handleFileInput = (e) => {
        let file = e.target.files[0];
        setUploadedResume(file);
        setImageData(URL.createObjectURL(file));
        // setUploadedResumeError("")
    };


    const suggestionsInterestedIn = interestedInList?.map(item => {
        return {
            id: `${item?.id}`,
            text: item?.title
        };
    });

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    // const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const delimiters = [KeyCodes.comma];

    const handleDelete = i => {
        setIntrestedIn(intrestedIn.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        if (intrestedIn?.length < 5) {
            setIntrestedIn([...intrestedIn, tag]);
        } else {
            // setInterestedError("Interested In tags are limited to 5")
        }
        // setInterestedError("")
    };

    const handleTechTagsDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTechTagsAddition = tag => {
        if (tags?.length < 5) {
            setTags([...tags, tag]);
        } else {

        }
        // setTagsError("")
    };

    const suggestionsTechTags = tagsList?.map(item => {
        return {
            id: `${item?.id}`,
            text: item?.tag
        };
    });

    return (
        <>
            {
                apiResponseError ?
                    <span className='text-green-900 text-lg font-family-500 font-medium py-3 block w-full'>
                        {apiResponseError}
                    </span>
                    :
                    ""
            }
            <div className="flex align-middle justify-between items-center w-full xl:px-6 lg:px-0 md:px-0 sm:px-0 px-0">
                <h3 className='primary-heading-color text-3xl font-family-700 font-medium'>My Profile</h3>
                {
                    editView
                        ?
                        <p className='hidden cursor-pointer xl:flex lg:flex md:flex sm:hidden alignmi items-center gap-7'>
                            <span
                                onClick={
                                    updateUserInformation
                                }
                                className='text-xl font-family-700 font-medium primary-heading-color'>
                                Save
                            </span>
                            <span onClick={() => {
                                setEditView(false)
                            }} className='text-xl font-family-700 font-medium secondary-heading-color'>
                                Cancel
                            </span>
                        </p>
                        :
                        <svg onClick={() => {
                            setEditView(true)
                        }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.9352C0 6.62048 1.07796 5.55469 2.4077 5.55469H13.4541C14.119 5.55469 14.658 6.08758 14.658 6.74494C14.658 7.4023 14.119 7.9352 13.4541 7.9352H2.4077V27.6198L22.5148 27.6198V16.5663C22.5148 15.9089 23.0537 15.376 23.7186 15.376C24.3835 15.376 24.9225 15.9089 24.9225 16.5663V27.6198C24.9225 28.9345 23.8445 30.0003 22.5148 30.0003H2.4077C1.07797 30.0003 0 28.9345 0 27.6198V7.9352Z" fill="#FFB538" />
                            <path d="M25.95 8.27562L21.5661 3.96094L11.5165 13.7603L10.1267 18.7515C10.0044 19.1907 10.4061 19.5973 10.853 19.4868L15.9636 18.2238L25.95 8.27562Z" fill="#FFB538" />
                            <path d="M27.3338 6.97304L22.9463 2.63744L24.6632 0.917384C25.8992 -0.320951 27.9262 -0.303307 29.14 0.956352C30.3132 2.17391 30.283 4.09648 29.0722 5.27748L27.3338 6.97304Z" fill="#FFB538" />
                        </svg>
                }
            </div>
            <hr className=' bg-white border-t-2 xl:w-1/5 lg:w-2/5 md:w-3/5 sm:w-full w-full border-gray-400 mt-7 mb-9' />
            <div className="flex xl:gap-11 xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col lg:gap-6 md:gap-6 sm:gap-0 gap-0 align-middle w-full xl:px-6 lg:px-6 md:px-6 sm:px-0 px-0">
                <div className="flex align-middle items-center flex-col">
                    <div className="flex relative overflow-hidden align-middle justify-center items-center flex-col xl:mb-5 lg:mb-5 md:mb-5 sm:mb-10 mb-10 h-64 xl:w-64 md:w-64 sm:w-11/12 w-full lg:w-64   uploader-bg-color">
                        {
                            editView
                                ?
                                imageData == "" ?
                                    <>
                                        <RxUpload className='mb-2 secondary-heading-color font-family-700 font-medium text-2xl' />
                                        <span className='text-xl font-family-500 font-medium secondary-heading-color text-center'>
                                            Upload new Logo
                                        </span>
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                handleFileInput(e)
                                            }}
                                            autoComplete='off'
                                            className="form-control absolute top-0 left-0 h-full cursor-pointer opacity-0 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                            // onChange={(e) => {
                                            //     setUsername(e.target.value)
                                            // }}
                                            id="resume"
                                            // value={username}
                                            placeholder="Upload Logo"
                                        />
                                    </>
                                    :
                                    <span className='absolute overflow-hidden top-0 letf-0 w-full h-full z-30 rounded-md border border-gray-400 '>
                                        <svg className='block text-end mx-2 w-max my-2 font-bold cursor-pointer' onClick={() => {
                                            setImageData("")
                                            setUploadedResume("")
                                        }} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5218 13.7396C13.198 14.0506 12.6834 14.0403 12.3724 13.7165L1.4267 2.32337C1.11567 1.99961 1.12598 1.48502 1.44973 1.17398C1.77348 0.862948 2.28808 0.873256 2.59911 1.19701L13.5448 12.5902C13.8558 12.9139 13.8455 13.4285 13.5218 13.7396Z" fill="#F32131" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.47727 13.7396C1.80102 14.0506 2.31562 14.0403 2.62666 13.7165L13.5723 2.32337C13.8834 1.99961 13.873 1.48502 13.5493 1.17398C13.2255 0.862948 12.7109 0.873256 12.3999 1.19701L1.45425 12.5902C1.14321 12.9139 1.15352 13.4285 1.47727 13.7396Z" fill="#F32131" />
                                        </svg>
                                        <img src={imageData} alt="" className='w-full h-full object-cover' />
                                    </span>
                                :
                                userInfo?.user?.logo ?
                                    <img src={`https://enthostalenthub.com/et/public/upload/${userInfo?.user?.logo}`} />
                                    :
                                    <img src={`https://enthostalenthub.com/et/public/upload/profile.jpeg`} />
                        }
                    </div>
                    {
                        editView ?
                            <div className='xl:block lg:block md:block sm:hidden hidden'>
                                <div className="flex flex-col xl:mb-3 lg:mb-3 md:mb-3 sm:mb-5 mb-5 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="email"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out  focus:bg-white focus:border-gray-300 focus:outline-none"
                                        onChange={(e) => {
                                            setUpdatedEmail(e.target.value)
                                        }}
                                        onBlur={validateEmailAddress}
                                        id="email"
                                        defaultValue={userInfo?.user?.email}
                                        placeholder={userInfo?.user?.email}
                                        value={updatedEmail}
                                    />
                                    {
                                        userNameError ?
                                            <span className='text-red-600 text-sm font-family-500 font-medium pt-3 block w-full'>
                                                {userNameError}
                                            </span>
                                            : ""
                                    }
                                </div>
                                <div className="flex xl:mb-3 lg:mb-3 md:mb-3 sm:mb-5 mb-5 align-middle justify-between items-center w-full gap-0 relative border border-solid border-gray-300 rounded-full transition ease-in-out">
                                    <select
                                        defaultValue={
                                            selectedMobileCode
                                        }
                                        onChange={(e) => {
                                            setPhoneCode(e.target.value)
                                        }}
                                        value={
                                            phoneCode
                                        }
                                        id="mobileCode" class="xl:w-5/12 lg:w-6/12 md:w-6/12 sm:w-5/12 w-5/12 bg-white appearance-none outline-none rounded-full border-none focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block pl-4 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                                        {
                                            countriesList?.map((item, index) => {
                                                return (
                                                    <option value={item.phone_code} key={index}>{item.phone_code}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <input
                                        maxLength={phoneLength}
                                        minLength={phoneLength}
                                        onChange={(e) => {
                                            setPhone(e.target.value)
                                        }}
                                        value={phone}
                                        onBlur={validatePhoneNumber}

                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full pr-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        id="mobile"
                                        placeholder="Mobile"
                                    />
                                </div>
                                {
                                    phoneError ?
                                        <span className='text-red-600 text-sm font-family-500 pb-3 font-medium block w-full'>
                                            {phoneError}
                                        </span>
                                        : ""
                                }
                                <div className="flex flex-col mb-0 align-middle justify-between items-center w-full gap-6">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="form-control font-family-500 font-normal block w-full px-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out  focus:bg-white focus:border-gray-300 focus:outline-none"
                                        id="linkedIn"
                                        onChange={(e) => {
                                            setUpdatedLInkedInUrl(e.target.value)
                                        }}
                                        onBlur={validateLInkedInURL}
                                        value={updatedLInkedInUrl}
                                        defaultValue={userInfo?.user?.linkedin_url}
                                        placeholder={userInfo?.user?.linkedin_url}
                                    />
                                    {
                                        LinkedInError ?
                                            <span className='text-red-600 text-sm font-family-500 pt-3 font-medium block w-full'>
                                                {LinkedInError}
                                            </span>
                                            : ""
                                    }
                                </div>
                            </div>
                            :
                            <>
                                {
                                    userInfo?.user?.email
                                        ?
                                        <div className="hidden align-middle w-full mb-5 items-center gap-2 xl:flex lg:flex md:flex sm:hidden">
                                            <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M22.6538 0.755556H1.88889C1.26297 0.755556 0.755556 1.26297 0.755556 1.88889V15.1111C0.755556 15.737 1.26297 16.2444 1.88889 16.2444H22.6538C23.2797 16.2444 23.7872 15.737 23.7872 15.1111V1.88889C23.7872 1.26297 23.2797 0.755556 22.6538 0.755556ZM1.88889 0C0.845684 0 0 0.845684 0 1.88889V15.1111C0 16.1543 0.845684 17 1.88889 17H22.6538C23.697 17 24.5427 16.1543 24.5427 15.1111V1.88889C24.5427 0.845684 23.697 0 22.6538 0H1.88889Z" fill="#30ACB6" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.640454 1.76046L0.0117188 2.23195C0.0431006 2.25964 0.0765618 2.28626 0.112146 2.31165L10.8583 9.98153C11.6428 10.5414 12.6956 10.544 13.4828 9.98799L24.3462 2.31488C24.429 2.2564 24.5004 2.19132 24.561 2.12137C24.3107 1.9737 24.1134 1.80774 23.9582 1.6582C23.9437 1.67217 23.9278 1.6854 23.9103 1.69775L13.0469 9.37085C12.5221 9.74155 11.8203 9.73983 11.2973 9.36655L0.640454 1.76046Z" fill="#30ACB6" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.051 8.11434C14.9335 8.28677 14.9781 8.52178 15.1505 8.63925L23.929 14.6195C24.1015 14.737 24.3365 14.6924 24.4539 14.52C24.5714 14.3475 24.5268 14.1125 24.3544 13.9951L15.5759 8.01482C15.4035 7.89735 15.1685 7.94191 15.051 8.11434Z" fill="#30ACB6" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.48513 8.11434C9.6026 8.28677 9.55804 8.52178 9.38561 8.63925L0.607108 14.6195C0.434677 14.737 0.199668 14.6924 0.0822013 14.52C-0.0352654 14.3475 0.00929232 14.1125 0.181724 13.9951L8.96022 8.01482C9.13265 7.89735 9.36766 7.94191 9.48513 8.11434Z" fill="#30ACB6" />
                                            </svg>
                                            <span className='secondary-heading-color text-lg font-family-500 font-medium'>
                                                {
                                                    userInfo?.user?.email
                                                }
                                            </span>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    userInfo?.user?.mobile
                                        ?
                                        <div className="hidden align-middle w-full mb-5 items-center gap-2 xl:flex lg:flex md:flex sm:hidden">
                                            <svg width="25" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.24219 1.06067C3.69047 1.27367 2.1418 3.32739 1.24394 4.59706C1.08682 4.81925 1.02945 5.09882 1.09084 5.36394C2.95151 13.4002 10.6107 17.5224 14.0532 17.9654C14.2943 17.9965 14.5318 17.9173 14.7218 17.7657C15.5361 17.1164 16.9296 15.9853 17.7306 15.2638C18.6396 14.4451 18.1093 13.934 17.7306 13.7809C17.0221 13.3283 15.4032 12.2937 14.5952 11.7757C13.7871 11.2577 13.2484 11.6852 13.0801 11.9637C12.9742 12.0798 12.6862 12.3882 12.2601 12.8282C12.036 13.0597 11.7105 13.1722 11.3975 13.0956C8.34543 12.3486 6.63394 9.35077 6.00983 7.51812C5.90707 7.21637 5.99976 6.90578 6.25774 6.71853C6.45632 6.57438 6.71632 6.38542 7.01964 6.13626C7.60685 5.6539 7.37738 4.94571 7.1459 4.59063L5.35724 1.60377C5.238 1.32528 4.84804 0.826774 4.24219 1.06067Z" stroke="#30ACB6" />
                                            </svg>
                                            <span className='secondary-heading-color text-lg font-family-500 font-medium'>
                                                {
                                                    userInfo?.user?.mobile
                                                }
                                            </span>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    userInfo?.user?.linkedin_url
                                        ?
                                        <div className="hidden align-middle w-full mb-4 items-center gap-2 xl:flex lg:flex md:flex sm:hidden">
                                            <svg width="25" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z" fill="#30ACB6" />
                                                <path d="M13.5789 9.18483V12.6892H11.5471V9.41968C11.5471 8.59875 11.2538 8.03812 10.5182 8.03812C9.95677 8.03812 9.62327 8.41556 9.476 8.78102C9.42251 8.91163 9.40872 9.09301 9.40872 9.27619V12.689H7.37683C7.37683 12.689 7.4041 7.15157 7.37683 6.57837H9.40887V7.44431C9.40478 7.45113 9.39902 7.4578 9.39539 7.46431H9.40887V7.44431C9.67888 7.02884 10.1604 6.43488 11.24 6.43488C12.5767 6.43488 13.5789 7.30825 13.5789 9.18483ZM5.2513 3.63281C4.55628 3.63281 4.10156 4.08904 4.10156 4.68846C4.10156 5.27514 4.54309 5.74455 5.22463 5.74455H5.23782C5.94648 5.74455 6.3871 5.27514 6.3871 4.68846C6.37361 4.08904 5.94648 3.63281 5.2513 3.63281ZM4.22232 12.6892H6.25346V6.57837H4.22232V12.6892Z" fill="white" />
                                            </svg>
                                            <span className='secondary-heading-color text-lg font-family-500 font-medium'>
                                                {
                                                    userInfo?.user?.linkedin_url
                                                }
                                            </span>
                                        </div>
                                        :
                                        null
                                }
                            </>
                    }

                </div>
                <div id="kasdfkjaskdfjkasldjflasjd" className={`flex align-middle ${editView ? "w-full" : "w-full"} xl:flex-row lg:flex-row justify-between md:flex-col sm:flex-col flex-col xl:gap-14 lg:gap-6 md:gap-6 sm:gap-5 gap-5 `}>
                    <div className={`flex flex-col ${editView ? "xl:w-3/5 lg:w-4/5 md:w-full sm:w-full w-full" : ""}`}>
                        {
                            editView ?
                                <>
                                    <div className="flex mb-4 align-middle justify-between items-center w-full gap-6">
                                        <input
                                            type="text"
                                            autoComplete='off'
                                            className="form-control font-family-500 font-normal block w-full px-4 py-2 text-3xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                            id="name"
                                            onChange={(e) => {
                                                setUpdatedName(e.target.value)
                                            }}
                                            value={updatedName}
                                            defaultValue={userInfo?.user?.name}
                                            placeholder={userInfo?.user?.name}
                                        />
                                    </div>
                                    {
                                        userInfo?.user?.country_id ?
                                            <div className="flex align-middle gap-4 xl:mb-8 lg:mb-5 md:mb-5 xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center sm:mb-5 mb-5 w-full items-center">
                                                <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.64381 18.4219C6.09572 18.6159 0.999637 19.6242 1 22.1048C1 25.2419 9.83339 25.7909 12.1162 25.7909C14.399 25.7909 23.0438 24.9529 23.0438 22.1048C23.0174 20.0726 18.6468 18.5667 15.5457 18.4219" stroke="#30ACB6" strokeWidth="1.5" />
                                                    <circle cx="11.9335" cy="8.68739" r="3.19911" stroke="#30ACB6" strokeWidth="1.5" />
                                                    <path d="M19.869 8.68644C19.869 9.51121 19.5568 10.6599 18.9929 12.0261C18.4368 13.3733 17.6676 14.8585 16.8231 16.3312C15.1347 19.2756 13.1778 22.1147 12.1156 23.6066C12.0265 23.7317 11.8548 23.7317 11.7658 23.6069C10.7016 22.1153 8.74051 19.2761 7.04853 16.3315C6.20221 14.8586 5.43131 13.3733 4.87404 12.026C4.30889 10.6596 3.99609 9.51099 3.99609 8.68644C3.99609 4.30327 7.54936 0.75 11.9325 0.75C16.3157 0.75 19.869 4.30327 19.869 8.68644Z" stroke="#30ACB6" strokeWidth="1.5" />
                                                </svg>
                                                <span className='secondary-heading-color text-xl font-family-500 font-medium'>
                                                    {
                                                        countriesList?.map((item) => {
                                                            if (item.id == userInfo?.user?.country_id) {
                                                                return (
                                                                    item.name
                                                                )
                                                            }
                                                        })
                                                    }
                                                </span>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="flex mb-0 align-middle justify-between items-center w-full gap-6">
                                        <textarea
                                            onChange={(e) => {
                                                setTellUs(e.target.value)
                                            }}
                                            value={tellUs}
                                            defaultValue={userInfo?.user?.name}
                                            id="tell_us" rows="10" className="form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl-5 transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none" placeholder={userInfo?.user?.tell_us}>
                                        </textarea>
                                    </div>
                                </>
                                :
                                <>
                                    <h3 className='secondary-heading-color w-full xl:mb-8 lg:mb-5 md:mb-5 sm:mb-5 mb-5 text-3xl font-family-700 font-medium xl:text-left lg:text-left md:text-left sm:text-center text-center'>
                                        {/* First Name / Last Name */}
                                        {
                                            userInfo?.user?.name
                                        }
                                    </h3>
                                    <div className="flex align-middle gap-4 xl:mb-8 lg:mb-5 md:mb-5 xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center sm:mb-5 mb-5 w-full items-center">
                                        <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.64381 18.4219C6.09572 18.6159 0.999637 19.6242 1 22.1048C1 25.2419 9.83339 25.7909 12.1162 25.7909C14.399 25.7909 23.0438 24.9529 23.0438 22.1048C23.0174 20.0726 18.6468 18.5667 15.5457 18.4219" stroke="#30ACB6" strokeWidth="1.5" />
                                            <circle cx="11.9335" cy="8.68739" r="3.19911" stroke="#30ACB6" strokeWidth="1.5" />
                                            <path d="M19.869 8.68644C19.869 9.51121 19.5568 10.6599 18.9929 12.0261C18.4368 13.3733 17.6676 14.8585 16.8231 16.3312C15.1347 19.2756 13.1778 22.1147 12.1156 23.6066C12.0265 23.7317 11.8548 23.7317 11.7658 23.6069C10.7016 22.1153 8.74051 19.2761 7.04853 16.3315C6.20221 14.8586 5.43131 13.3733 4.87404 12.026C4.30889 10.6596 3.99609 9.51099 3.99609 8.68644C3.99609 4.30327 7.54936 0.75 11.9325 0.75C16.3157 0.75 19.869 4.30327 19.869 8.68644Z" stroke="#30ACB6" strokeWidth="1.5" />
                                        </svg>
                                        <span className='secondary-heading-color text-xl font-family-500 font-medium'>
                                            {
                                                countriesList?.map((item) => {
                                                    if (item.id == userInfo?.user?.country_id) {
                                                        return (
                                                            item.name
                                                        )
                                                    }
                                                })
                                            }
                                        </span>
                                    </div>
                                    <p className='secondary-heading-color font-family-500 font-medium text-xl xl:text-left lg:text-left md:text-left sm:text-center text-center'>
                                        {
                                            userInfo?.user?.tell_us
                                        }
                                    </p>
                                </>
                        }
                    </div>
                    <div className="flex flex-col justify-center xl:hidden lg:hidden md:hidden sm:flex">
                        {
                            editView ?
                                <>
                                    <div className="flex flex-col xl:mb-3 lg:mb-3 md:mb-3 sm:mb-5 mb-5 align-middle justify-between items-center w-full gap-6">
                                        <input
                                            type="email"
                                            autoComplete='off'
                                            className="form-control font-family-500 font-normal block w-full px-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out  focus:bg-white focus:border-gray-300 focus:outline-none"
                                            onChange={(e) => {
                                                setUpdatedEmail(e.target.value)
                                            }}
                                            onBlur={validateEmailAddress}
                                            id="email"
                                            defaultValue={userInfo?.user?.email}
                                            placeholder={userInfo?.user?.email}
                                            value={updatedEmail}
                                        />
                                        {
                                            userNameError ?
                                                <span className='text-red-600 text-sm font-family-500 font-medium pt-3 block w-full'>
                                                    {userNameError}
                                                </span>
                                                : ""
                                        }
                                    </div>
                                    <div className="flex xl:mb-3 lg:mb-3 md:mb-3 sm:mb-5 mb-5 align-middle justify-between items-center w-full gap-0 relative border border-solid border-gray-300 rounded-full transition ease-in-out">
                                        <select
                                            defaultValue={
                                                selectedMobileCode
                                            }
                                            onChange={(e) => {
                                                setPhoneCode(e.target.value)
                                            }}
                                            value={
                                                phoneCode
                                            }
                                            id="mobileCode" class="xl:w-5/12 lg:w-6/12 md:w-6/12 sm:w-5/12 w-5/12 bg-white appearance-none outline-none rounded-full border-none focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 block pl-4 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                                            {
                                                countriesList?.map((item, index) => {
                                                    return (
                                                        <option value={item.phone_code} key={index}>{item.phone_code}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input
                                            maxLength={phoneLength}
                                            minLength={phoneLength}
                                            onChange={(e) => {
                                                setPhone(e.target.value)
                                            }}
                                            value={phone}
                                            onBlur={validatePhoneNumber}

                                            type="text"
                                            autoComplete='off'
                                            className="form-control font-family-500 font-normal block w-full pr-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                            id="mobile"
                                            placeholder="Mobile"
                                        />
                                    </div>
                                    {
                                        phoneError ?
                                            <span className='text-red-600 text-sm font-family-500 pb-3 font-medium block w-full'>
                                                {phoneError}
                                            </span>
                                            : ""
                                    }
                                    <div className="flex xl:mb-0 lg:mb-0 md:mb-0 sm:mb-10 mb-10 align-middle justify-between items-center w-full gap-6">
                                        <input
                                            type="text"
                                            autoComplete='off'
                                            className="form-control font-family-500 font-normal block w-full px-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding border border-solid border-gray-300 rounded-full transition ease-in-out  focus:bg-white focus:border-gray-300 focus:outline-none"
                                            onChange={(e) => {
                                                setUpdatedLInkedInUrl(e.target.value)
                                            }}
                                            onBlur={validateLInkedInURL}
                                            id="linkedIn"
                                            value={updatedLInkedInUrl}
                                            defaultValue={userInfo?.user?.linkedin_url}
                                            placeholder={userInfo?.user?.linkedin_url}
                                        />
                                        {
                                            LinkedInError ?
                                                <span className='text-red-600 text-sm font-family-500 pt-3 font-medium block w-full'>
                                                    {LinkedInError}
                                                </span>
                                                : ""
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className="flex align-middle justify-center w-full mb-5 items-center gap-2 xl:hidden lg:hidden md:hidden sm:flex">
                                        <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M22.6538 0.755556H1.88889C1.26297 0.755556 0.755556 1.26297 0.755556 1.88889V15.1111C0.755556 15.737 1.26297 16.2444 1.88889 16.2444H22.6538C23.2797 16.2444 23.7872 15.737 23.7872 15.1111V1.88889C23.7872 1.26297 23.2797 0.755556 22.6538 0.755556ZM1.88889 0C0.845684 0 0 0.845684 0 1.88889V15.1111C0 16.1543 0.845684 17 1.88889 17H22.6538C23.697 17 24.5427 16.1543 24.5427 15.1111V1.88889C24.5427 0.845684 23.697 0 22.6538 0H1.88889Z" fill="#30ACB6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.640454 1.76046L0.0117188 2.23195C0.0431006 2.25964 0.0765618 2.28626 0.112146 2.31165L10.8583 9.98153C11.6428 10.5414 12.6956 10.544 13.4828 9.98799L24.3462 2.31488C24.429 2.2564 24.5004 2.19132 24.561 2.12137C24.3107 1.9737 24.1134 1.80774 23.9582 1.6582C23.9437 1.67217 23.9278 1.6854 23.9103 1.69775L13.0469 9.37085C12.5221 9.74155 11.8203 9.73983 11.2973 9.36655L0.640454 1.76046Z" fill="#30ACB6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.051 8.11434C14.9335 8.28677 14.9781 8.52178 15.1505 8.63925L23.929 14.6195C24.1015 14.737 24.3365 14.6924 24.4539 14.52C24.5714 14.3475 24.5268 14.1125 24.3544 13.9951L15.5759 8.01482C15.4035 7.89735 15.1685 7.94191 15.051 8.11434Z" fill="#30ACB6" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.48513 8.11434C9.6026 8.28677 9.55804 8.52178 9.38561 8.63925L0.607108 14.6195C0.434677 14.737 0.199668 14.6924 0.0822013 14.52C-0.0352654 14.3475 0.00929232 14.1125 0.181724 13.9951L8.96022 8.01482C9.13265 7.89735 9.36766 7.94191 9.48513 8.11434Z" fill="#30ACB6" />
                                        </svg>
                                        <span className='secondary-heading-color text-lg font-family-500 font-medium'>
                                            {
                                                userInfo?.user?.email
                                            }
                                        </span>
                                    </div>
                                    <div className="flex align-middle justify-center w-full mb-5 items-center gap-2 xl:hidden lg:hidden md:hidden sm:flex">
                                        <svg width="25" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.24219 1.06067C3.69047 1.27367 2.1418 3.32739 1.24394 4.59706C1.08682 4.81925 1.02945 5.09882 1.09084 5.36394C2.95151 13.4002 10.6107 17.5224 14.0532 17.9654C14.2943 17.9965 14.5318 17.9173 14.7218 17.7657C15.5361 17.1164 16.9296 15.9853 17.7306 15.2638C18.6396 14.4451 18.1093 13.934 17.7306 13.7809C17.0221 13.3283 15.4032 12.2937 14.5952 11.7757C13.7871 11.2577 13.2484 11.6852 13.0801 11.9637C12.9742 12.0798 12.6862 12.3882 12.2601 12.8282C12.036 13.0597 11.7105 13.1722 11.3975 13.0956C8.34543 12.3486 6.63394 9.35077 6.00983 7.51812C5.90707 7.21637 5.99976 6.90578 6.25774 6.71853C6.45632 6.57438 6.71632 6.38542 7.01964 6.13626C7.60685 5.6539 7.37738 4.94571 7.1459 4.59063L5.35724 1.60377C5.238 1.32528 4.84804 0.826774 4.24219 1.06067Z" stroke="#30ACB6" />
                                        </svg>
                                        <span className='secondary-heading-color text-lg font-family-500 font-medium'>
                                            {
                                                userInfo?.user?.mobile
                                            }
                                        </span>
                                    </div>
                                    <div className="flex align-middle justify-center w-full mb-4 items-center gap-2 xl:hidden lg:hidden md:hidden sm:flex">
                                        <svg width="25" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z" fill="#30ACB6" />
                                            <path d="M13.5789 9.18483V12.6892H11.5471V9.41968C11.5471 8.59875 11.2538 8.03812 10.5182 8.03812C9.95677 8.03812 9.62327 8.41556 9.476 8.78102C9.42251 8.91163 9.40872 9.09301 9.40872 9.27619V12.689H7.37683C7.37683 12.689 7.4041 7.15157 7.37683 6.57837H9.40887V7.44431C9.40478 7.45113 9.39902 7.4578 9.39539 7.46431H9.40887V7.44431C9.67888 7.02884 10.1604 6.43488 11.24 6.43488C12.5767 6.43488 13.5789 7.30825 13.5789 9.18483ZM5.2513 3.63281C4.55628 3.63281 4.10156 4.08904 4.10156 4.68846C4.10156 5.27514 4.54309 5.74455 5.22463 5.74455H5.23782C5.94648 5.74455 6.3871 5.27514 6.3871 4.68846C6.37361 4.08904 5.94648 3.63281 5.2513 3.63281ZM4.22232 12.6892H6.25346V6.57837H4.22232V12.6892Z" fill="white" />
                                        </svg>
                                        <span className='secondary-heading-color text-lg font-family-500 font-medium'>
                                            {
                                                userInfo?.user?.linkedin_url
                                            }
                                        </span>
                                    </div>
                                </>
                        }
                        {
                            editView
                                ?
                                <div className="flex align-middle sm:justify-center sm:gap-6 justify-between gap-0 items-center">
                                    <button
                                        onClick={
                                            updateUserInformation
                                        }
                                        className='primary-bg-color cursor-pointer rounded-full text-xl text-white font-family-500 font-medium w-max py-2 px-10'>
                                        Save
                                    </button>
                                    <button onClick={() => {
                                        setEditView(!editView)
                                    }} className='tags-color cursor-pointer  rounded-full text-xl secondary-heading-color font-family-500 font-medium w-max py-2 px-10'>
                                        Cancel
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                    {
                        !editView
                            ?
                            <div className="flex flex-col">
                                <border className="mb-6 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl xl:w-72 lg:w-max md:w-max sm:w-4/5 w-full xl:px-0 xl:ml-0 lg:ml-0 md:ml-0 sm:mx-auto mx-auto lg:px-10 md:px-10 sm:px-10 px-10 py-2 flex align-middle items-center justify-center font-family-500 font-medium secondary-link-color border secondary-border-color rounded-full ">
                                    View your Resume
                                </border>
                                <border className="xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl xl:w-72 lg:w-max md:w-max sm:w-4/5 w-full xl:px-0 xl:ml-0 lg:ml-0 md:ml-0 sm:mx-auto mx-auto lg:px-10 md:px-10 sm:px-10 px-10 py-2 flex align-middle items-center justify-center font-family-500 font-medium secondary-link-color border secondary-border-color rounded-full ">
                                    Upload your Resume
                                </border>
                                <border className="mb-5 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl xl:w-72 lg:w-max md:w-max sm:w-4/5 w-full xl:px-0 xl:ml-0 lg:ml-0 md:ml-0 sm:mx-auto mx-auto lg:px-10 md:px-10 sm:px-10 px-10 primary-bg-color py-2 flex align-middle items-center justify-center font-family-500 font-medium text-white border secondary-border-color rounded-full ">
                                    Match your Resume
                                </border>
                                <span
                                    onClick={() => {
                                        navigate('/profile/change-password')
                                    }}
                                    className='primary-heading-color xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 xl:text-center lg:text-center md:text-left sm:text-center text-center  font-medium cursor-pointer'>
                                    Change my Password
                                </span>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <hr className=' bg-white border w-full border-gray-400 mt-7 mb-9 xl:opacity-100 lg:opacity-100 md:opacity-100 sm:opacity-0 opacity-0' />
            <div className="flex align-middle justify-between items-center w-full xl:px-6 lg:px-0 md:px-0 sm:px-0 px-0">
                <h3 className='primary-heading-color text-3xl font-family-700 font-medium'>
                    Preferences
                </h3>
            </div>
            <hr className=' bg-white border xl:w-1/5 lg:w-2/5 md:w-3/5 sm:w-full w-full border-gray-400 mt-3 mb-9' />
            <div className="flex xl:gap-3 lg:gap-3 md:gap-3 sm:gap-5 gap-5 xl:mb-4 lg:mb-4 md:mb-4 sm:mb-10 mb-10 xl:px-6 xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col lg:px-6 md:px-6 sm:px-0 px-0">
                <div className="flex w-full items-start xl:flex-row lg:flex-row md:flex-col sm:flex-col flex-col xl:gap-3 lg:gap-3 md:gap-3 sm:gap-5 gap-5">
                    {
                        editView
                            ?
                            <>
                                <span className='secondary-heading-color text-xl font-family-700 font-medium'>
                                    I am Interested in:
                                </span>
                                <ReactTags
                                    tags={intrestedIn}
                                    suggestions={suggestionsInterestedIn}
                                    delimiters={delimiters}
                                    placeholder="Technical Job Positions I am interested in"
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    inputFieldPosition="bottom"
                                    allowDragDrop={false}
                                    autocomplete
                                />

                            </>
                            :
                            <span className='secondary-heading-color text-xl font-family-700 font-medium'>
                                I am Interested in:
                            </span>
                    }
                </div>

                <div className="flex align-middle w-full flex-wrap items-center gap-3">
                    {
                        userInfo?.interested_in?.length > 0 ?
                            userInfo?.interested_in?.map((item, index) => {
                                return (
                                    <span key={item?.intin_id} className='flex align-middle tags-color px-3 rounded-full secondary-heading-color gap-3 font-family-500 font-medium text-lg justify-between items-center'>
                                        {
                                            item?.title
                                        }
                                        {
                                            editView ?
                                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.24454 10.1121C9.00809 10.3393 8.63225 10.3318 8.40508 10.0953L0.410839 1.77422C0.183673 1.53777 0.191202 1.16193 0.427656 0.934759C0.66411 0.707592 1.03995 0.715121 1.26712 0.951575L9.26136 9.27265C9.48853 9.50911 9.481 9.88495 9.24454 10.1121Z" fill="#4F4F4F" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.446861 10.1121C0.683316 10.3393 1.05916 10.3318 1.28632 10.0953L9.28057 1.77422C9.50773 1.53777 9.5002 1.16193 9.26375 0.934759C9.0273 0.707592 8.65146 0.715121 8.42429 0.951575L0.430045 9.27265C0.202878 9.50911 0.210407 9.88495 0.446861 10.1121Z" fill="#4F4F4F" />
                                                </svg>
                                                :
                                                ""
                                        }
                                    </span>
                                )
                            })
                            :
                            null
                    }
                </div>
            </div>
            <div className="flex xl:gap-3 lg:gap-3 md:gap-3 sm:gap-5 gap-5 xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col xl:px-6 lg:px-6 md:px-6 sm:px-0 px-0">
                <div className="flex w-full items-start xl:flex-row lg:flex-row md:flex-col sm:flex-col flex-col xl:gap-3 lg:gap-3 md:gap-3 sm:gap-5 gap-5">
                    {
                        editView
                            ?
                            <>
                                <span className='secondary-heading-color text-xl font-family-700 font-medium'>
                                    Tech Tags:
                                </span>
                                <ReactTags
                                    tags={tags}
                                    suggestions={suggestionsTechTags}
                                    delimiters={delimiters}
                                    placeholder="My Technical Skills"
                                    handleDelete={handleTechTagsDelete}
                                    handleAddition={handleTechTagsAddition}
                                    inputFieldPosition="bottom"
                                    allowDragDrop={false}
                                    autocomplete
                                />
                            </>
                            :
                            <span className='secondary-heading-color text-xl font-family-700 font-medium'>
                                Tech Tags:
                            </span>
                    }
                </div>
                <div className="flex align-middle w-full flex-wrap items-center gap-3">
                    {
                        userInfo?.tech_tags?.length > 0 ?
                            userInfo?.tech_tags?.map((item, index) => {
                                return (
                                    <span key={item?.tag_id} className='flex align-middle tags-color px-3 rounded-full secondary-heading-color gap-3 font-family-500 font-medium text-lg justify-between items-center'>
                                        {
                                            item?.tag_name
                                        }
                                        {
                                            editView ?
                                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.24454 10.1121C9.00809 10.3393 8.63225 10.3318 8.40508 10.0953L0.410839 1.77422C0.183673 1.53777 0.191202 1.16193 0.427656 0.934759C0.66411 0.707592 1.03995 0.715121 1.26712 0.951575L9.26136 9.27265C9.48853 9.50911 9.481 9.88495 9.24454 10.1121Z" fill="#4F4F4F" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.446861 10.1121C0.683316 10.3393 1.05916 10.3318 1.28632 10.0953L9.28057 1.77422C9.50773 1.53777 9.5002 1.16193 9.26375 0.934759C9.0273 0.707592 8.65146 0.715121 8.42429 0.951575L0.430045 9.27265C0.202878 9.50911 0.210407 9.88495 0.446861 10.1121Z" fill="#4F4F4F" />
                                                </svg>
                                                :
                                                ""
                                        }
                                    </span>
                                )
                            })
                            :
                            null
                    }
                </div>
            </div>
        </>
    )
}

export default CandidateProfileSection