import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import ShortCall from '../assets/images/short-call.png'
import Test from '../assets/images/test.png'
const CandidateScheduledActivities = ({ job, activity }) => {

    const navigate = useNavigate()
    var date1 = new Date(job?.created_at);
    var date2 = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return (
        <>
            <div className=" xl:grid-cols-7 my-10 lg:grid-cols-7 md:grid-cols-7 xl:grid lg:grid md:grid sm:hidden hidden sm:grid-cols-3 grid-cols-1 align-top items-start ">
                <div className='flex align-middle items-center xl:justify-between lg:justify-between md:justify-around sm:justify-around justify-around xl:gap-5 lg:gap-5 md:gap-0 sm:gap-0 gap-0 '>
                    {
                        activity == "interview"
                            ?
                            <>
                                <span className='xl:w-14 lg:w-14 md:w-10 sm:w-8 w-8'>
                                    <svg width="100%" height="auto" viewBox="0 0 52 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4164 6.23875H3.95137C2.642 6.23875 1.58055 7.3002 1.58055 8.60957V36.199C1.58055 37.5084 2.642 38.5698 3.95137 38.5698H47.4164C48.7258 38.5698 49.7873 37.5084 49.7873 36.199V8.60957C49.7873 7.3002 48.7258 6.23875 47.4164 6.23875ZM3.95137 4.6582C1.76909 4.6582 0 6.42729 0 8.60957V36.199C0 38.3813 1.76909 40.1504 3.95137 40.1504H47.4164C49.5987 40.1504 51.3678 38.3813 51.3678 36.199V8.60957C51.3678 6.42729 49.5987 4.6582 47.4164 4.6582H3.95137Z" fill="#30B68E" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.395 1.72899H20.9043C20.315 1.72899 19.8374 2.20664 19.8374 2.79585V4.44921H31.4619V2.79586C31.4619 2.20664 30.9842 1.72899 30.395 1.72899ZM20.9043 0.148438C19.4421 0.148438 18.2568 1.33372 18.2568 2.79585V6.02976H33.0424V2.79586C33.0424 1.33373 31.8571 0.148438 30.395 0.148438H20.9043Z" fill="#30B68E" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.95137 6.21141H47.4164C48.7258 6.21141 49.7873 7.27286 49.7873 8.58223V18.4256C49.7873 19.7349 48.7258 20.7964 47.4164 20.7964H40.7717C40.8262 21.316 40.8551 21.8433 40.8571 22.377H47.4164C49.5987 22.377 51.3678 20.6079 51.3678 18.4256V8.58223C51.3678 6.39995 49.5987 4.63086 47.4164 4.63086H3.95137C1.76909 4.63086 0 6.39995 0 8.58223V18.4256C0 20.6079 1.76909 22.377 3.95137 22.377H10.2737C10.2756 21.8433 10.3045 21.316 10.359 20.7964H3.95137C2.642 20.7964 1.58055 19.7349 1.58055 18.4256V8.58223C1.58055 7.27286 2.642 6.21141 3.95137 6.21141Z" fill="#30B68E" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6523 36.6458C33.5247 36.6458 39.9065 30.2639 39.9065 22.3916C39.9065 14.5192 33.5247 8.13738 25.6523 8.13738C17.7799 8.13738 11.3981 14.5192 11.3981 22.3916C11.3981 30.2639 17.7799 36.6458 25.6523 36.6458ZM25.6523 38.5527C34.5778 38.5527 41.8134 31.3171 41.8134 22.3916C41.8134 13.466 34.5778 6.23047 25.6523 6.23047C16.7268 6.23047 9.49121 13.466 9.49121 22.3916C9.49121 31.3171 16.7268 38.5527 25.6523 38.5527Z" fill="#30B68E" />
                                        <path d="M25.7002 9.0958L25.6361 9.0957C25.1096 9.0957 24.6826 9.52257 24.6826 10.0492C24.6826 10.5758 25.1096 11.0026 25.6361 11.0026L25.6913 11.0027C25.6928 11.0027 25.6942 11.0027 25.6959 11.0027C26.2204 11.0027 26.6467 10.5788 26.6492 10.0537C26.6516 9.52714 26.2267 9.09828 25.7002 9.0958Z" fill="#30B68E" />
                                        <path d="M25.6848 33.7814L25.6296 33.7813C25.1007 33.7803 24.6743 34.2035 24.6719 34.7302C24.6694 35.2568 25.0945 35.6857 25.621 35.6882L25.6848 35.6884C26.2113 35.6884 26.6383 35.2614 26.6383 34.7349C26.6383 34.2084 26.2113 33.7814 25.6848 33.7814Z" fill="#30B68E" />
                                        <path d="M12.3582 22.6347L12.3581 22.6989C12.3583 23.2254 12.7853 23.6522 13.3119 23.6521C13.8385 23.6519 14.2652 23.2248 14.265 22.6983L14.2651 22.6431C14.2651 22.6416 14.2651 22.6402 14.2651 22.6385C14.2649 22.114 13.8409 21.6878 13.3158 21.6855C12.7892 21.6832 12.3605 22.1082 12.3582 22.6347Z" fill="#30B68E" />
                                        <path d="M37.0497 22.6374L37.0495 22.6926C37.0487 23.2216 37.4721 23.6479 37.9988 23.6501C38.5254 23.6524 38.9541 23.2272 38.9564 22.7007L38.9566 22.6369C38.9565 22.1104 38.5294 21.6835 38.0029 21.6837C37.4764 21.6838 37.0495 22.1109 37.0497 22.6374Z" fill="#30B68E" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6263 14.1484C26.1529 14.1484 26.5798 14.5753 26.5798 15.1019V23.5741L32.498 29.4923C32.8703 29.8647 32.8703 30.4684 32.498 30.8407C32.1256 31.2131 31.5219 31.2131 31.1496 30.8407L24.9521 24.6432C24.7733 24.4644 24.6729 24.2219 24.6729 23.969V15.1019C24.6729 14.5753 25.0997 14.1484 25.6263 14.1484Z" fill="#30B68E" />
                                    </svg>
                                </span>
                                <span className='font-family-500 font-medium xl:text-xl lg:text-sm md:text-sm sm:text-sm text-sm secondary-heading-color'>
                                    Interview
                                </span>
                            </>
                            : activity == "test"
                                ?
                                <>
                                    <span className='xl:w-14 lg:w-14 md:w-10 sm:w-8 w-8'>
                                        <img src={Test} />
                                    </span>
                                    <span className='font-family-500 font-medium xl:text-xl lg:text-sm md:text-sm sm:text-sm text-sm secondary-heading-color'>
                                        Test
                                    </span>
                                </>
                                :
                                <>
                                    <span className='xl:w-14 lg:w-14 md:w-10 sm:w-8 w-8'>
                                        <img src={ShortCall} />
                                    </span>
                                    <span className='font-family-500 font-medium xl:text-xl lg:text-sm md:text-sm sm:text-sm text-sm secondary-heading-color'>
                                        Short Call
                                    </span>
                                </>

                    }
                </div>
                <span className='xl:text-lg lg:text-sm md:text-sm text-sm sm:text-sm w-full  xl:px-3 xl:pl-10 lg:px-3 px-3 border-b-2 border-gray-300 font-fmaily-700 font-bold secondary-heading-color pb-3'>
                    Job Title
                </span>
                <span className='xl:text-lg lg:text-sm md:text-sm text-sm sm:text-sm w-full px-3 border-b-2 border-gray-300 font-fmaily-700 font-bold secondary-heading-color pb-3'>
                    Company Name
                </span>
                <span className='xl:text-lg lg:text-sm md:text-sm text-sm sm:text-sm w-full px-3 border-b-2 border-gray-300 font-fmaily-700 font-bold secondary-heading-color pb-3'>
                    Call Date
                </span>
                <span className='xl:text-lg lg:text-sm md:text-sm text-sm sm:text-sm w-full px-3 border-b-2 border-gray-300 font-fmaily-700 font-bold secondary-heading-color pb-3'>
                    Call Time
                </span>
                <span className='xl:text-lg lg:text-sm md:text-sm text-sm sm:text-sm w-full px-3 border-b-2 border-gray-300 font-fmaily-700 font-bold secondary-heading-color pb-3'>
                    Meeting Url
                </span>
                <span className='xl:text-lg lg:text-sm md:text-sm text-sm sm:text-sm w-full px-3 border-b-2 border-gray-300 font-fmaily-700 font-bold secondary-heading-color pb-3'>
                    {
                        activity == "interview"
                            ?
                            "Interview Status"
                            :
                            activity == "call"
                                ?
                                "Call Status"
                                :
                                "Test Status"
                    }
                </span>

                {/* Data Row */}
                <div className='flex align-middle   items-center gap-5 '>
                </div>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium xl:py-2 xl:px-5 lg:py-2 lg:px-3 py-2 px-3'>
                    Technical Support
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    Dell Canada
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    22/08/2022
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    1:45 PM Est
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    URL
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium pb-2'>
                    <div className='relative w-full'>
                        <select id="countries"
                            className={`bg-white border font-family-500 font-normal border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color appearance-none outline-none rounded-full block w-full  px-2 py-1  lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg`}>
                            <option selected className='secondary-heading-color'>
                                {
                                    activity == "interview"
                                        ?
                                        "Interview Status"
                                        :
                                        activity == "call"
                                            ?
                                            "Call Status"
                                            :
                                            "Test Status"
                                }
                            </option>
                            <option value="completed">Completed</option>
                            <option value="pending">Pending</option>

                        </select>
                        <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="12" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                        </svg>
                    </div>
                </span>

                {/* Data Row */}
                <div className='flex align-middle   items-center gap-5 '>
                </div>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium xl:py-2 xl:px-5 lg:py-2 lg:px-3 py-2 px-3'>
                    Technical Support
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    Dell Canada
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    22/08/2022
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    1:45 PM Est
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium py-2 px-3'>
                    URL
                </span>
                <span className='primary-heading-color font-family-500 lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg font-medium pb-2'>
                    <div className='relative w-full'>
                        <select id="countries"
                            className={`bg-white border font-family-500 font-normal border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color appearance-none outline-none rounded-full block w-full  px-2 py-1  lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg`}>
                            <option selected className='secondary-heading-color'>
                                {
                                    activity == "interview"
                                        ?
                                        "Interview Status"
                                        :
                                        activity == "call"
                                            ?
                                            "Call Status"
                                            :
                                            "Test Status"
                                }
                            </option>
                            <option value="completed">Completed</option>
                            <option value="pending">Pending</option>

                        </select>
                        <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="12" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                        </svg>
                    </div>
                </span>
            </div>
            <div className='flex mb-6 xl:hidden py-4 lg:hidden md:hidden sm:flex align-middle items-center xl:justify-between lg:justify-between md:justify-around sm:justify-start justify-start xl:gap-5 lg:gap-5 md:gap-0 sm:gap-5 gap-5 '>
                {
                    activity == "interview"
                        ?
                        <>
                            <span className='xl:w-14 lg:w-14 md:w-10 sm:w-10 w-10'>
                                <svg width="100%" height="auto" viewBox="0 0 52 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4164 6.23875H3.95137C2.642 6.23875 1.58055 7.3002 1.58055 8.60957V36.199C1.58055 37.5084 2.642 38.5698 3.95137 38.5698H47.4164C48.7258 38.5698 49.7873 37.5084 49.7873 36.199V8.60957C49.7873 7.3002 48.7258 6.23875 47.4164 6.23875ZM3.95137 4.6582C1.76909 4.6582 0 6.42729 0 8.60957V36.199C0 38.3813 1.76909 40.1504 3.95137 40.1504H47.4164C49.5987 40.1504 51.3678 38.3813 51.3678 36.199V8.60957C51.3678 6.42729 49.5987 4.6582 47.4164 4.6582H3.95137Z" fill="#30B68E" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.395 1.72899H20.9043C20.315 1.72899 19.8374 2.20664 19.8374 2.79585V4.44921H31.4619V2.79586C31.4619 2.20664 30.9842 1.72899 30.395 1.72899ZM20.9043 0.148438C19.4421 0.148438 18.2568 1.33372 18.2568 2.79585V6.02976H33.0424V2.79586C33.0424 1.33373 31.8571 0.148438 30.395 0.148438H20.9043Z" fill="#30B68E" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.95137 6.21141H47.4164C48.7258 6.21141 49.7873 7.27286 49.7873 8.58223V18.4256C49.7873 19.7349 48.7258 20.7964 47.4164 20.7964H40.7717C40.8262 21.316 40.8551 21.8433 40.8571 22.377H47.4164C49.5987 22.377 51.3678 20.6079 51.3678 18.4256V8.58223C51.3678 6.39995 49.5987 4.63086 47.4164 4.63086H3.95137C1.76909 4.63086 0 6.39995 0 8.58223V18.4256C0 20.6079 1.76909 22.377 3.95137 22.377H10.2737C10.2756 21.8433 10.3045 21.316 10.359 20.7964H3.95137C2.642 20.7964 1.58055 19.7349 1.58055 18.4256V8.58223C1.58055 7.27286 2.642 6.21141 3.95137 6.21141Z" fill="#30B68E" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6523 36.6458C33.5247 36.6458 39.9065 30.2639 39.9065 22.3916C39.9065 14.5192 33.5247 8.13738 25.6523 8.13738C17.7799 8.13738 11.3981 14.5192 11.3981 22.3916C11.3981 30.2639 17.7799 36.6458 25.6523 36.6458ZM25.6523 38.5527C34.5778 38.5527 41.8134 31.3171 41.8134 22.3916C41.8134 13.466 34.5778 6.23047 25.6523 6.23047C16.7268 6.23047 9.49121 13.466 9.49121 22.3916C9.49121 31.3171 16.7268 38.5527 25.6523 38.5527Z" fill="#30B68E" />
                                    <path d="M25.7002 9.0958L25.6361 9.0957C25.1096 9.0957 24.6826 9.52257 24.6826 10.0492C24.6826 10.5758 25.1096 11.0026 25.6361 11.0026L25.6913 11.0027C25.6928 11.0027 25.6942 11.0027 25.6959 11.0027C26.2204 11.0027 26.6467 10.5788 26.6492 10.0537C26.6516 9.52714 26.2267 9.09828 25.7002 9.0958Z" fill="#30B68E" />
                                    <path d="M25.6848 33.7814L25.6296 33.7813C25.1007 33.7803 24.6743 34.2035 24.6719 34.7302C24.6694 35.2568 25.0945 35.6857 25.621 35.6882L25.6848 35.6884C26.2113 35.6884 26.6383 35.2614 26.6383 34.7349C26.6383 34.2084 26.2113 33.7814 25.6848 33.7814Z" fill="#30B68E" />
                                    <path d="M12.3582 22.6347L12.3581 22.6989C12.3583 23.2254 12.7853 23.6522 13.3119 23.6521C13.8385 23.6519 14.2652 23.2248 14.265 22.6983L14.2651 22.6431C14.2651 22.6416 14.2651 22.6402 14.2651 22.6385C14.2649 22.114 13.8409 21.6878 13.3158 21.6855C12.7892 21.6832 12.3605 22.1082 12.3582 22.6347Z" fill="#30B68E" />
                                    <path d="M37.0497 22.6374L37.0495 22.6926C37.0487 23.2216 37.4721 23.6479 37.9988 23.6501C38.5254 23.6524 38.9541 23.2272 38.9564 22.7007L38.9566 22.6369C38.9565 22.1104 38.5294 21.6835 38.0029 21.6837C37.4764 21.6838 37.0495 22.1109 37.0497 22.6374Z" fill="#30B68E" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.6263 14.1484C26.1529 14.1484 26.5798 14.5753 26.5798 15.1019V23.5741L32.498 29.4923C32.8703 29.8647 32.8703 30.4684 32.498 30.8407C32.1256 31.2131 31.5219 31.2131 31.1496 30.8407L24.9521 24.6432C24.7733 24.4644 24.6729 24.2219 24.6729 23.969V15.1019C24.6729 14.5753 25.0997 14.1484 25.6263 14.1484Z" fill="#30B68E" />
                                </svg>
                            </span>
                            <span className='font-family-500 font-medium xl:text-xl lg:text-sm md:text-sm sm:text-xl text-xl secondary-heading-color'>
                                Interview
                            </span>
                        </>
                        : activity == "test"
                            ?
                            <>
                                <span className='xl:w-14 lg:w-14 md:w-10 sm:w-10 w-10'>
                                    <img src={Test} />
                                </span>
                                <span className='font-family-500 font-medium xl:text-xl lg:text-sm md:text-sm sm:text-xl text-xl  secondary-heading-color'>
                                    Test
                                </span>
                            </>
                            :
                            <>
                                <span className='xl:w-14 lg:w-14 md:w-10 sm:w-10 w-10'>
                                    <img src={ShortCall} />
                                </span>
                                <span className='font-family-500 font-medium xl:text-xl lg:text-sm md:text-sm sm:text-xl text-xl  secondary-heading-color'>
                                    Short Call
                                </span>
                            </>

                }
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center pb-2 border-b-2 border-gray-400">
                <span className='font-family-500 font-medium text-xl secondary-heading-color'>Job Title</span>
                <span className='font-family-500 font-medium text-xl secondary-heading-color'>Company Name</span>
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center py-2">
                <span className='font-family-500 font-medium text-xl primary-heading-color'>Technical Support</span>
                <span className='font-family-500 font-medium text-xl primary-heading-color'>Dell Canada</span>
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center mb-6 py-2">
                <span className='font-family-500 font-medium text-xl primary-heading-color'>Application Support</span>
                <span className='font-family-500 font-medium text-xl primary-heading-color'>Home Depot</span>
            </div>

            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center pb-2 border-b-2 border-gray-400">
                <span className='font-family-500 font-medium text-xl secondary-heading-color'>Call Date</span>
                <span className='font-family-500 font-medium text-xl secondary-heading-color'>Call Time</span>
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center py-2">
                <span className='font-family-500 font-medium text-xl primary-heading-color'>22/08/2022</span>
                <span className='font-family-500 font-medium text-xl primary-heading-color'>1:45 PM Est</span>
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center mb-6 py-2">
                <span className='font-family-500 font-medium text-xl primary-heading-color'>12/08/2022</span>
                <span className='font-family-500 font-medium text-xl primary-heading-color'>2:00 PM Est</span>
            </div>

            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center pb-2 border-b-2 border-gray-400">
                <span className='font-family-500 font-medium text-xl secondary-heading-color'>Meeting URL</span>
                <span className='font-family-500 font-medium text-xl secondary-heading-color'>Call Status</span>
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center py-2">
                <span className='font-family-500 font-medium text-xl primary-heading-color'>URL</span>
                <span className='font-family-500 font-medium text-xl primary-heading-color'>
                    <div className='relative w-full'>
                        <select id="countries"
                            className={`bg-white border font-family-500 font-normal border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color appearance-none outline-none rounded-full block w-full  pl-2 pr-10 py-1  lg:text-sm md:text-sm sm:text-sm text-sm xl:text-lg`}>
                            <option selected className='secondary-heading-color'>
                                {
                                    activity == "interview"
                                        ?
                                        "Interview Status"
                                        :
                                        activity == "call"
                                            ?
                                            "Call Status"
                                            :
                                            "Test Status"
                                }
                            </option>
                            <option value="completed">Completed</option>
                            <option value="pending">Pending</option>

                        </select>
                        <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="12" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                        </svg>
                    </div>
                </span>
            </div>
            <div className="flex xl:hidden lg:hidden md:hidden sm:flex  align-middle justify-between items-center mb-6 py-2">
                <span className='font-family-500 font-medium text-xl primary-heading-color'>URL</span>
                <span className='font-family-500 font-medium text-xl primary-heading-color'>2:00 PM Est</span>
            </div>

        </>

    )
}

export default CandidateScheduledActivities