import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
// import CompanyLogo2 from '../assets/images/company-card-1.png'
// import CompanyLogo3 from '../assets/images/company-card-2.png'

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const CompanyInfoCard = ({ countriesList, route }) => {
    const [countries, setCountries] = useState([])

    useEffect(() => {
        setCountries(countriesList)
    }, [countriesList])


    const swiperDefault = useSwiper();
    const navigate = useNavigate()

    return (
        <>
            {
                countries?.length > 0
                    ?
                    <div className="main-container">
                        {
                            route == "FinJob" ?
                                <div className="xl:grid lg:grid md:grid sm:grid hidden xl:grid-cols-5 xl:gap-8 lg:gap-6 md:gap-6 gap-6 lg:grid-cols-4 md:grid-cols-3  sm:grid-cols-2 grid-cols-1 xl:py-20 lg:py-14 md:py-10 py-10  ">
                                    {countries?.map((items, index) => {
                                        if (index < 5) {
                                            return (
                                                <a onClick={() => { navigate(`/company-info/${items?.id}`) }} >
                                                    <div key={index} id={items?.id} className="company-card h-full py-5 px-8 cursor-pointer "
                                                    // onClick={() => { navigate('/company-info') }}
                                                    >
                                                        <img width="50px" src={items?.logo ? `https://enthostalenthub.com/et/public/upload/${items?.logo}` : CompanyLogo1} className="mb-2" alt="" />
                                                        <h5 className='mb-2 font-normal' >
                                                            {items?.company_name}
                                                        </h5>
                                                        <p className='mb-0 text-base font-normal ' >
                                                            {items?.company_info?.substring(0, 70)}
                                                        </p>
                                                    </div>
                                                </a>
                                            )
                                        }
                                    })}
                                </div>
                                :

                                <div className="xl:grid lg:grid md:grid sm:grid hidden xl:grid-cols-5 xl:gap-8 lg:gap-6 md:gap-6 gap-6 lg:grid-cols-4 md:grid-cols-3  sm:grid-cols-2 grid-cols-1 xl:py-20 lg:py-14 md:py-10 py-10  ">
                                    {countries?.map((items, index) => {
                                        if (index < 15) {
                                            return (
                                                <a onClick={() => { navigate(`/company-info/${items?.id}`) }} >
                                                    <div key={index} id={items?.id} className="company-card h-full py-5 px-8 cursor-pointer "
                                                    // onClick={() => { navigate('/company-info') }}
                                                    >
                                                        <img width="50px" src={items?.logo ? `https://enthostalenthub.com/et/public/upload/${items?.logo}` : CompanyLogo1} className="mb-2" alt="" />
                                                        <h5 className='mb-2 font-normal' >
                                                            {items?.company_name}
                                                        </h5>
                                                        <p className='mb-0 text-base font-normal ' >
                                                            {items?.company_info?.substring(0, 70)}
                                                        </p>
                                                    </div>
                                                </a>
                                            )
                                        }
                                    })}
                                </div>
                        }
                        {
                            route == "FinJob" ?
                                <Swiper navigation={true} modules={[Navigation]} className="mySwiper xl:hidden lg:hidden md:hidden sm:hidden block xl:py-20 lg:py-14 md:py-10 py-10">
                                    {countries?.map((items, index) => {
                                        if (index < 5) {
                                            return (
                                                <SwiperSlide key={index} id={items?.id}>
                                                    <a onClick={() => { navigate(`/company-info/${items?.id}`) }} >
                                                        <div className="company-card py-6 px-14 cursor-pointer "
                                                        // onClick={() => { navigate('/company-info') }}
                                                        >
                                                            <img width="50px" src={items?.logo ? `https://enthostalenthub.com/et/public/upload/${items?.logo}` : CompanyLogo1} className="mb-2 block w-10 mx-auto" alt="" />
                                                            <h5 className='mb-2 text-2xl font-medium font-family-500 text-center' >
                                                                {items?.company_name}
                                                            </h5>
                                                            <p className='mb-0 text-xl font-medium font-family-500 text-center ' >
                                                                {items?.company_info?.substring(0, 70)}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </SwiperSlide>
                                            )
                                        }
                                    })}
                                </Swiper>
                                :
                                <Swiper navigation={true} modules={[Navigation]} className="mySwiper xl:hidden lg:hidden md:hidden sm:hidden block xl:py-20 lg:py-14 md:py-10 py-10">
                                    {countries?.map((items, index) => {
                                        if (index < 15) {
                                            return (
                                                <SwiperSlide key={index} id={items?.id}>
                                                    <a onClick={() => { navigate(`/company-info/${items?.id}`) }} >
                                                        <div className="company-card py-6 px-14 cursor-pointer "
                                                        // onClick={() => { navigate('/company-info') }}
                                                        >
                                                            <img width="50px" src={items?.logo ? `https://enthostalenthub.com/et/public/upload/${items?.logo}` : CompanyLogo1} className="mb-2 block w-10 mx-auto" alt="" />
                                                            <h5 className='mb-2 text-2xl font-medium font-family-500 text-center' >
                                                                {items?.company_name}
                                                            </h5>
                                                            <p className='mb-0 text-xl font-medium font-family-500 text-center ' >
                                                                {items?.company_info?.substring(0, 70)}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </SwiperSlide>
                                            )
                                        }
                                    })}
                                </Swiper>
                        }
                    </div>
                    :
                    <div className="main-container xl:py-10 lg:py-10 md:py-10 py-10">
                    </div>
            }
        </>
    )
}

export default CompanyInfoCard