import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import CompanyLogo1 from '../assets/images/company-card.png'
const CompanyInformationBanner = ({ companyInformation }) => {

    const navigate = useNavigate()

    const [companyInfo, setCompanyInfo] = useState([])
    const [showCompanyInfo, setShowCompanyInfo] = useState(false)


    useEffect(() => {
        setCompanyInfo(companyInformation[0])
    }, [companyInformation])

    return (
        <div className='main-container'>
            <div className=' xl:py-20 lg:py-14 md:py-10 sm:py-10 py-10'>
                <div className='flex align-middle items-center justify-start '>
                    <img src={companyInfo?.logo ? `https://enthostalenthub.com/et/public/upload/${companyInfo?.logo}` : CompanyLogo1} className="mr-3 w-9 h-9" />
                    <h3 className='primary-heading-color font-family-500 font-semibold'>
                        {companyInfo?.company_name}
                    </h3>
                </div>
                <p onClick={() => {
                    setShowCompanyInfo(!showCompanyInfo)
                }} className='flex cursor-pointer align-middle justify-start items-center mt-3 secondary-heading-color font-family-500 text-lg font-normal '>
                    {
                        !showCompanyInfo ?
                            <>
                                Company Information
                                <svg className='ml-2' width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5927 10.8906C10.9435 11.5444 10.4178 11.1803 10.1364 10.8906L1.19798 1.92339C1.10629 1.83141 1.0204 1.74933 0.962242 1.63171C0.634871 0.969582 1.22554 0.256329 1.8831 0.524858C1.98518 0.566547 2.0546 0.624247 2.13131 0.700873L10.8632 9.42261L19.4771 0.796048C19.5717 0.701314 19.6615 0.612927 19.7845 0.554754C20.1471 0.383233 20.527 0.537013 20.7268 0.820433C20.8773 1.03391 20.9256 1.32094 20.7873 1.5979C20.7106 1.75164 20.5838 1.86297 20.4643 1.98299L11.5927 10.8906Z" fill="#4F4F4F" />
                                </svg>
                            </>
                            :
                            <>
                                {
                                    companyInfo?.company_info
                                }
                                <svg
                                    className='ml-2'
                                    width="20" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2042 0.904329C10.8534 0.250547 11.379 0.614618 11.6605 0.904329L20.5989 9.87153C20.6906 9.96351 20.7765 10.0456 20.8346 10.1632C21.162 10.8253 20.5713 11.5386 19.9138 11.2701C19.8117 11.2284 19.7423 11.1707 19.6656 11.094L10.9337 2.37231L2.31982 10.9989C2.22522 11.0936 2.13536 11.182 2.01238 11.2402C1.64975 11.4117 1.26983 11.2579 1.07004 10.9745C0.919561 10.761 0.871271 10.474 1.00954 10.197C1.08629 10.0433 1.21304 9.93195 1.33257 9.81193L10.2042 0.904329Z" fill="#4F4F4F" />
                                </svg>

                            </>
                    }
                </p>
            </div>
        </div>
    )
}

export default CompanyInformationBanner