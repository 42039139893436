import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CanadaLogo from '../assets/images/canada.png'
import USALogo from '../assets/images/usa.png'
import PakistanLogo from '../assets/images/pakistan.png'
import MacedoniaLogo from '../assets/images/Macedonia.png'
import AlbaniaLogo from '../assets/images/albania.png'
import BosniaLogo from '../assets/images/bosnia.png'
import BrazilLogo from '../assets/images/brazil.png'
import axios from '../api/axios';
import { BiLoaderAlt } from "react-icons/bi";
import { WithContext as ReactTags } from 'react-tag-input';
import ProtectedRoute from './ProtectedRoute'

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const PostAJobForm = () => {
    const navigate = useNavigate()

    const COUNTRIES_API_URL = "/countries";
    const GET_JOB_TYPES = "/jobtypes";
    const TAGS_API_URL = "/tags";
    // const REGISTER_EMPLOYER_STEP3_URL = "/employer/step3";
    const REGISTER_EMPLOYER_STEP3_URL = "/jobs/create";
    const regexNumber = new RegExp('^[0-9]*$')
    const GET_STATE_BY_COUNTRY_ID = "country"



    const [jobTitleError, setJobTitleError] = useState("")
    const [salaryError, setSalaryError] = useState("")
    const [requirementError, setRequirementsError] = useState("")
    const [responsibilitiesError, setResponsibilitiesError] = useState("")
    const [jobRequirementsError, setJobRequirementsError] = useState("")
    const [niceToHaveError, setNiceToHaveError] = useState("")
    const [benefitsError, setBenefitsError] = useState("")
    const [jobTypeError, setJobTypeError] = useState("")
    const [countryListError, setCountryListError] = useState("")
    const [cityListError, setCityListError] = useState("")
    const [skillError, setSkillError] = useState("")
    const [tags, setTags] = useState([])
    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)



    const [countriesList, setCountriesList] = useState([])
    const [tagsList, setTagsList] = useState([])
    // STEP THREE DATA
    const [jobTitle, setJobTitle] = useState("")
    const [salary, setSalary] = useState("")
    const [requirements, setRequirements] = useState("")
    const [responsibilites, setResponsibilities] = useState("")
    const [jobRequirements, setJobRequirements] = useState("")
    const [niceToHave, setNiceToHave] = useState("")
    const [benefits, setBenefits] = useState("")
    const [jobType, setJobType] = useState("")
    const [countryList, setCountryList] = useState([])
    const [jobTypeList, setJobTypeList] = useState([])
    const [citiesList, setCitiesList] = useState([])
    const [selectedCity, setSelectedCity] = useState([])


    const fetchTagsData = async () => {
        try {
            const response = await axios.get(TAGS_API_URL);
            setTagsList(response?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchJobTypes = async () => {
        try {
            const response = await axios.get(GET_JOB_TYPES);
            // setTagsList(response.data)
            setJobTypeList(response?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchSelectedCountryStates = async (e) => {
        try {
            const response = await axios.post(GET_STATE_BY_COUNTRY_ID, {
                country_id: 5
            });
            console.log('response?.data ', response?.data)
            setCitiesList(response?.data?.data[0]?.cities)
            // setCountriesList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    // const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const delimiters = [KeyCodes?.comma];

    const handleTechTagsDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTechTagsAddition = tag => {
        if (tags?.length < 5) {
            setTags([...tags, tag]);
            setSkillError("")
        } else {
            setSkillError("Technical Skills are limited to 5")
        }
    };

    const suggestionsTechTags = tagsList?.map(item => {
        return {
            id: `${item?.id}`,
            className: "tag-errors",
            text: item?.tag
        };
    });

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCountriesList(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchTagsData()
        fetchCountriesData()
        fetchJobTypes()
        fetchSelectedCountryStates()
    }, [])

    const onlyLettersAndSpaces = (str) => {
        return /^[A-Za-z\s]*$/.test(str);
    }

    const onlyNumbers = (str) => {
        return regexNumber?.test(str);
    }

    const validateJobTitle = (e) => {
        // if (jobTitle == "") {
        //     setJobTitleError("Title can not be empty")
        // } else if (!onlyLettersAndSpaces(jobTitle)) {
        //     setJobTitleError("Only Alphabets are Allowed")
        // } else {
        //     setJobTitleError("")
        // }
        if (e == "Choose a Job Title" || e == "") {
            setJobTitleError("Please select a valid Job Title")
        } else {
            setJobTitleError("")
        }
    }

    // const validateSalary = () => {
    //     if (salary == "") {
    //         setSalaryError("Salary can not be empty")
    //     } else if (!onlyNumbers(salary)) {
    //         setSalaryError("Only Numbers are Allowed")
    //     } else {
    //         setSalaryError("")
    //     }
    // }

    const validateRequirements = () => {
        if (requirements == "") {
            setRequirementsError("Short Description is Required")
        } else {
            setRequirementsError("")
        }
    }

    const validateResponsibilities = () => {
        if (responsibilites == "") {
            setResponsibilitiesError("Responsibilities can not be empty")
        } else {
            setResponsibilitiesError("")
        }
    }

    const validateJobRequirements = () => {
        if (jobRequirements == "") {
            setJobRequirementsError("Job Requirements can not be empty")
        } else {
            setJobRequirementsError("")
        }
    }

    const validateNiceToHave = () => {
        if (niceToHave == "") {
            setNiceToHaveError("Field can not be empty")
        } else {
            setNiceToHaveError("")
        }
    }

    const validateBenefits = () => {
        if (benefits == "") {
            setBenefitsError("Benefits can not be empty")
        } else {
            setBenefitsError("")
        }
    }

    const validateSkills = () => {
        if (tags?.length == "") {
            setSkillError("Skills can not be empty");
        } else {
            setSkillError("");
        }
    };

    const validateJobType = (e) => {
        if (e == "Job Type" || e == "") {
            setJobTypeError("Please select a valid Job Type")
        } else {
            setJobTypeError("")
        }
    }

    const validateCountryList = () => {
        if (countryList?.length == 0) {
            setCountryListError("Please select at least 1 country")
        }
    }
    const validateCityList = () => {
        if (selectedCity?.length == 0) {
            setCityListError("Please select at least 1 Country")
        }
    }


    const handleCityySelection = (cityId) => {
        let cArray = [...selectedCity]
        console.log('cArray?.length ', cArray?.length)

        const filteredCArray = cArray?.filter((item) => item == cityId)
        if (filteredCArray?.length > 0) {
            const index = cArray?.indexOf(filteredCArray[0]);
            if (index > -1) { // only splice array when item is found
                cArray?.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else if (cArray?.length < 3) {
            cArray?.push(cityId)
        } else if (cArray?.length == 3) {
            setCityListError("")
        } else if (cArray?.length == 0) {
            setCityListError("Please select at least 1 city")
        } else {
            setCityListError("You can maximum select 3 cities")
        }
        // if (cArray?.length > 0) {
        //     setCityListError("")
        // } else {
        //     setCityListError("Please select at least one city")
        // }
        setSelectedCity(cArray)
    }

    const handleCountrySelection = (countryId) => {
        let cArray = [...countryList]
        const filteredCArray = cArray?.filter((item) => item == countryId)
        if (filteredCArray?.length > 0) {
            const index = cArray?.indexOf(filteredCArray[0]);
            if (index > -1) { // only splice array when item is found
                cArray?.splice(index, 1); // 2nd parameter means remove one item only
            }
        } else {
            cArray?.push(countryId)
        }
        if (cArray?.length > 0) {
            setCountryListError("")
        } else {
            setCountryListError("Please select at least one country")
        }
        setCountryList(cArray)
    }

    const validateJobPostingInformation = () => {
        validateJobTitle()
        // validateSalary()
        validateRequirements()
        validateJobType(jobType)
        validateSkills()
        // validateResponsibilities()
        // validateNiceToHave()
        // validateJobRequirements()
        // validateBenefits()
        // validateCountryList()
        validateCityList()
    }

    const postJob = async () => {
        const userData = localStorage.getItem("userInfo")
        const user = JSON.parse(userData)
        if (
            jobTitleError == "" && jobTitle != "" &&
            requirementError == "" && requirements != "" &&
            jobTypeError == "" && jobType != "" &&
            tags?.length > 0 &&
            selectedCity?.length > 0 && cityListError == ""
            // responsibilitiesError == "" && responsibilites != "" &&
            // jobRequirementsError == "" && jobRequirements != "" &&
            // niceToHaveError == "" && niceToHave != "" &&
            // benefitsError == "" && benefits != "" &&
            // countryListError == "" && countryList?.length > 0
        ) {
            setLoader(true)
            try {
                const techTagsArr = []
                const tech_tagsClone = tags

                for (let i = 0; i < tech_tagsClone?.length; i++) {
                    // techTagsArr.push(tech_tagsClone[i].id)
                    techTagsArr.push(parseInt(tech_tagsClone[i]?.id))
                }

                let post_data = {
                    "title": jobTitle,
                    "description": requirements, // SHORT DESCRIPTION
                    "salary": salary,
                    "job_type": jobType,
                    // "skills": skills, // array
                    "skills": techTagsArr, // array
                    // "roles_and_responsibilities": responsibilites,
                    "job_requirements": JSON.stringify(jobRequirements), // LONG DESCRIPTION
                    // "nice_to_have": niceToHave,
                    // "benifits": benefits,
                    "countries": [5], // country ids array
                    "cities": selectedCity,
                    "token": user?.token
                }

                console.log('post date', post_data)

                const response = await axios.post(
                    REGISTER_EMPLOYER_STEP3_URL,
                    JSON.stringify(post_data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                console.log("response", response)
                if (!response.data.error) {
                    navigate('/employer-dashboard')
                }

            } catch (err) {
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
        }

        setTimeout(() => {
            setLoader(false)
            setApiResponseError("")
        }, 10000)
    }

    const onEditorStateChange = (editorState) => {
        // console.log('editorState ', editorState)
        setJobRequirements(editorState)
    };

    return (
        <ProtectedRoute >
            <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>

                <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                    <h2 className='font-family-700 font-medium text-center primary-heading-color mb-9'>
                        Post Your Job
                    </h2>
                    <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
                        <div className="w-full relative">

                            <select
                                value={jobTitle}
                                onChange={(e) => {
                                    // validateCountry(e)
                                    if (e.target.value != "Choose a Job Title") {
                                        setJobTitle(e.target.value)
                                        setJobTitleError("")
                                    } else {
                                        setJobTitleError(e.target.value)
                                        setJobTitleError("Please select a valid Job Title")
                                    }
                                }}
                                onBlur={(e) => { validateJobTitle(jobTitle) }}
                                id="jobType"
                                className={`bg-white border font-family-500 font-normal ${jobTitleError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                            >
                                <option
                                    className="secondary-heading-color"
                                    selected
                                    value="Choose a Job Title"
                                >
                                    Choose a Job Title
                                </option>
                                {
                                    tagsList?.map((item, index) => {
                                        return (
                                            <option className="secondary-heading-color" key={item?.id} value={item?.tag}>
                                                {item?.tag}
                                            </option>
                                        )
                                    })
                                }
                            </select>

                            {/* <input
                                type="text"
                                autoComplete='off'
                                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${jobTitleError != "" ? "border-red-500 focus:border-red-500 placeholder:text-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300 "} bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white secondary-heading-color focus:outline-none`}
                                onChange={(e) => {
                                    setJobTitle(e.target.value)
                                    setJobTypeError("")
                                }}
                                value={jobTitle}
                                onBlur={validateJobTitle}
                                id="jobTitle"
                                placeholder={"Choose a Job Title"}
                            />
                            <ReactTags
                                tags={tags}
                                suggestions={suggestionsTechTags}
                                delimiters={delimiters}
                                placeholder="My Technical Skills"
                                handleDelete={handleTechTagsDelete}
                                handleAddition={handleTechTagsAddition}
                                inputFieldPosition="bottom"
                                allowDragDrop={false}
                                autocomplete
                            /> */}
                            {
                                jobTitleError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            jobTitleError ?
                                <>
                                    <span className={`text-red-600 block ${jobTitleError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            jobTitleError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>

                    <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
                        <div className="w-full relative">
                            <input
                                type="text"
                                autoComplete='off'
                                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${salaryError != "" ? "border-red-500 focus:border-red-500 placeholder:text-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300 "} bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                                onChange={(e) => {
                                    setSalary(e.target.value)
                                    setSalaryError("")
                                }}
                                value={salary}
                                // onBlur={validateSalary}
                                id="Salary"
                                placeholder={"Salary"}
                            />
                            {
                                salaryError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            salaryError ?
                                <>
                                    <span className={`text-red-600 block ${salaryError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            salaryError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>
                    <div className="flex mb-6 align-middle justify-between items-center  flex-col w-full relative">
                        <div className="w-full relative">
                            <select
                                value={jobType}
                                onChange={(e) => {
                                    // validateCountry(e)
                                    if (e.target.value != "Job Type") {
                                        setJobType(e.target.value)
                                        setJobTypeError("")
                                    } else {
                                        setJobType(e.target.value)
                                        setJobTypeError("Please select a valid Job Type")
                                    }
                                }}
                                onBlur={(e) => { validateJobType(jobType) }}
                                id="jobType"
                                className={`bg-white border font-family-500 font-normal ${jobTypeError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                            >
                                <option
                                    className="secondary-heading-color"
                                    selected
                                    value="Job Type"
                                >
                                    Job Type
                                </option>
                                {
                                    jobTypeList?.map((item, index) => {
                                        return (
                                            <option className="secondary-heading-color" key={item?.id} value={item?.title}>
                                                {item?.title}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            {/* <svg className='absolute cursor-pointer top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                            </svg> */}
                            {
                                jobTypeError ?
                                    <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    : ""
                            }
                        </div>
                        {
                            jobTypeError ?
                                <>
                                    <span className={`text-red-600 block ${jobTypeError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            jobTypeError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>


                    <div className={`flex flex-col ${skillError == "" ? "xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10" : "xl:mb-5 error-borders rounded-full lg:mb-5 md:mb-10 sm:mb-10 mb-10"} align-middle justify-between items-center w-full  relative transition ease-in-out`}>
                        <div className='relative w-full'>
                            <ReactTags
                                tags={tags}
                                suggestions={suggestionsTechTags}
                                delimiters={delimiters}
                                placeholder="My Technical Skills"
                                handleDelete={handleTechTagsDelete}
                                handleAddition={handleTechTagsAddition}
                                inputFieldPosition="bottom"
                                allowDragDrop={false}
                                autocomplete
                            />
                            {
                                skillError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    : ""
                            }
                        </div>
                        {
                            skillError ?
                                <>
                                    <span className={`text-red-600 ${skillError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                        {
                                            skillError
                                        }
                                    </span>
                                </>
                                :
                                ""
                        }
                    </div>

                    {/* <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
                        <div className="relative w-full">
                            <textarea
                                value={responsibilites}
                                onChange={(e) => {
                                    setResponsibilities(e.target.value)
                                    setResponsibilitiesError("")
                                }}
                                onBlur={validateResponsibilities}
                                id="responsibilites"
                                className={`form-control ${responsibilitiesError != "" ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500" : "secondary-heading-color border-gray-300 focus:border-gray-300"} h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                                placeholder={"Roles and Responsibilities"}>
                            </textarea>
                            {
                                responsibilitiesError ?
                                    <svg className='absolute top-5' style={{ right: "5%" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            responsibilitiesError ?
                                <>
                                    <span className={`text-red-600 block ${responsibilitiesError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            responsibilitiesError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>

                    <div className="flex relative mb-6 align-middle justify-between items-center w-full flex-col">
                        <div className="w-full relative">
                            <textarea
                                onBlur={validateJobRequirements}
                                value={jobRequirements}
                                onChange={(e) => {
                                    setJobRequirements(e.target.value)
                                    setJobRequirementsError("")
                                }}
                                id="jobRequirements"
                                className={`form-control ${jobRequirementsError != "" ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500" : "secondary-heading-color border-gray-300 focus:border-gray-300"} h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                                placeholder={"Job Requirements"}
                            >
                            </textarea>
                            {
                                jobRequirementsError ?
                                    <svg className='absolute top-5' style={{ right: "5%" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            jobRequirementsError ?
                                <>
                                    <span className={`text-red-600 block ${jobRequirementsError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            jobRequirementsError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>

                    <div className="flex relative mb-6 align-middle justify-between items-center w-full flex-col">
                        <div className="relative w-full">
                            <textarea
                                onBlur={validateNiceToHave}
                                value={niceToHave}
                                onChange={(e) => {
                                    setNiceToHave(e.target.value)
                                    setNiceToHaveError("")
                                }}
                                id="niceToHave"
                                className={`form-control ${niceToHaveError != "" ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500" : "secondary-heading-color border-gray-300 focus:border-gray-300"} h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                                placeholder={"Nice to Have"}
                            >
                            </textarea>
                            {
                                niceToHaveError ?
                                    <svg className='absolute top-5' style={{ right: "5%" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            niceToHaveError ?
                                <>
                                    <span className={`text-red-600 block ${niceToHaveError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            niceToHaveError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>

                    <div className="flex relative mb-6 align-middle justify-between items-center w-full flex-col">
                        <div className="w-full relative">
                            <textarea
                                onBlur={validateBenefits}
                                value={benefits}
                                onChange={(e) => {
                                    setBenefits(e.target.value)
                                    setBenefitsError("")
                                }}
                                id="benefits"
                                className={`form-control ${benefitsError != "" ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500" : "secondary-heading-color border-gray-300 focus:border-gray-300"} h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                                placeholder={"Benefits"}
                            >
                            </textarea>
                            {
                                benefitsError ?
                                    <svg className='absolute top-5' style={{ right: "5%" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            benefitsError ?
                                <>
                                    <span className={`text-red-600 block ${benefitsError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            benefitsError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div> */}
                    <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
                        <div className="relative w-full">
                            <textarea
                                value={requirements}
                                onChange={(e) => {
                                    setRequirements(e.target.value)
                                    setRequirementsError("")
                                }}
                                onBlur={validateRequirements}
                                id="requirements"
                                placeholder={"Short Description"}
                                className={`form-control ${requirementError != "" ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500" : "secondary-heading-color border-gray-300 focus:border-gray-300"} h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                            >
                            </textarea>
                            {
                                requirementError ?
                                    <svg className='absolute top-5' style={{ right: "5%" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :
                                    ""
                            }
                        </div>
                        {
                            requirementError ?
                                <>
                                    <span className={`text-red-600 block ${requirementError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                        {
                                            requirementError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>
                    <span className='font-family-500 font-normal block w-full mb-4 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color'>
                        Detailed Job Description
                    </span>
                    <Editor
                        // editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                    />

                    <div className="flex relative mb-6 align-middle justify-between items-center w-full gap-6 mt-6">
                        <span className={`font-family-500 font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${countryListError || cityListError != "" ? "text-red-500" : "secondary-heading-color"} `}>
                            {/* Select a Country/Countries to post a Job: */}
                            Select a City/Cities to post a Job:
                        </span>
                        {
                            countryListError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                :
                                cityListError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    :

                                    ""
                        }
                    </div>
                </div>
                <div className='flex align-middle justify-center mb-8 gap-10 signup-w-3-12-5 mx-auto'>
                    <div>
                        {
                            countriesList?.map((item, index) => {
                                const filterCountry = countryList?.filter((countryId) =>
                                    countryId == item.id
                                )
                                if (item.id == 5) {
                                    return (
                                        <div
                                            // onClick={(e) => {
                                            //     handleCountrySelection(item.id)
                                            // }}
                                            key={index}
                                            className={`flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ${filterCountry.length > 0 ? "bg-gray-200 rounded-md py-1" : ""} `}>
                                            <img src={
                                                MacedoniaLogo
                                            } alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                            <p className="text-center text-lg font-normal secondary-heading-color font-family-500">
                                                {item?.name}
                                            </p>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <ul className='flex items-center align-middle  gap-5'>
                        {
                            citiesList?.map((item) => {
                                const filterCountry = selectedCity?.filter((cityId) =>
                                    cityId == item.id
                                )
                                return (
                                    <li
                                        onClick={(e) => {
                                            handleCityySelection(item?.id)
                                        }}
                                        className="flex cursor-pointer align-middle justify-start items-center h-max gap-3" key={item?.id} value={item?.id}>
                                        <span className={`w-4 h-4 border-2 ${filterCountry?.length > 0 ? "border-gray-300 bg-gray-300" : "border-gray-300 "}  rounded-full`}>

                                        </span>
                                        <span className='secondary-heading-color font-family-500 text-base'>
                                            {item?.city}
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                {/* <div className="xl:grid lg:grid md:grid mb-14 xl:w-3/5 lg:w-4/5 w-full mx-auto sm:grid flex flex-wrap xl:grid-cols-7 lg:grid-cols-7 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 gap-6 md:grid-cols-5 sm:grid-cols-3 align-middle justify-center items-center">
                    {
                        countriesList?.map((item, index) => {
                            const filterCountry = countryList?.filter((countryId) =>
                                countryId == item.id
                            )
                            return (
                                <div
                                    onClick={(e) => {
                                        handleCountrySelection(item.id)
                                    }}
                                    key={index}
                                    className={`flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ${filterCountry.length > 0 ? "bg-gray-200 rounded-md py-1" : ""} `}>
                                    <img src={
                                        item.name == "Canada"
                                            ?
                                            CanadaLogo
                                            :
                                            item.name == "Pakistan"
                                                ?
                                                PakistanLogo
                                                : item.name == "United States"
                                                    ?
                                                    USALogo
                                                    :
                                                    item.name == "Macedonia"
                                                        ?
                                                        MacedoniaLogo
                                                        :
                                                        item.name == "Albania"
                                                            ?
                                                            AlbaniaLogo
                                                            :
                                                            item.name == "Bosnia"
                                                                ?
                                                                BosniaLogo
                                                                :
                                                                BrazilLogo
                                    } alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                                    <p className="text-center text-lg font-normal secondary-heading-color font-family-500">
                                        {item?.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div> */}
                {/* <div className="flex align-middle justify-between"></div> */}
                <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                    <button
                        disabled={jobTitleError || cityListError || requirementError || jobTypeError || skillError
                            // || responsibilitiesError || jobRequirementsError || niceToHaveError || benefitsError
                        }
                        onClick={() => {
                            jobTitleError == "" && jobTitle != "" &&
                                requirementError == "" && requirements != "" &&
                                jobTypeError == "" && jobType != "" &&
                                skillError == "" && tags.length > 0 &&
                                cityListError == "" && selectedCity?.length > 0
                                // responsibilitiesError == "" && responsibilites != "" &&
                                // jobRequirementsError == "" && jobRequirements != "" &&
                                // niceToHaveError == "" && niceToHave != "" &&
                                // benefitsError == "" && benefits != "" &&
                                // countryListError == "" && countryList.length > 0
                                ?
                                postJob()
                                :
                                validateJobPostingInformation()
                        }}
                        className='primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Post Your Job
                        {loader ? <BiLoaderAlt className="loader-spinner ml-2" /> : null}
                    </button>
                </div>
                <div className=" xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-3/4 lg:w-4/5 w-full mx-auto">
                    <hr className=' bg-white border-t-2 border-gray-300 my-0 mx-auto w-full' />
                </div>
            </div>
        </ProtectedRoute>
    )
}

export default PostAJobForm