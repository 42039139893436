import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GoogleButton from '../assets/images/google-button.png';
import axios from '../api/axios';
import { RxUpload } from 'react-icons/rx'
import { BiLoaderAlt } from 'react-icons/bi'
import { WithContext as ReactTags } from 'react-tag-input';

const ContactUsForm = () => {
    const navigate = useNavigate()
    const COMPANY_EMAILS_API = "/dd";
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const CONTACT_US_API = "/contact_us"

    const [jobTitleError, setJobTitleError] = useState("")
    const [firstNameError, setFirstNameError] = useState("")
    const [lastNameError, setLastNameError] = useState("")
    const [userNameError, setUserNameError] = useState("")
    const [companyInfoError, setCompanyInfoError] = useState("");
    const [apiResponse, setApiResponse] = useState("");

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [companyInfo, setCompanyInfo] = useState("");
    const [email, setEmail] = useState("")

    const [companyEmails, setCompanyEmails] = useState([]);
    const [loader, setLoader] = useState(false)


    const onlyLettersAndSpaces = (str) => {
        return /^[A-Za-z\s]*$/.test(str);
    }


    const fetchCompanyEmails = async () => {
        try {
            const response = await axios.get(COMPANY_EMAILS_API);
            //   console.log("response?.data ", response?.data);
            setCompanyEmails(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCompanyEmails()
    }, [])


    const validateFirstName = () => {
        if (firstName == "") {
            setFirstNameError("First Name can not be empty")
        } else if (!onlyLettersAndSpaces(firstName)) {
            setFirstNameError("Only Alphabets are Allowed")
        } else {
            setFirstNameError("")
        }
    }

    const validateJobTitle = () => {
        if (jobTitle == "") {
            setJobTitleError("Job Title can not be empty")
        } else {
            setJobTitleError("")
        }
    }

    const validateLastName = () => {
        if (lastName == "") {
            setLastNameError("Last Name can not be empty")
        } else if (!onlyLettersAndSpaces(lastName)) {
            setLastNameError("Only Alphabets are Allowed")
        } else {
            setLastNameError("")
        }
    }

    const validateEmail = () => {
        const address = email.split("@").pop();
        if (email == "") {
            setUserNameError("Email can not be empty")
        } else if (!regex.test(email)) {
            setUserNameError("Email must be a valid Email Address")
        }
        // else if (companyEmails?.includes(address)) {
        //     setUserNameError("Email must be a valid Email Address")
        // }
        else {
            setUserNameError("");
        }
    }


    const validateCompanyInfo = () => {
        if (companyInfo == "") {
            setCompanyInfoError("Message can not be empty");
        } else {
            setCompanyInfoError("");
        }
    };

    const checkValidationOfAllFields = () => {
        validateFirstName()
        validateLastName()
        validateJobTitle()
        validateCompanyInfo()
        validateEmail()        
    }


    const submitContactUs = async () => {
        setLoader(true)
        checkValidationOfAllFields()
        if (
            firstNameError == "" &&
            lastNameError == "" &&
            userNameError == "" &&
            jobTitleError == "" &&
            companyInfoError == ""
        ) {
            const obj = {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "job_title": jobTitle,
                "message": companyInfo
            }

            try {
                const response = await axios.post(
                    CONTACT_US_API,
                    obj,
                    {
                        // headers: { "Content-Type": "application/json" },
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                )
                console.log('response ', response)
                if (!response.data.error) {
                    setApiResponse(response?.data?.message)
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            alert('error ')
        }


        setTimeout(() => {
            setLoader(false)
            setFirstName("")
            setLastName("")
            setEmail("")
            setJobTitle("")
            setCompanyInfo("")
        }, 3000)
        setTimeout(() => {
            setApiResponse("")
        }, 5000)

    }


    return (
        <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
            <h2 className='font-family-700 font-medium text-center primary-heading-color mb-8'>
                Contact Us
            </h2>
            <div className="flex align-middle justify-center flex-col items-center w-3-12-5 mx-auto">

                <div className="flex mb-6 align-middle justify-between  w-full gap-6">
                    <div className='w-full relative'>
                        <div className="flex relative flex-col w-full">
                            <input
                                type="text"
                                autoComplete='off'
                                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${firstNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                                id="firstname"
                                onBlur={validateFirstName}
                                value={firstName}
                                placeholder={firstNameError != "" ? firstNameError : "First Name"}
                            />
                            {
                                firstNameError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    : ""
                            }
                        </div>
                        {
                            firstNameError ?
                                <>
                                    <span className={`text-red-600 ${firstNameError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                        {
                                            firstNameError
                                        }
                                    </span>
                                </>
                                :
                                ""
                        }
                    </div>
                    <div className='w-full relative'>
                        <div className="flex relative flex-col w-full">
                            <input
                                type="text"
                                autoComplete='off'
                                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${lastNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                onBlur={validateLastName}
                                id="lastname"
                                value={lastName}
                                placeholder={lastNameError != "" ? lastNameError : "Last Name"}
                            />
                            {
                                lastNameError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    : ""
                            }
                        </div>
                        {
                            lastNameError ?
                                <>
                                    <span className={`text-red-600 ${lastNameError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                        {
                                            lastNameError
                                        }
                                    </span>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>

                <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <div className='w-full relative'>
                        <input
                            type="email"
                            autoComplete='off'
                            className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${userNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            onBlur={validateEmail}
                            value={email}
                            id="email"
                            placeholder={userNameError != "" ? userNameError : "Email"}
                        />
                        {
                            userNameError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        userNameError ?
                            <>
                                <span className={`text-red-600 ${userNameError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        userNameError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>

                <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <div className='w-full relative'>
                        <input
                            type="text"
                            autoComplete='off'
                            className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${jobTitleError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setJobTitle(e.target.value)
                            }}
                            id="jobTitle"
                            onBlur={validateJobTitle}
                            value={jobTitle}
                            placeholder={jobTitleError != "" ? jobTitleError : "Job Title"}
                        />
                        {
                            jobTitleError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        jobTitleError ?
                            <>
                                <span className={`text-red-600 ${jobTitleError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        jobTitleError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>


                <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <div className='w-full relative'>
                        <textarea
                            onChange={(e) => {
                                setCompanyInfo(e.target.value);
                            }}
                            onBlur={validateCompanyInfo}
                            value={companyInfo}
                            id="companyInformation"
                            className={`form-control h-28 text-gray-300 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border border-solid ${companyInfoError != ""
                                ? "focus:border-red-500 placeholder:text-red-500 border-red-500"
                                : "focus:border-gray-300 secondary-heading-color border-gray-300"
                                } rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                            placeholder={
                                companyInfoError != ""
                                    ? companyInfoError
                                    : "Message"
                            }
                        ></textarea>
                        {companyInfoError ? (
                            <svg
                                className="absolute top-5"
                                style={{ right: "5%" }}
                                width="22"
                                height="19"
                                viewBox="0 0 22 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                                    fill="#EF4444"
                                />
                            </svg>
                        ) : (
                            ""
                        )}
                    </div>
                    {
                        companyInfoError ?
                            <>
                                <span className={`text-red-600 ${companyInfoError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        companyInfoError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>

                <button
                    onClick={submitContactUs}
                    disabled={firstNameError || lastNameError || userNameError || jobTitleError || companyInfoError || loader}
                    className='primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Submit
                    {
                        loader ?
                            <BiLoaderAlt className='loader-spinner ml-2' />
                            :
                            null
                    }
                </button>
                {
                    apiResponse ?
                        <span className='text-green-700 text-lg font-family-500 font-medium py-3 block w-full'>
                            {apiResponse}
                        </span>
                        :
                        ""
                }
            </div>
            <div className=" xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
                <hr className=' bg-white border-t-2 border-gray-300 my-0 mx-auto w-full' />
            </div>
        </div>
    )
}

export default ContactUsForm