import React, { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxUpload } from "react-icons/rx";
import CanadaLogo from "../assets/images/canada.png";
import USALogo from "../assets/images/usa.png";
import PakistanLogo from "../assets/images/pakistan.png";
import MacedoniaLogo from "../assets/images/Macedonia.png";
import AlbaniaLogo from "../assets/images/albania.png";
import BosniaLogo from "../assets/images/bosnia.png";
import BrazilLogo from "../assets/images/brazil.png";
import axios from "../api/axios";
import { WithContext as ReactTags } from 'react-tag-input';
import { BiLoaderAlt } from "react-icons/bi";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EmployeeSignupForm = () => {
  const navigate = useNavigate();

  const COUNTRIES_API_URL = "/countries";
  const COMPANY_EMAILS_API = "/dd";
  const REGISTER_URL = "/register";
  const GET_JOB_TYPES = "/jobtypes";
  const TAGS_API_URL = "/tags";
  const GET_STATE_BY_COUNTRY_ID = "country"
  const REGISTER_EMPLOYER_STEP2_URL = "/employer/step2";
  const PROFILE_UPLOAD_URL = "/company/logo";
  // const REGISTER_EMPLOYER_STEP3_URL = "/employer/step3";
  const REGISTER_EMPLOYER_STEP3_URL = "/jobs/create";
  const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
  const regexNumber = new RegExp("^[0-9]*$");

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})"
  );
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{4,})"
  );

  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [LinkedInError, setLinkedInError] = useState("");
  const [websiteUrlError, setWebsiteUrlError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyInfoError, setCompanyInfoError] = useState("");
  const [uploadedResumeError, setUploadedResumeError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [salaryError, setSalaryError] = useState("");
  const [requirementError, setRequirementsError] = useState("");
  const [responsibilitiesError, setResponsibilitiesError] = useState("");
  const [jobRequirementsError, setJobRequirementsError] = useState("");
  const [niceToHaveError, setNiceToHaveError] = useState("");
  const [benefitsError, setBenefitsError] = useState("");
  const [jobTypeError, setJobTypeError] = useState("");
  const [countryListError, setCountryListError] = useState("");
  const [cityListError, setCityListError] = useState("")
  const [skillError, setSkillError] = useState("");
  const [agreeError, setAgreeError] = useState(false);
  const [apiResponseError, setApiResponseError] = useState("");
  const [loader, setLoader] = useState(false);

  //  STEP ONE DATA
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [agree, setAgree] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneLength, setPhoneLength] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [companyEmails, setCompanyEmails] = useState([]);
  const [tags, setTags] = useState([])
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [linkedin, setLinkedin] = useState("");
  // STEP TWO DATA
  const [uploadedResume, setUploadedResume] = useState([]);
  const [imageData, setImageData] = useState("");
  const [stepCounter, setStepCounter] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");
  // STEP THREE DATA
  const [jobTitle, setJobTitle] = useState("");
  const [salary, setSalary] = useState("");
  const [requirements, setRequirements] = useState("");
  const [responsibilites, setResponsibilities] = useState("");
  const [jobRequirements, setJobRequirements] = useState("");
  const [niceToHave, setNiceToHave] = useState("");
  const [benefits, setBenefits] = useState("");
  // const [skills, setSkills] = useState("");
  const [jobType, setJobType] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [tagsList, setTagsList] = useState([])
  const [jobTypeList, setJobTypeList] = useState([])
  const [citiesList, setCitiesList] = useState([])
  const [selectedCity, setSelectedCity] = useState([])

  const fetchCountriesData = async () => {
    try {
      const response = await axios.get(COUNTRIES_API_URL);
      setCountriesList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchJobTypes = async () => {
    try {
      const response = await axios.get(GET_JOB_TYPES);
      console.log('response.data ', response.data)
      // setTagsList(response.data)
      setJobTypeList(response?.data)
    }
    catch (error) {
      console.log(error);
    }
  }


  const fetchTagsData = async () => {
    try {
      const response = await axios.get(TAGS_API_URL);
      setTagsList(response.data)
    }
    catch (error) {
      console.log(error);
    }
  }

  const fetchSelectedCountryStates = async (e) => {
    try {
      const response = await axios.post(GET_STATE_BY_COUNTRY_ID, {
        country_id: 5
      });
      console.log('response?.data ', response?.data)
      setCitiesList(response?.data?.data[0]?.cities)
      // setCountriesList(response.data)
    }
    catch (error) {
      console.log(error);
    }
  }

  const fetchCompanyEmails = async () => {
    try {
      const response = await axios.get(COMPANY_EMAILS_API);
      //   console.log("response?.data ", response?.data);
      setCompanyEmails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTagsData()
    fetchJobTypes()
    fetchCountriesData();
    fetchCompanyEmails();
    fetchSelectedCountryStates()
  }, []);

  useEffect(() => {
    if (phoneCode == "+1") {
      setPhoneLength(10);
    } else if (phoneCode == "+92") {
      setPhoneLength(10);
    } else if (phoneCode == "+389") {
      setPhoneLength(8);
    } else if (phoneCode == "+355") {
      setPhoneLength(8);
    } else if (phoneCode == "+387") {
      setPhoneLength(8);
    } else if (phoneCode == "+55") {
      setPhoneLength(11);
    }
  }, [phoneCode]);

  const validateIsAgree = () => {
    if (agree) {
      setAgreeError(false);
    } else {
      setAgreeError(true);
    }
  };
  const onlyLettersAndSpaces = (str) => {
    return /^[A-Za-z\s]*$/.test(str);
  };

  const onlyNumbers = (str) => {
    return regexNumber.test(str);
  };

  const validateFirstName = () => {
    if (firstName == "") {
      setFirstNameError("First Name can not be empty");
    } else if (!onlyLettersAndSpaces(firstName)) {
      setFirstNameError("Only Alphabets are Allowed");
    } else {
      setFirstNameError("");
    }
  };

  const validateLastName = () => {
    if (lastName == "") {
      setLastNameError("Last Name can not be empty");
    } else if (!onlyLettersAndSpaces(lastName)) {
      setLastNameError("Only Alphabets are Allowed");
    } else {
      setLastNameError("");
    }
  };

  const validateEmail = () => {
    const address = email.split("@").pop();
    if (email == "") {
      setUserNameError("Username can not be empty");
    } else if (!regex.test(email)) {
      setUserNameError("Username should be a valid email Address");
    } else if (companyEmails?.includes(address)) {
      setUserNameError("Username should be a valid Business Email Address")
    } else {
      setUserNameError("");
    }
  };

  const validatePassword = () => {
    if (password == "") {
      setPasswordError("Password can not be empty");
    } else if (!mediumRegex.test(password)) {
      // alert('in med')
      setPasswordError(
        "Please add uppercase, special characters to make Password Strong"
      );
    } else if (!strongRegex.test(password)) {
      setPasswordError(
        "Please add uppercase, special characters to make Password Strong"
      );
      // alert('in strong')
    } else {
      // alert('in else')
      setPasswordError("");
    }
  };

  const validateCountry = (e) => {
    if (e == "Country" || e == "") {
      setCountryError("Please select a valid Country");
    } else {
      setCountryError("");
    }
  };

  const validatePhoneNumber = () => {
    console.log(phone?.length);
    if (phone == "") {
      setPhoneError("Phone can not be empty");
    } else if (phone?.length < phoneLength) {
      setPhoneError(`Please enter a valid phone having ${phoneLength} numbers`);
    } else {
      setPhoneError("");
    }
  };

  const checkValidationOfAllFields = () => {
    validateFirstName();
    validateLastName();
    validateCountry(country);
    validatePassword();
    validateEmail();
    validatePhoneNumber();
    validateIsAgree();
  };

  useEffect(() => {
    if (country != "Country") {
      const filteredCountry = countriesList?.filter(
        (item) => item.id == country
      );
      console.log("filter ", filteredCountry);
      if (filteredCountry?.length > 0) {
        setPhoneCode(filteredCountry[0]?.phone_code);
      }
    } else {
      setPhoneCode("");
    }
  }, [country]);

  const handleFileInput = (e) => {
    let file = e.target.files[0];
    setUploadedResume(file);
    setImageData(URL.createObjectURL(file));
    setUploadedResumeError("");
  };

  const registerUser = async () => {
    checkValidationOfAllFields();
    if (
      firstNameError == "" &&
      lastNameError == "" &&
      userNameError == "" &&
      countryError == "" &&
      phoneError == "" &&
      // LinkedInError == "" &&
      phoneError == ""
    ) {
      setLoader(true);
      try {
        let post_data = {
          name: `${firstName} ${lastName}`,
          email: email,
          password: `A${password}`,
          first_name: firstName,
          last_name: lastName,
          country_id: country,
          mobile: `${phoneCode}${phone}`,
          is_term_accept: agree ? 1 : 0,
          user_type: 2, // 1 for candidate
        };

        console.log("post date", post_data);

        const response = await axios.post(
          REGISTER_URL,
          JSON.stringify(post_data),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("response", response);
        if (!response.data.error) {
          const userId = response?.data?.id;
          const obj = {
            userRole: "2",
            token: response.data.token,
            userId: userId,
          };
          localStorage.setItem("userInfo", JSON.stringify(obj));
          // navigate('/profile')
          setLoader(false);
          setStepCounter(2);
        }
      } catch (err) {
        console.log("error ", err.response.data.message);
        setApiResponseError(err.response.data.message);
        //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
      }
    } else {
      // alert("asdfasdfd");
    }

    setTimeout(() => {
      setApiResponseError("");
    }, 10000);
  };

  const validateWebsiteUrl = () => {
    const address = websiteUrl.split(".");
    const website = `${address[1]}.${address[2]}`
    // console.log('website ', website)
    if (websiteUrl == "") {
      setWebsiteUrlError("Website Url can not be empty");
    }
    else if (companyEmails?.includes(website)) {
      setWebsiteUrlError(`Website Url can't contain ${address} domain.`)
    }
    else {
      setWebsiteUrlError("");
    }
  };

  // const validateLInkedInURL = () => {
  //   if (linkedin == "" || !linkedin.includes("www.linkedin.com")) {
  //     setLinkedInError("Linkedin Url can not be empty");
  //   } else {
  //     setLinkedInError("");
  //   }
  // };

  const validateCompanyName = () => {
    if (companyName == "") {
      setCompanyNameError("Company Name can not be empty");
    } else {
      setCompanyNameError("");
    }
  };

  const validateCompanyInfo = () => {
    if (companyInfo == "") {
      setCompanyInfoError("Company Info can not be empty");
    } else {
      setCompanyInfoError("");
    }
  };

  const validateUploadedLogo = () => {
    if (!uploadedResume) {
      setUploadedResumeError("Company Logo is Required");
    } else {
      setUploadedResumeError("");
    }
  };

  const onEditorStateChange = (editorState) => {
    // console.log('editorState ', editorState)
    setJobRequirements(editorState)
  };

  const validateCompanyProfileInformation = () => {
    // validateLInkedInURL();
    validateCompanyName();
    validateWebsiteUrl();
    validateCompanyInfo();
    validateUploadedLogo()
  };

  const registerCompanyInformation = async () => {
    validateCompanyProfileInformation();
    const userData = localStorage.getItem("userInfo");
    const user = JSON.parse(userData);
    if (
      websiteUrlError == "" &&
      companyNameError == "" &&
      // LinkedInError == "" &&
      companyInfoError == ""
      // &&
      // uploadedResumeError == ""
    ) {
      setLoader(true);
      try {
        // const formData = new FormData();
        const file = uploadedResume;
        let post_data = {
          // 'logo': {
          //     "name": file?.name,
          //     "lastModified": file?.lastModified,
          //     "size": file?.size,
          //     "type": file?.type,
          //     "webkitRelativePath": file?.webkitRelativePath,
          //     "lastModifiedDate": file?.lastModifiedDate,
          // },
          website_url: websiteUrl,
          company_name: companyName,
          company_info: companyInfo,
          token: user?.token,
          linkedin_url: linkedin,
        };
        console.log("formData date", post_data);

        const response = await axios.post(
          REGISTER_EMPLOYER_STEP2_URL,
          JSON.stringify(post_data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: user?.token,
            },
          }
        );
        console.log("response", response);
        if (!response.data.error) {
          const formData = new FormData();
          // Update the formData obzject
          formData.append("logo", file);
          formData.append("company_id", response?.data?.data?.id);
          const responseOfImage = await axios.post(
            PROFILE_UPLOAD_URL,
            formData,
            {
              // headers: { "Content-Type": "application/json" },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user?.token}`,
              },
            }
          );
          console.log("responseOfImage", responseOfImage);
          if (!responseOfImage.data.error) {
            setLoader(false);
            setStepCounter(3);
          }
        }
      } catch (err) {
        console.log("error ", err?.response?.data?.message);
        setApiResponseError(err.response.data.message);
        //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
      }
    } else {
      // alert("asdfasdfd");
    }

    setTimeout(() => {
      setApiResponseError("");
    }, 10000);
  };

  const validateJobTitle = (e) => {
    // if (jobTitle == "") {
    //     setJobTitleError("Title can not be empty")
    // } else if (!onlyLettersAndSpaces(jobTitle)) {
    //     setJobTitleError("Only Alphabets are Allowed")
    // } else {
    //     setJobTitleError("")
    // }
    if (e == "Choose a Job Title" || e == "") {
      setJobTitleError("Please select a valid Job Title")
    } else {
      setJobTitleError("")
    }
  }

  // const validateSalary = () => {
  //   if (salary == "") {
  //     setSalaryError("Salary can not be empty");
  //   } else if (!onlyNumbers(salary)) {
  //     setSalaryError("Only Numbers are Allowed");
  //   } else {
  //     setSalaryError("");
  //   }
  // };

  const validateRequirements = () => {
    if (requirements == "") {
      setRequirementsError("Short Description is Required");
    } else {
      setRequirementsError("");
    }
  };

  const validateResponsibilities = () => {
    if (responsibilites == "") {
      setResponsibilitiesError("Responsibilities can not be empty");
    } else {
      setResponsibilitiesError("");
    }
  };

  const validateJobRequirements = () => {
    if (jobRequirements == "") {
      setJobRequirementsError("Job Requirements can not be empty");
    } else {
      setJobRequirementsError("");
    }
  };

  const validateNiceToHave = () => {
    if (niceToHave == "") {
      setNiceToHaveError("Nice to have can not be empty");
    } else {
      setNiceToHaveError("");
    }
  };

  const validateBenefits = () => {
    if (benefits == "") {
      setBenefitsError("Benefits can not be empty");
    } else {
      setBenefitsError("");
    }
  };

  const validateSkills = () => {
    if (tags.length == "") {
      setSkillError("Skills can not be empty");
    } else {
      setSkillError("");
    }
  };

  const validateJobType = (e) => {
    // console.log('event is ', e.target.value)
    if (e == "Job Type" || e == "") {
      setJobTypeError("Please select a valid Job Type");
    } else {
      setJobTypeError("");
    }
  };



  const validateCountryList = () => {
    console.log("countryList.length ", countryList.length);
    if (countryList.length == 0) {
      setCountryListError("Please select at least 1 country");
    } else {
      setCountryListError("");
    }
  };


  const validateCityList = () => {
    if (selectedCity?.length == 0) {
      setCityListError("Please select at least 1 Country")
    }
  }

  const handleCityySelection = (cityId) => {
    let cArray = [...selectedCity]
    console.log('cArray?.length ', cArray?.length)

    const filteredCArray = cArray?.filter((item) => item == cityId)
    if (filteredCArray?.length > 0) {
      const index = cArray?.indexOf(filteredCArray[0]);
      if (index > -1) { // only splice array when item is found
        cArray?.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else if (cArray?.length < 3) {
      cArray?.push(cityId)
    } else if (cArray?.length == 3) {
      setCityListError("")
    } else if (cArray?.length == 0) {
      setCityListError("Please select at least 1 city")
    } else {
      setCityListError("You can maximum select 3 cities")
    }
    // if (cArray?.length > 0) {
    //     setCityListError("")
    // } else {
    //     setCityListError("Please select at least one city")
    // }
    setSelectedCity(cArray)
  }

  const handleCountrySelection = (countryId) => {
    let cArray = [...countryList];
    const filteredCArray = cArray?.filter((item) => item == countryId);
    if (filteredCArray.length > 0) {
      const index = cArray.indexOf(filteredCArray[0]);
      if (index > -1) {
        // only splice array when item is found
        cArray.splice(index, 1); // 2nd parameter means remove one item only
      }
    } else {
      cArray.push(countryId);
    }
    console.log("cArray.length ", cArray.length);
    if (cArray.length > 0) {
      setCountryListError("");
    } else {
      setCountryListError("Please select at least one country");
    }
    setCountryList(cArray);
  };


  const validateJobPostingInformation = () => {
    validateJobTitle()
    // validateSalary()
    validateRequirements()
    validateJobType(jobType)
    validateSkills()
    // validateResponsibilities()
    // validateNiceToHave()
    // validateJobRequirements()
    // validateBenefits()
    // validateCountryList()
    validateCityList()
  }

  const postJob = async () => {
    const userData = localStorage.getItem("userInfo");
    const user = JSON.parse(userData);
    if (
      jobTitleError == "" &&
      jobTitle != "" &&
      requirementError == "" &&
      requirements != "" &&
      jobTypeError == "" &&
      jobType != "" &&
      tags.length > 0 &&
      // responsibilitiesError == "" &&
      // responsibilites != "" &&
      // jobRequirementsError == "" &&
      // jobRequirements != "" &&
      // niceToHaveError == "" &&
      // niceToHave != "" &&
      // benefitsError == "" &&
      // benefits != "" &&
      // countryListError == "" &&
      // countryList.length > 0
      selectedCity?.length > 0 && cityListError == ""
    ) {
      setLoader(true);
      try {

        const techTagsArr = []
        const tech_tagsClone = tags

        for (let i = 0; i < tech_tagsClone.length; i++) {
          // techTagsArr.push(tech_tagsClone[i].id)
          techTagsArr.push(parseInt(tech_tagsClone[i].id))
        }


        let post_data = {
          title: jobTitle,
          "description": requirements, // SHORT DESCRIPTION
          salary: salary,
          job_type: jobType,
          // skills: skills, // array
          skills: techTagsArr,
          roles_and_responsibilities: responsibilites,
          "job_requirements": JSON.stringify(jobRequirements), // LONG DESCRIPTION
          nice_to_have: niceToHave,
          benifits: benefits,
          // countries: countryList, // country ids array
          countries: [5], // country ids array
          "cities": selectedCity,
          token: user?.token,
        };

        console.log("post date", post_data);

        const response = await axios.post(
          REGISTER_EMPLOYER_STEP3_URL,
          JSON.stringify(post_data),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("response", response);
        if (!response.data.error) {
          navigate("/employer-dashboard");
        }
      } catch (err) {
        console.log("error ", err.response.data.message);
        setApiResponseError(err.response.data.message);
        //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
      }
    }

    setTimeout(() => {
      setLoader(false);
      setApiResponseError("");
    }, 10000);
  };

  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  // const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const delimiters = [KeyCodes.comma];


  const handleTechTagsDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleTechTagsAddition = tag => {
    if (tags?.length < 5) {
      setTags([...tags, tag]);
      setSkillError("")
    } else {
      setSkillError("Technical Skills are limited to 5")
    }
  };

  const suggestionsTechTags = tagsList?.map(item => {
    return {
      id: `${item?.id}`,
      className: "tag-errors",
      text: item?.tag
    };
  });

  return (
    <div className="xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10">
      <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
        <div className="flex relative steps-row justify-between w-full align-middle  mb-10 xl:items-center lg:items-center md:items-center overflow-hidden sm:items-center items-start">
          <div
            disabled={stepCounter > 1}
            className="flex cursor-pointer align-middle first justify-start items-center flex-col "
          >
            <span className="text-2xl bg-white secondary-heading-color secondary-border-color font-family-700 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full">
              1
            </span>
            <span className="text-xl font-family-700 font-medium text-center secondary-heading-color">
              Sign Up
            </span>
          </div>
          <div
            // onClick={() => {
            //     setStepCounter(2)
            // }}
            className={`cursor-pointer ${stepCounter == 2
              ? "step-two"
              : stepCounter == 3
                ? "step-three"
                : ""
              } flex align-middle second justify-center items-center flex-col before-after relative`}
          >
            <span
              className={`  text-2xl bg-white secondary-heading-color ${stepCounter == 1
                ? "steps-main-border-color"
                : "secondary-border-color"
                }  font-family-700 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full`}
            >
              2
            </span>
            <span className="text-xl font-family-700 font-medium text-center secondary-heading-color">
              Create Profile
            </span>
          </div>
          <div
            // onClick={() => {
            //     setStepCounter(3)
            // }}
            className="flex  cursor-pointer align-middle third justify-end items-center flex-col"
          >
            <span
              className={` text-2xl bg-white secondary-heading-color ${stepCounter == 1 || stepCounter == 2
                ? "steps-main-border-color"
                : "secondary-border-color"
                } font-family-700 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full`}
            >
              3
            </span>
            <span className="text-xl font-family-700 font-medium text-center secondary-heading-color">
              Post Job Globally
            </span>
          </div>
        </div>
      </div>
      {stepCounter == 1 ? (
        <>
          <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
            <h2 className="font-family-700 font-medium text-center primary-heading-color mb-9">
              Create Employer Account
            </h2>
            <div className="flex mb-6 align-middle justify-between  w-full gap-6">
              <div className='w-full'>
                <div className="flex relative flex-col w-full">
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${firstNameError != ""
                      ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                      : "border-gray-300 secondary-heading-color  focus:border-gray-300"
                      } bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    id="firstname"
                    onBlur={validateFirstName}
                    value={firstName}
                    placeholder={
                      "First Name"
                    }
                  />
                  {firstNameError ? (
                    <svg
                      className="absolute top-1/2"
                      style={{ right: "5%", transform: "translate(0px, -50%)" }}
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                        fill="#EF4444"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                {
                  firstNameError ?
                    <>
                      <span className={`text-red-600 block ${firstNameError == "" ? "" : "pt-3"} font-family-500 font-medium text-sm`}>
                        {
                          firstNameError
                        }
                      </span>
                    </>
                    : ""
                }
              </div>
              <div className='w-full'>
                <div className="flex relative flex-col w-full">
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${lastNameError != ""
                      ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                      : "border-gray-300 secondary-heading-color  focus:border-gray-300"
                      } bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    onBlur={validateLastName}
                    id="lastname"
                    value={lastName}
                    placeholder={
                      "Last Name"
                    }
                  />
                  {lastNameError ? (
                    <svg
                      className="absolute top-1/2"
                      style={{ right: "5%", transform: "translate(0px, -50%)" }}
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                        fill="#EF4444"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                {
                  lastNameError ?
                    <>
                      <span className={`text-red-600 ${lastNameError == "" ? "" : "pt-3"} block font-family-500 font-medium text-sm`}>
                        {
                          lastNameError
                        }
                      </span>
                    </>
                    : ""
                }
              </div>
            </div>
            <div className="flex  flex-col mb-6 align-middle justify-between items-center w-full gap-0">
              <div className='relative w-full'>
                <input
                  type="email"
                  autoComplete="off"
                  className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${userNameError != ""
                    ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300"
                    } bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={validateEmail}
                  value={email}
                  id="email"
                  placeholder={"User Name"}
                />
                {userNameError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                userNameError ?
                  <>
                    <span className={`text-red-600 ${userNameError == "" ? "" : "pt-3"} block w-full font-family-500 font-medium text-sm`}>
                      {
                        userNameError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex flex-col xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10 align-middle justify-between items-center w-full gap-0">
              <div className="w-full relative">
                <input
                  type="password"
                  autoComplete="off"
                  className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${passwordError != ""
                    ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300"
                    } bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onBlur={validatePassword}
                  value={password}
                  id="password"
                  placeholder={"Password"}
                />
                {passwordError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                passwordError != '' ?
                  <>
                    <span className={`${passwordError == "" ? "secondary-heading-color" : " text-red-600 "} pt-3 w-full block font-family-500 font-medium text-sm`}>
                      <ul className={`list-disc pl-5 ${passwordError == "" ? "secondary-heading-color" : " text-red-600 py-1"}  `}>
                        <li>Password must be at least 8 characters in length</li>
                        <li>Use a minimum of 1 numeric value</li>
                        <li>Use at least  1 Upper Case Letter (ABCD)</li>
                        <li>
                          Use at least 1 Lower Case Letter (abcd)
                        </li>
                        <li>
                          User at least one special character ( !# % $ * )
                        </li>
                      </ul>
                    </span>
                  </>
                  :
                  null
              }
            </div>

            <div className="flex flex-col mb-6 align-middle justify-between items-center w-full gap-0 ">
              <div className='relative w-full'>
                <select
                  id="countries"
                  onChange={(e) => {
                    // validateCountry(e)
                    if (e.target.value != "Country") {
                      setCountry(e.target.value);
                      setCountryError("");
                    } else {
                      setCountryError("Please select a valid Contry");
                    }
                  }}
                  className={`bg-white border font-family-500 font-normal ${countryError != ""
                    ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                    : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "
                    } appearance-none outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                >
                  <option className="secondary-heading-color" selected>
                    Country
                  </option>
                  {countriesList?.map((item, index) => {
                    return (
                      <option
                        className="secondary-heading-color"
                        value={item.id}
                        key={index}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <svg
                  className="absolute top-1/2 right-5 "
                  style={{ transform: "translate(0%,-50%)" }}
                  width="21"
                  height="12"
                  viewBox="0 0 21 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z"
                    fill="#4F4F4F"
                  />
                </svg>
                {countryError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "10%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                countryError ?
                  <>
                    <span className={`text-red-600 ${countryError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                      {
                        countryError
                      }
                    </span>
                  </>
                  :
                  ""
              }
            </div>
            <div className='w-full mb-6'>
              <div
                className={`flex  align-middle justify-between items-center w-full gap-0 relative border ${phoneError != ""
                  ? "border-red-500 text-red-500"
                  : "border-gray-300"
                  } border-solid rounded-full transition ease-in-out`}
              >
                <select
                  onChange={(e) => {
                    setPhoneCode(e.target.value);
                  }}
                  defaultValue={phoneCode}
                  value={phoneCode}
                  id="mobileCode"
                  className={`xl:w-3/12 form-control font-family-500 font-normal ${phoneError != ""
                    ? "focus:ring-red-500 active:ring-red-500 focus-within:ring-red-500 focus-within:border-red-500  active:border-red-500 focus-visible:ring-red-500 focus-visible:border-red-500 focus:border-red-500 text-red-500"
                    : "focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color"
                    }  lg:w-3/12 md:w-5/12 sm:w-5/12 w-5/12 bg-white appearance-none outline-none rounded-full border-none  block pl-4 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl `}
                >
                  <option className="secondary-heading-color" selected>
                    code
                  </option>
                  {countriesList?.map((item, index) => {
                    return (
                      <option
                        className="secondary-heading-color"
                        value={item.phone_code}
                        key={index}
                      >
                        {item.phone_code}
                      </option>
                    );
                  })}
                </select>
                <input
                  maxLength={phoneLength}
                  minLength={phoneLength}
                  type="text"
                  autoComplete="off"
                  className={`form-control font-family-500 font-normal block w-full pr-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${phoneError != ""
                    ? "focus:border-red-500 placeholder:text-red-500 text-red-500"
                    : "secondary-heading-color focus:border-gray-300"
                    } bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  onBlur={validatePhoneNumber}
                  id="mobile"
                  placeholder={"Mobile"}
                />
                {phoneError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                phoneError ?
                  <>
                    <span className={`text-red-600 ${phoneError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                      {
                        phoneError
                      }
                    </span>
                  </>
                  :
                  ""
              }
            </div>

            <div
              className={`flex align-middle w-full relative ${agreeError
                ? "xl:mb-5 lg:mb-5 md:mb-10 sm:mb-5 mb-5"
                : "xl:mb-5 lg:mb-5 md:mb-10 sm:mb-5 mb-5"
                } items-center `}
            >
              <label className="relative flex align-middle justify-center items-center custom-checkbox">
                <input
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAgreeError(false);
                    } else {
                      setAgreeError(true);
                    }
                    setAgree(e.target.checked);
                  }}
                  value={agree}
                  type="checkbox"
                // I
                // value={rememberMe}
                />
                <span
                  className={`checkmark ${agreeError != "" ? "border-red-500" : "border-gray-300"
                    }`}
                ></span>
                <span
                  className={`form-check-label ${agreeError == "" ? "secondary-heading-color" : "text-red-500"} xl:leading-none lg:leading-none md:leading-none sm:leading-none leading-tight pl-9 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 font-medium inline-block `}
                  htmlFor="rememberMe">
                  Yes, I understand and Agree to the Terms of Service
                </span>
              </label>
              {agreeError ? (
                <svg
                  className="absolute top-1/2"
                  style={{ right: "5%", transform: "translate(0px, -50%)" }}
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                    fill="#EF4444"
                  />
                </svg>
              ) : (
                ""
              )}
            </div>

            <button
              onClick={registerUser}
              disabled={
                firstNameError ||
                lastNameError ||
                userNameError ||
                passwordError ||
                countryError ||
                phoneError ||
                agreeError
              }
              className="primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center"
            >
              Create
              {loader ? <BiLoaderAlt className="loader-spinner ml-2" /> : null}
            </button>
            {apiResponseError ? (
              <span className="text-red-600 text-sm font-family-500 font-medium py-3 block w-full">
                {apiResponseError}
              </span>
            ) : (
              ""
            )}
            <a className="secondary-heading-color w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl">
              Already have account?
              <span
                onClick={() => {
                  navigate("/employer-login");
                }}
                className="call-to-action-text-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2"
              >
                Log in
              </span>
            </a>
          </div>
          <div className="hr-bottom xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
            <hr className=" bg-white border-t-2 border-gray-300 my-0 mx-auto w-full" />
          </div>
        </>
      ) : stepCounter == 2 ? (
        <>
          <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
            <h2 className="font-family-700 font-medium text-center primary-heading-color mb-9">
              Create Company Profile
            </h2>
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
              <div className="w-full relative">
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${websiteUrlError != ""
                    ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300"
                    } bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setWebsiteUrl(e.target.value);
                  }}
                  onBlur={validateWebsiteUrl}
                  value={websiteUrl}
                  id="websiteUrl"
                  placeholder={
                    "Website URL"
                  }
                />
                {websiteUrlError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>

              {
                websiteUrlError ?
                  <>
                    <span className={`text-red-600 block ${websiteUrlError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        websiteUrlError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
              <div className="w-full relative">
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${companyNameError != ""
                    ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300"
                    } bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  id="companyName"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  onBlur={validateCompanyName}
                  value={companyName}
                  placeholder={
                    "Company Name"
                  }
                />
                {companyNameError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                companyNameError ?
                  <>
                    <span className={`text-red-600 block ${companyNameError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        companyNameError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
              <div className="relative w-full">
                <textarea
                  onChange={(e) => {
                    setCompanyInfo(e.target.value);
                  }}
                  onBlur={validateCompanyInfo}
                  value={companyInfo}
                  id="companyInformation"
                  className={`form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border border-solid ${companyInfoError != ""
                    ? "focus:border-red-500 placeholder:text-red-500 border-red-500"
                    : "focus:border-gray-300 secondary-heading-color border-gray-300"
                    } rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                  placeholder={
                    "Company Information"
                  }
                ></textarea>
                {companyInfoError ? (
                  <svg
                    className="absolute top-5"
                    style={{ right: "5%" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                companyInfoError ?
                  <>
                    <span className={`text-red-600 block ${companyInfoError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        companyInfoError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
              <div className="relative w-full">
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control font-family-500 font-normal block w-full px-8 py-2 secondary-heading-color xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${LinkedInError != ""
                    ? "border-red-500 focus:border-red-500 placeholder:text-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300 "
                    } bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setLinkedin(e.target.value);
                  }}
                  // onBlur={validateLInkedInURL}
                  value={linkedin}
                  id="linkedInLink"
                  placeholder={
                    "LinkedIn Link"
                  }
                />
                {LinkedInError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                LinkedInError ?
                  <>
                    <span className={`text-red-600 block ${LinkedInError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        LinkedInError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex flex-col mb-12 align-middle justify-between items-center w-full gap-6 relative transition ease-in-out">
              {/* <div className="flex absolute top-0 left-0 w-full h-full align-middle justify-start px-8 py-2 items-center"> */}
              <div className="flex w-full h-full align-middle gap-6 items-center">
                {imageData != "" ? (
                  <span
                    className="h-20 uploader-bg-color w-20 border border-gray-400 rounded-lg  object-cover block"
                    style={{ minWidth: "80px" }}
                  >
                    <img
                      src={imageData}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </span>
                ) : (
                  <span
                    className="uploader-bg-color flex align-middle justify-center items-center h-20 rounded-lg"
                    style={{ minWidth: "20px", padding: "0px 31.46px" }}
                  >
                    <RxUpload
                      className=" text-xl secondary-secondary-heading-color font-medium font-family-500"
                      width="20px"
                      height="20px"
                      style={{ color: "#4F4F4F" }}
                    />
                  </span>
                )}
                <span className="form-control cursor-pointer font-family-500 font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                  <span className="form-control pl-24 flex align-middle justify-start items-center absolute top-0 left-0 h-full  cursor-pointer font-family-500 font-normal w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    Upload Logo
                  </span>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileInput(e);
                    }}
                    autoComplete="off"
                    className="form-control cursor-pointer absolute top-0 left-0 h-full  opacity-0 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                    // onChange={(e) => {
                    //     setUsername(e.target.value)
                    // }}
                    id="resume"
                    // value={username}
                    placeholder="Upload Resume"
                  />
                </span>
              </div>
              {uploadedResumeError ? (
                <span className="text-red-600 text-sm font-family-500 font-medium pt-3 block w-full">
                  {uploadedResumeError}
                </span>
              ) : (
                ""
              )}
            </div>
            <button
              disabled={
                websiteUrlError ||
                // LinkedInError ||
                companyInfoError ||
                companyInfoError ||
                uploadedResumeError
              }
              onClick={registerCompanyInformation}
              className="primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center"
            >
              Create
            </button>
            {apiResponseError ? (
              <span className="text-red-600 text-sm font-family-500 font-medium py-3 block w-full">
                {apiResponseError}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="hr-bottom xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
            <hr className=" bg-white border-t-2 border-gray-300 my-0 mx-auto w-full" />
          </div>
        </>
      ) : (
        <>
          <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
            <h2 className="font-family-700 font-medium text-center primary-heading-color mb-9">
              Post Your Job
            </h2>
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
              <div className="w-full relative">
                <select
                  value={jobTitle}
                  onChange={(e) => {
                    // validateCountry(e)
                    if (e.target.value != "Choose a Job Title") {
                      setJobTitle(e.target.value)
                      setJobTitleError("")
                    } else {
                      setJobTitleError(e.target.value)
                      setJobTitleError("Please select a valid Job Title")
                    }
                  }}
                  onBlur={(e) => { validateJobTitle(jobTitle) }}
                  id="jobType"
                  className={`bg-white border font-family-500 font-normal ${jobTitleError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                >
                  <option
                    className="secondary-heading-color"
                    selected
                    value="Choose a Job Title"
                  >
                    Choose a Job Title
                  </option>
                  {
                    tagsList?.map((item, index) => {
                      return (
                        <option className="secondary-heading-color" key={item?.id} value={item?.tag}>
                          {item?.tag}
                        </option>
                      )
                    })
                  }
                </select>
                {/* <input
                  type="text"
                  autoComplete="off"
                  className={`form-control secondary-heading-color font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${jobTitleError != ""
                    ? "border-red-500 focus:border-red-500 placeholder:text-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300 "
                    } bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                    setJobTypeError("");
                  }}
                  value={jobTitle}
                  onBlur={validateJobTitle}
                  id="jobTitle"
                  placeholder={
                    "Choose a Job Title"
                  }
                /> */}
                {jobTitleError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                jobTitleError ?
                  <>
                    <span className={`text-red-600 block ${jobTitleError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        jobTitleError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
              <div className="w-full relative">
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control secondary-heading-color font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${salaryError != ""
                    ? "border-red-500 focus:border-red-500 placeholder:text-red-500"
                    : "border-gray-300 secondary-heading-color  focus:border-gray-300 "
                    } bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                  onChange={(e) => {
                    setSalary(e.target.value);
                    setSalaryError("");
                  }}
                  value={salary}
                  // onBlur={validateSalary}
                  id="Salary"
                  placeholder={"Salary"}
                />
                {salaryError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "5%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                salaryError ?
                  <>
                    <span className={`text-red-600 block ${salaryError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        salaryError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex mb-6 align-middle justify-between items-center  flex-col w-full relative">
              <div className="w-full relative">
                <select
                  value={jobType}
                  onChange={(e) => {
                    // validateCountry(e)
                    if (e.target.value != "Job Type") {
                      setJobType(e.target.value);
                      setJobTypeError("");
                    } else {
                      setJobType(e.target.value);
                      setJobTypeError("Please select a valid Job Type");
                    }
                  }}
                  onBlur={(e) => {
                    validateJobType(jobType);
                  }}
                  id="jobType"
                  className={`bg-white border font-family-500 font-normal ${jobTypeError != ""
                    ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500"
                    : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "
                    } appearance-none outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                >
                  <option
                    className="secondary-heading-color"
                    selected
                    value="Job Type"
                  >
                    Job Type
                  </option>
                  {
                    jobTypeList?.map((item, index) => {
                      return (
                        <option className="secondary-heading-color" key={item?.id} value={item?.title}>
                          {item?.title}
                        </option>
                      )
                    })
                  }
                </select>
                <svg
                  className="absolute top-1/2 right-5 "
                  style={{ transform: "translate(0%,-50%)" }}
                  width="21"
                  height="12"
                  viewBox="0 0 21 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z"
                    fill={`${jobTypeError == "" ? "#4F4F4F" : "#dc2626"}`}
                  />
                </svg>
                {jobTypeError ? (
                  <svg
                    className="absolute top-1/2"
                    style={{ right: "10%", transform: "translate(0px, -50%)" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                jobTypeError ?
                  <>
                    <span className={`text-red-600 block ${jobTypeError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        jobTypeError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>


            <div className={`flex flex-col ${skillError == "" ? "mb-10" : " error-borders rounded-full  mb-6"} align-middle justify-between items-center w-full  relative transition ease-in-out`}>
              <div className='relative w-full'>
                <ReactTags
                  tags={tags}
                  suggestions={suggestionsTechTags}
                  delimiters={delimiters}
                  placeholder="My Technical Skills"
                  handleDelete={handleTechTagsDelete}
                  handleAddition={handleTechTagsAddition}
                  inputFieldPosition="bottom"
                  allowDragDrop={false}
                  autocomplete
                />
                {
                  skillError ?
                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                    </svg>
                    : ""
                }
              </div>
              {
                skillError ?
                  <>
                    <span className={`text-red-600 ${skillError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                      {
                        skillError
                      }
                    </span>
                  </>
                  :
                  ""
              }
            </div>
            {/* <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full gap-6">
              <input
                type="text"
                autoComplete="off"
                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${skillError != ""
                  ? "border-red-500 focus:border-red-500 placeholder:text-red-500"
                  : "border-gray-300 secondary-heading-color  focus:border-gray-300 "
                  } bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                onChange={(e) => {
                  setSkills(e.target.value);
                  setSkillError("");
                }}
                onBlur={validateSkills}
                value={skills}
                id="Skills"
                placeholder={skillError != "" ? skillError : "Technical Skills"}
              />
              {skillError ? (
                <svg
                  className="absolute top-1/2"
                  style={{ right: "5%", transform: "translate(0px, -50%)" }}
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                    fill="#EF4444"
                  />
                </svg>
              ) : (
                ""
              )}
            </div> */}


            {/* <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
              <div className="relative w-full">
                <textarea
                  value={responsibilites}
                  onChange={(e) => {
                    setResponsibilities(e.target.value);
                    setResponsibilitiesError("");
                  }}
                  onBlur={validateResponsibilities}
                  id="responsibilites"
                  className={`form-control ${responsibilitiesError != ""
                    ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500"
                    : "secondary-heading-color border-gray-300 focus:border-gray-300"
                    } h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                  placeholder={
                    "Roles and Responsibilities"
                  }
                ></textarea>
                {responsibilitiesError ? (
                  <svg
                    className="absolute top-5"
                    style={{ right: "5%" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                responsibilitiesError ?
                  <>
                    <span className={`text-red-600 block ${responsibilitiesError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        responsibilitiesError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative mb-6 align-middle justify-between items-center w-full flex-col">
              <div className="w-full relative">
                <textarea
                  onBlur={validateJobRequirements}
                  value={jobRequirements}
                  onChange={(e) => {
                    setJobRequirements(e.target.value);
                    setJobRequirementsError("");
                  }}
                  id="jobRequirements"
                  className={`form-control ${jobRequirementsError != ""
                    ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500"
                    : "secondary-heading-color border-gray-300 focus:border-gray-300"
                    } h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                  placeholder={
                    "Job Requirements"
                  }
                ></textarea>
                {jobRequirementsError ? (
                  <svg
                    className="absolute top-5"
                    style={{ right: "5%" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                jobRequirementsError ?
                  <>
                    <span className={`text-red-600 block ${jobRequirementsError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        jobRequirementsError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative mb-6 align-middle justify-between items-center w-full flex-col">
              <div className="relative w-full">
                <textarea
                  onBlur={validateNiceToHave}
                  value={niceToHave}
                  onChange={(e) => {
                    setNiceToHave(e.target.value);
                    setNiceToHaveError("");
                  }}
                  id="niceToHave"
                  className={`form-control ${niceToHaveError != ""
                    ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500"
                    : "secondary-heading-color border-gray-300 focus:border-gray-300"
                    } h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                  placeholder={
                    "Nice to Have"
                  }
                ></textarea>
                {niceToHaveError ? (
                  <svg
                    className="absolute top-5"
                    style={{ right: "5%" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                niceToHaveError ?
                  <>
                    <span className={`text-red-600 block ${niceToHaveError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        niceToHaveError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <div className="flex relative mb-6 align-middle justify-between items-center w-full flex-col">
              <div className="w-full relative">
                <textarea
                  onBlur={validateBenefits}
                  value={benefits}
                  onChange={(e) => {
                    setBenefits(e.target.value);
                    setBenefitsError("");
                  }}
                  id="benefits"
                  className={`form-control ${benefitsError != ""
                    ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500"
                    : "secondary-heading-color border-gray-300 focus:border-gray-300"
                    } h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                  placeholder={"Benefits"}
                ></textarea>
                {benefitsError ? (
                  <svg
                    className="absolute top-5"
                    style={{ right: "5%" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                benefitsError ?
                  <>
                    <span className={`text-red-600 block ${benefitsError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        benefitsError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
             */}
            <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
              <div className="relative w-full">
                <textarea
                  value={requirements}
                  onChange={(e) => {
                    setRequirements(e.target.value);
                    setRequirementsError("");
                  }}
                  onBlur={validateRequirements}
                  id="requirements"
                  placeholder={
                    "Short Description"
                  }
                  className={`form-control ${requirementError != ""
                    ? "text-red-500 placeholder:text-red-500 border-red-500 focus:border-red-500"
                    : "secondary-heading-color border-gray-300 focus:border-gray-300"
                    } h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding border border-solid  rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                ></textarea>
                {requirementError ? (
                  <svg
                    className="absolute top-5"
                    style={{ right: "5%" }}
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                      fill="#EF4444"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </div>
              {
                requirementError ?
                  <>
                    <span className={`text-red-600 block ${requirementError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                      {
                        requirementError
                      }
                    </span>
                  </>
                  : ""
              }
            </div>
            <span className='font-family-500 font-normal block w-full mb-4 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color'>
              Detailed Job Description
            </span>
            <Editor
              // editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />
            <div className="flex relative mb-8 mt-8 align-middle justify-between items-center w-full gap-6">
              <span className={`font-family-500 font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${countryListError || cityListError != "" ? "text-red-500" : "secondary-heading-color"} `}>
                {/* Select a Country/Countries to post a Job: */}
                Select a City/Cities to post a Job:
              </span>
              {
                countryListError ?
                  <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                  </svg>
                  :
                  cityListError ?
                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                    </svg>
                    :

                    ""
              }
            </div>
          </div>

          <div className='flex align-middle justify-center mb-8 gap-10 signup-w-3-12-5 mx-auto'>
            <div>
              {
                countriesList?.map((item, index) => {
                  const filterCountry = countryList?.filter((countryId) =>
                    countryId == item.id
                  )
                  if (item.name == "North Macedonia") {
                    return (
                      <div
                        // onClick={(e) => {
                        //     handleCountrySelection(item.id)
                        // }}
                        key={index}
                        className={`flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ${filterCountry.length > 0 ? "bg-gray-200 rounded-md py-1" : ""} `}>
                        <img src={
                          MacedoniaLogo
                        } alt="" className='mb-6 xl:h-20 lg:h-20 h-auto ' />
                        <p className="text-center text-lg font-normal secondary-heading-color font-family-500">
                          {item?.name}
                        </p>
                      </div>
                    )
                  }
                })
              }
            </div>
            <ul className='flex align-middle items-center gap-5'>
              {
                citiesList?.map((item) => {
                  const filterCountry = selectedCity?.filter((cityId) =>
                    cityId == item.id
                  )
                  return (
                    <li
                      onClick={(e) => {
                        handleCityySelection(item?.id)
                      }}
                      className="flex cursor-pointer align-middle justify-start items-center h-max gap-3" key={item?.id} value={item?.id}>
                      <span className={`w-4 h-4 border-2 ${filterCountry?.length > 0 ? "border-gray-300 bg-gray-300" : "border-gray-300 "}  rounded-full`}>

                      </span>
                      <span className='secondary-heading-color font-family-500 text-base'>
                        {item?.city}
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>

          {/* <div className="xl:grid lg:grid md:grid mb-14 xl:w-3/5 lg:w-4/5 w-full mx-auto sm:grid flex flex-wrap xl:grid-cols-7 lg:grid-cols-7 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 gap-6 md:grid-cols-5 sm:grid-cols-3 align-middle justify-center items-center">
            {countriesList?.map((item, index) => {
              const filterCountry = countryList?.filter(
                (countryId) => countryId == item.id
              );
              return (
                <div
                  onClick={(e) => {
                    handleCitySelection(item.id);
                  }}
                  key={index}
                  className={`flex xl:w-auto lg:w-auto md:w-auto sm:w-auto w-5/12 align-middle justify-center cursor-pointer flex-col items-center ${filterCountry.length > 0
                    ? "bg-gray-200 rounded-md py-1"
                    : ""
                    } `}
                >
                  <img
                    src={
                      item.name == "Canada"
                        ? CanadaLogo
                        : item.name == "Pakistan"
                          ? PakistanLogo
                          : item.name == "United States"
                            ? USALogo
                            : item.name == "Macedonia"
                              ? MacedoniaLogo
                              : item.name == "Albania"
                                ? AlbaniaLogo
                                : item.name == "Bosnia"
                                  ? BosniaLogo
                                  : BrazilLogo
                    }
                    alt=""
                    className="mb-6 xl:h-20 lg:h-20 h-auto "
                  />
                  <p className="text-center text-lg font-normal secondary-heading-color font-family-500">
                    {item?.name}
                  </p>
                </div>
              );
            })}
          </div> */}
          {/* <div className="flex align-middle justify-between"></div> */}

          <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
            <button
              disabled={jobTitleError || cityListError || requirementError || jobTypeError || skillError
                // || responsibilitiesError || jobRequirementsError || niceToHaveError || benefitsError
              }
              onClick={() => {
                jobTitleError == "" && jobTitle != "" &&
                  requirementError == "" && requirements != "" &&
                  jobTypeError == "" && jobType != "" &&
                  skillError == "" && tags.length > 0 &&
                  cityListError == "" && selectedCity?.length > 0
                  // responsibilitiesError == "" && responsibilites != "" &&
                  // jobRequirementsError == "" && jobRequirements != "" &&
                  // niceToHaveError == "" && niceToHave != "" &&
                  // benefitsError == "" && benefits != "" &&
                  // countryListError == "" && countryList.length > 0
                  ?
                  postJob()
                  :
                  validateJobPostingInformation()
              }}
              className='primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
              Post Your Job
              {loader ? <BiLoaderAlt className="loader-spinner ml-2" /> : null}
            </button>
            <a onClick={() => {
              navigate('/employer-dashboard')
            }} className="cursor-pointer text-center block text-base font-family-500 font-medium call-to-action-text-color" >
              Skip
            </a>
          </div>
          <div className=" xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-3/4 lg:w-4/5 w-full mx-auto">
            <hr className=" bg-white border-t-2 border-gray-300 my-0 mx-auto w-full" />
          </div>
        </>
      )}
      {/* </div> */}
    </div>
  );
};

export default EmployeeSignupForm;
