import React, { Component, useEffect, useState } from 'react'
import axios from '../../api/axios'
import CallToActionBanner from '../../components/CallToActionBanner'
import RecommendedJobs from '../../components/RecommendedJobs'
import CompanyInformationBanner from '../../components/CompanyInformationBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useParams } from 'react-router-dom';

const CompanyInformation = () => {

    const FETCH_COMPANY_INFORMATION = 'company'
    const GET_JOB_TYPES = "/jobtypes";
    const GET_JOB_STATUS = "/status_by_type"
    const CART_API_URL = '/carts'
    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)

    const [jobList, setJobList] = useState([])
    const [companyInformation, setCompanyInformation] = useState([])
    const [jobTypeList, setJobTypeList] = useState([])
    const [jobStatusList, setJobStatusList] = useState([])
    const { company_id } = useParams();

    const [cartJobs, setCartJobs] = useState([])
    const [reloadPage, setReloadPage] = useState(false)

    const fetchJobTypes = async () => {
        try {
            const response = await axios.get(GET_JOB_TYPES);
            setJobTypeList(response?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchJobStatus = async () => {
        try {
            const response = await axios.get(GET_JOB_STATUS, {
                params: {
                    type: "job_ad",
                    token: userInfo?.token,
                }
            });
            setJobStatusList(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchCompanyInformation = async () => {
        try {
            const response = await axios.post(FETCH_COMPANY_INFORMATION,
                {
                    token: userInfo?.token,
                    company_id: company_id
                },
            );
            setCompanyInformation(response?.data?.company)
            setJobList(response?.data?.jobs)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchJobTypes()
        fetchJobStatus()
        fetchCompanyInformation()
    }, [])

    const refetchJobsForHeader = (props) => {
        console.log('props ', props)
        setReloadPage(props)
    }

    const fetchCartJobs = async () => {
        try {
            const response = await axios.get(CART_API_URL, {
                params: {
                    token: userInfo?.token
                }
            });
            setCartJobs(response.data)
        }
        catch (error) {
            console.log(error);
        }
        setReloadPage(false)
    }

    useEffect(() => {
        console.log('reload Page ', reloadPage)
        if (reloadPage) {
            fetchCartJobs()
        }
    }, [reloadPage])

    return (
        <>
            <Header countFromParent={cartJobs?.length} bgColor={true} />
            <CompanyInformationBanner companyInformation={companyInformation} />
            <div className='main-container  '>
                <div className='xl:mb-9 lg:mb-9 md:mb-10 sm:mb-10 mb-10 xl:gap-5 lg:gap-5 md:gap-5 sm:gap-3 gap-3 flex align-middle justify-end items-center'>
                    <div className='relative xl:w-max lg:w-max md:w-max sm:w-full w-full'>
                        <select id="applicationCount"
                            className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none xl:rounded-full lg:rounded-full md:rounded-full sm:rounded-md rounded-md focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-14 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                            <option selected>Categories</option>
                            {
                                jobStatusList?.map((item, index) => {
                                    return (
                                        <option className="secondary-heading-color" key={item?.id} value={item?.status}>
                                            {item?.status}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                        </svg>
                    </div>
                    <div className='relative xl:w-max lg:w-max md:w-max sm:w-full w-full'>
                        <select id="applicationCount"
                            className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none xl:rounded-full lg:rounded-full md:rounded-full sm:rounded-md rounded-md focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-14 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                            <option className='secondary-heading-color' selected>Job Type</option>
                            {
                                jobTypeList?.map((item, index) => {
                                    return (
                                        <option className="secondary-heading-color" key={item?.id} value={item?.title}>
                                            {item?.title}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                        </svg>
                    </div>
                </div>
                <hr className='border-t-2 mb-10' />
            </div>
            <div className="main-container">
                {
                    jobList?.length > 0 ?
                        jobList?.map((item, index) => {
                            if (index < 15)
                                if (index == 0) {

                                    return (
                                        <>
                                            <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                            <hr key={index} className='border-t-2 mt-10 mb-14' />
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                            <hr key={index} className='border mt-10 mb-14' />
                                        </>
                                    )
                                }
                        })
                        :
                        null
                }
                {
                    jobList?.length > 10 ?
                        <ul className='flex gap-3 alignmi justify-center items-center w-full xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10'>
                            <li className='flex align-middle justify-center items-center w-8 h-8 cursor-pointer secondary-border-color rounded-md border '>
                                <span className='font-family-500 font-normal text-lg secondary-link-color'>
                                    1
                                </span>
                            </li>
                            <li className='flex align-middle justify-center items-center w-8 h-8 cursor-pointer rounded-md border border-gray-300'>
                                <span className='font-family-500 font-normal text-lg'>
                                    2
                                </span>
                            </li>
                            <li className='flex align-middle justify-center items-center w-8 h-8 cursor-pointer rounded-md border border-gray-300'>
                                <span className='font-family-500 font-normal text-lg'>
                                    3
                                </span>
                            </li>
                        </ul>
                        :
                        ""
                }
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default CompanyInformation