import React from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ProtectedRoute from '../../components/ProtectedRoute'
import SendTestListTable from '../../components/SendTestListTable'

const SendTest = () => {
    return (
        <>
            <ProtectedRoute>
                <Header bgColor={true} />
                <SendTestListTable />
                <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default SendTest