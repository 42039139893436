import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
import BannerImageMobile from '../assets/images/banner-mobile.png';
import { BiLoaderAlt } from 'react-icons/bi'
import axios from '../api/axios';
const ResetPasswordForm = () => {


    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")
    const navigate = useNavigate()

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const CHANGE_PASSWORD = "/reset_password";

    const [oldPassword, setOldPassword] = useState("")
    const [oldPasswordError, setOldPasswordError] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordError, setNewPasswordError] = useState("")
    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)


    const validateOldPassword = () => {
        if (oldPassword == "") {
            setOldPasswordError("Old Password can not be empty")
        } else {
            setOldPasswordError("")
        }
    }



    const validateNewPassword = () => {
        if (newPassword == "") {
            setNewPasswordError("New Password can not be empty")
        } else if (!mediumRegex.test(newPassword)) {
            setNewPasswordError("Please add uppercase, special characters to make Password Strong")
        } else if (!strongRegex.test(newPassword)) {
            setNewPasswordError("")
        } else {
            setNewPasswordError("")
        }
    }


    const changePassword = async () => {
        setLoader(true)
        if (oldPasswordError == "" && newPasswordError == "" && oldPassword == newPassword) {
            const post_data = {
                "password": oldPassword,
                "password_confirmation": newPassword,
                'token': token
            }
            console.log('post_data ', post_data)
            try {
                const response = await axios.post(
                    CHANGE_PASSWORD,
                    JSON.stringify(post_data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                console.log('response ', response)
                if (!response.data.error) {
                    navigate('/candidateOrEmployer')
                }
            } catch (err) {
                console.log('error ', err.response.data.message)
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
        } else {
            setApiResponseError("Password does not match")
        }

        setTimeout(() => {
            setLoader(false)
            setApiResponseError("")
        }, 10000)
    }

    return (
        <div className="home-banner login-banner flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle xl:pb-0 lg:pb-0 md:pb-0 sm:pb-0 pb-0 xl:pt-10 lg:pt-10 md:pt-16 sm:pt-24 pt-24">
            <div className="flex xl:gap-24 lg:gap-16 md:gap-10 sm:gap-0 gap-5 w-full xl:flex-row lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse">
                <div className="image xl:w-auto lg:w-auto md:w-auto xl:mx-0 lg:mx-0 md:mx-0 sm:mx-auto mx-auto sm:w-1/2 w-full">
                    <img src={BannerImage} alt="" className='xl:block lg:block md:block sm:hidden hidden' />
                    <img src={BannerImageMobile} alt="" className='xl:hidden lg:hidden md:hidden sm:block block' />
                </div>
                <div className="content flex align-middle xl:w-2/5 lg:w-1/2 md:w-11/12 sm:w-full flex-col justify-center">
                    <h1 className='text-white xl:mb-9 lg:mb-9 md:mb-9 sm:mb-5 mb-5 text-center font-family-700 font-extrabold'>
                        Reset Password
                    </h1>
                    <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                        <input
                            type="password"
                            autoComplete='off'
                            className={`form-control font-family-500 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border-solid ${oldPasswordError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} border  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setOldPassword(e.target.value)
                            }}
                            onBlur={validateOldPassword}
                            value={oldPassword}
                            id="oldpass"
                            placeholder={oldPasswordError != "" ? oldPasswordError : "Password"}
                        />
                        {
                            oldPasswordError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
                        <input
                            type="password"
                            autoComplete='off'
                            className={`form-control font-family-500 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border-solid ${newPasswordError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} border  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                            }}
                            onBlur={validateNewPassword}
                            value={newPassword}
                            id="newpass"
                            // value={username}
                            placeholder={newPasswordError != "" ? newPasswordError : "Confirm Password"}
                        />
                        {
                            newPasswordError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    <button
                        onClick={changePassword}
                        disabled={oldPasswordError || newPasswordError || loader}
                        className='primary-bg-color w-full mb-5 rounded-full px-8 py-2 disabled:opacity-60 opacity-100 disabled:cursor-not-allowed cursor-pointer text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                        Save
                        {
                            loader ?
                                <BiLoaderAlt className='loader-spinner ml-2' />
                                :
                                null
                        }
                    </button>
                    <a
                        onClick={() => {
                            navigate('/candidateOrEmployer')
                        }}
                        className='secondary-heading-color disabled:opacity-80 disabled:cursor-not-allowed w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                        <span
                            //  onClick={() => {
                            //     navigate('/candidate-login')
                            // }}
                            className='text-white font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                            Cancel
                        </span>
                    </a>
                    {
                        apiResponseError ?
                            <span className='text-red-600 text-sm font-family-500 font-medium py-3 block w-full'>
                                {apiResponseError}
                            </span>
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordForm