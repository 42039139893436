import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
import CompanyImage1 from '../assets/images/company-image.png'
const CompanyCard = ({
    imagePosition,
    companyTtitle,
    companyLogo,
    companyImage,
    website_url,
    id,
    companyDescription
}) => {
    const navigate = useNavigate()
    const flexCol = imagePosition == "right" ? "flex-col-reverse" : "flex-col"
    return (
        <div className={`flex xl:flex-row lg:flex-row md:flex-row sm:${flexCol} ${flexCol} align-middle xl:mb-20 lg:mb-14 md:mb-10 mb-10 items-center justify-center xl:gap-6 lg:gap-6 md:gap-6 sm:gap-10 gap-10 company-cards-list`}>
            {
                imagePosition == "right" ?
                    (
                        <>
                            <div className="card xl:py-8 xl:px-14 lg:py-6 lg:px-6 md:py-6  py-7 px-5 md:px-6 w-full h-max">
                                <a onClick={() => { navigate(`/company-info/${id}`) }} >
                                    <div className="flex align-middle xl:mb-6 lg:mb-6 md:mb-5 sm:mb-5 mb-5 items-center justify-start">
                                        <img className='mr-5 h-auto' width="40px" src={companyLogo ? `https://enthostalenthub.com/et/public/upload/${companyLogo}` : CompanyLogo1} alt="" />
                                        <h3 className='font-medium font-family-500 secondary-heading-color'>
                                            {
                                                companyTtitle
                                            }
                                        </h3>
                                    </div>
                                    <p className='mb-0 text-xl font-family-500 font-medium xl:text-left lg:text-left md:text-left sm:text-left text-center indent-6 secondary-heading-color'>
                                        {
                                            companyDescription
                                        }
                                    </p>
                                </a>
                            </div>
                            <div className=" flex w-full h-max object-cover justify-center  image-holder">
                                <img src={companyImage ? `https://enthostalenthub.com/et/public/upload/${companyImage}` : CompanyLogo1} className="h-max w-max " alt="" />
                            </div>
                            {/* <img src={CompanyImage1} className="w-full xl:w-full lg:w-full md:w-1/2 " alt="" /> */}
                        </>
                    )
                    :
                    (
                        <>
                            <div className="flex w-full h-max object-cover justify-center image-holder">
                                <img src={companyImage ? `https://enthostalenthub.com/et/public/upload/${companyImage}` : CompanyLogo1} className="h-max w-max" alt="" />
                            </div>
                            <div className="card xl:py-8 xl:px-14 lg:py-6 lg:px-6 md:py-6  py-7 px-5 md:px-6  w-full h-max">
                                <a onClick={() => { navigate(`/company-info/${id}`) }} >
                                    <div className="flex align-middle xl:mb-6 lg:mb-6 md:mb-5 sm:mb-5 mb-5 items-center justify-start">
                                        <img className='mr-5 h-auto' width="40px" src={companyLogo ? `https://enthostalenthub.com/et/public/upload/${companyLogo}` : CompanyLogo1} alt="" />
                                        <h3 className='font-medium font-family-500 secondary-heading-color'>
                                            {
                                                companyTtitle
                                            }
                                        </h3>
                                    </div>
                                    <p className='mb-0 text-xl font-family-500 font-medium xl:text-left lg:text-left md:text-left sm:text-left text-center indent-6 secondary-heading-color'>
                                        {
                                            companyDescription
                                        }
                                    </p>
                                </a>
                            </div>
                        </>
                    )
            }
        </div >
    )
}

export default CompanyCard