import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ContactUsForm from '../../components/ContactUsForm'

const ConctactUs = () => {
    return (
        <>
            <Header bgColor={true} />
            <ContactUsForm />
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default ConctactUs