import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
import CompanyImage1 from '../assets/images/company-image.png'
const CountryReasonCard = ({
    title,
    description,
    image,
    position
}) => {

    const flexCol = position == "right" ? "flex-col-reverse" : "flex-col"

    return (
        <div className="inner-main-container ">
            <div className={`xl:grid lg:grid md:grid sm:flex flex sm:${flexCol} ${flexCol} xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1  xl:gap-0 lg:gap-0 md:gap-3 sm:gap-0 gap-0 align-middle justify-center items-center xl:my-20 lg:my-14 md:my-10 sm:my-10 my-10 `}>
                {
                    position == "left" ?
                        <>
                            <div className="w-full">
                                <img className='w-full' src={image} alt="" />
                            </div>
                            <div className="xl:w-full lg:w-full md:w-full sm:w-11/12 w-11/12  xl:mt-0 lg:mt-0 md:mt-0 sm:-mt-16 -mt-14 relative z-50 margin-left" >
                                <div className="flex bg-white country-card flex-col justify-center align-middle xl:py-10 xl:px-11 lg:py-7 lg:px-5 md:py-7 md:px-5 sm:py-10 sm:px-6 py-10 px-5 ">
                                    <h3 className='font-semibold xl:mb-10 lg:mb-10 md:mb-5 sm:mb-5 mb-5 text-4xl font-family-500 primary-heading-color'>
                                        {title}
                                    </h3>
                                    <p className='secondary-heading-color text-xl mb-0 font-family-500 font-normal'>
                                        {description}
                                    </p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="xl:w-full lg:w-full md:w-full sm:w-11/12 w-11/12 xl:mt-0 lg:mt-0 md:mt-0 sm:-mt-16 -mt-14 relative z-50">
                                <div className="flex bg-white country-card flex-col justify-center align-middle xl:py-10 xl:px-11 lg:py-7 lg:px-5 md:py-7 md:px-5 sm:py-10 sm:px-6 py-10 px-5">
                                    <h3 className='font-semibold xl:mb-10 lg:mb-10 md:mb-5 sm:mb-5 mb-5 text-4xl font-family-500 primary-heading-color'>
                                        {title}
                                    </h3>
                                    <p className='secondary-heading-color text-xl mb-0 font-family-500 font-normal'>
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div className="w-full margin-left">
                                <img className='w-full' src={image} alt="" />
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default CountryReasonCard