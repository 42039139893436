import React, { Component, useState, useEffect } from 'react'
import axios from '../api/axios'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
const SingleJobDetail = ({ job, refetchJobsForHeader, bgColored, route }) => {

    const navigate = useNavigate()
    var date1 = new Date(job?.created_at);
    var date2 = new Date();


    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)
    const GET_API_URL = 'candidate'
    const ADD_JOB_TO_CART = "add_cart"
    const APPLY_FOR_JOB = "apply_job"
    const GET_STATE_BY_COUNTRY_ID = "country"

    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)

    var Difference_In_Time = date2.getTime() - date1.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    useEffect(() => {
        setCitiesList(job?.job_cities)
    }, [job])

    const [citiesList, setCitiesList] = useState([])
    const [cityListError, setCityListError] = useState("")
    const [selectedCity, setSelectedCity] = useState([])


    const addProductToCart = async (userInformation) => {
        console.log('selectedCity ', selectedCity)
        const response = await axios.post(
            ADD_JOB_TO_CART,
            {
                user_id: userInformation?.id,
                job_id: job?.id,
                city_id: selectedCity?.length > 0 ? selectedCity : -1,
                // selectedCity
                token: userInfo?.token
            }
        )
        if (!response.data.error) {
            setApiResponseError(response?.data?.msg)
        } else {
            setApiResponseError(response?.data?.msg)
        }
        refetchJobsForHeader(true)
        setTimeout(() => {
            setApiResponseError("")
        }, 10000)
    }



    const fetchUserInformation = async () => {
        try {
            let post_data = {
                "token": userInfo?.token,
            }

            const response = await axios.get(
                GET_API_URL,
                {
                    params: {
                        token: post_data.token
                    }
                },
                // JSON.stringify(post_data),
                {
                    headers: {
                        "Content-Type": "application/json",
                        // "token": post_data.token,
                        // 'Authorization': `token ${post_data.token}`
                    },
                }
            )
            addProductToCart(response?.data?.user)
        } catch (err) {
            console.log('error ', err.response.data.message)
        }
    }


    const ApplyForJob = async () => {
        setLoader(true)
        const response = await axios.get(
            GET_API_URL,
            {
                params: {
                    token: userInfo?.token
                }
            },
            // JSON.stringify(post_data),
            {
                headers: {
                    "Content-Type": "application/json",
                    // "token": post_data.token,
                    // 'Authorization': `token ${post_data.token}`
                },
            }
        )
        if (citiesList?.length == 0) {
            try {
                const apply = await axios.post(APPLY_FOR_JOB, {
                    user_id: response?.data?.user?.id,
                    token: userInfo?.token,
                    city_id: selectedCity[0],
                    job_id: job?.id,
                });
                if (!apply.data.error) {
                    setApiResponseError("Applied for the Job")
                } else {
                    setApiResponseError("Applied for the Job")
                }
            }
            catch (error) {
                console.log(error);
            }
        } else if (selectedCity?.length == 0) {
            setCityListError("Please Select atleast 1 city")
        } else {
            try {
                const apply = await axios.post(APPLY_FOR_JOB, {
                    user_id: response?.data?.user?.id,
                    token: userInfo?.token,
                    city_id: selectedCity[0],
                    job_id: job?.id,
                });
                if (!apply.data.error) {
                    setApiResponseError("Applied for the Job")
                } else {
                    setApiResponseError("Applied for the Job")
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        setTimeout(() => {
            setLoader(false)
            setApiResponseError("")
            setCityListError("")
        }, 10000)
    }

    const handleCityySelection = (cityId) => {
        let cArray = [...selectedCity]
        const filteredCArray = cArray?.filter((item) => item == cityId)
        if (filteredCArray?.length > 0) {
            const index = cArray?.indexOf(filteredCArray[0]);
            if (index > -1) { // only splice array when item is found
                cArray?.splice(index, 1); // 2nd parameter means remove one item only
            }
            setCityListError("")
        } else if (cArray?.length == 0) {
            setCityListError("")
            cArray?.push(cityId)
        } else {
            setCityListError("You can select only one city")
        }

        setSelectedCity(cArray)
    }

    return (
        <div className="flex align-middle xl:flex-row lg:flex-row md:flex-col flex-col justify-between xl:gap-24 gap-10 recommended-job items-center">
            <div className="flex align-middle justify-center flex-col w-full">
                <div className="xl:flex lg:flex md:block items-center align-middle justify-between">
                    <h4 className={`${bgColored ? "text-white" : "secondary-heading-color"} font-medium xl:mb-0 lg:mb-0 md:mb-3 sm:mb-3 mb-5 font-family-500`}>
                        {job?.title}
                    </h4>
                    <div className="flex items-center xl:flex-nowrap lg:flex-nowrap md:flex-nowrap sm:flex-nowrap flex-wrap xl:w-3/5 lg:w-3/5 md:w-full w-full align-middle xl:justify-between lg:justify-between md:justify-start sm:justify-start xl:gap-0 lg:gap-0 md:gap-4 gap-4 justify-between ">
                        <p className='flex xl:w-max lg:w-max md:w-max sm:w-max w-2/5 align-middle justify-center items-center'>
                            <span className='mr-2'>
                                <img src={job?.logo ? `https://enthostalenthub.com/et/public/upload/${job?.logo}` : CompanyLogo1} className="mr-0 w-9 h-9 object-cover" alt="" />
                            </span>
                            <span className={` ${bgColored ? "text-white" : "secondary-heading-color"} font-family-500 text-xl font-medium`}>
                                {
                                    job?.company_name ? job?.company_name : "Employer"
                                }
                            </span>
                        </p>
                        <span className={` ${bgColored ? "text-white" : "secondary-heading-color"} xl:text-left lg:text-left md:text-left sm:text-left text-end xl:hidden lg:hidden md:hideen sm:hidden block font-family-500 text-xl xl:w-max lg:w-max md:w-max sm:w-max w-2/5 font-medium`}>
                            {
                                parseInt(Difference_In_Days)
                            } Days ago
                        </span>
                        <span className={`${bgColored ? "text-white" : "secondary-heading-color"} font-family-500 text-xl xl:w-max lg:w-max md:w-max sm:w-max w-2/5 font-medium`}>
                            Salary ${
                                !isNaN(job?.salary) ? job?.salary : ' ' + Math.floor(Math.random() * (1000 - 100) + 100)
                            }
                        </span>
                        <span className={`${bgColored ? "text-white" : "secondary-heading-color"} xl:text-left lg:text-left md:text-left sm:text-left text-end font-family-500 text-xl xl:w-max lg:w-max md:w-max sm:w-max w-2/5 font-medium`}>
                            {
                                job?.job_type
                            }
                        </span>
                    </div>
                </div>
                <p className={`${bgColored ? "text-white" : "secondary-heading-color"} font-family-300 font-normal my-6 text-lg`}>
                    <span className='font-family-700 font-medium text-xl block w-full mb-5 secondary-heading-color'>
                        Employer Information:
                    </span>
                    {
                        job?.company_info
                    }
                    <span className='font-family-700 font-medium text-xl block w-full my-5 secondary-heading-color'>
                        About Job:
                    </span>
                    {
                        job?.description
                    }
                    <br />
                    <br />
                    {/* {
                        job?.roles_and_responsibilities
                    } */}
                    {/* <br />
                    <br />
                    {
                        job?.job_requirements
                    }
                    <br />
                    <br />
                    {
                        job?.nice_to_have
                    }
                    <br />
                    <br />
                    {
                        job?.benifits
                    } */}
                </p>
                {
                    job?.job_skills
                        ?
                        <ul className='flex align-middle items-center gap-4 xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center flex-wrap list-none'>
                            {
                                job?.job_skills?.map((item, index) => {
                                    return (
                                        <li key={index} className={`${bgColored ? "bg-white" : "bg-gray-200"} rounded-md px-4 h-6  ${route == "dashboard" ? "secondary-heading-color" : "call-to-action-color"} flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal`}>
                                            {
                                                item?.tag
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        :
                        ""
                }
            </div>
            <div className="flex align-middle justify-center xl:items-start lg:items-start md:items-start sm:items-start items-center xl:w-2/5 lg:w-2/5 md:w-full w-full flex-col">
                {
                    apiResponseError != "" ?
                        <span className={`text-green-500 xl:block lg:block md:block sm:block hidden text-lg mb-7 font-family-500 font-medium `}>
                            {apiResponseError}
                        </span>
                        :
                        null
                }
                <a className='bg-white w-max  px-6 xl:py-0 lg:py-2 md:py-2 xl:h-11 py-2 lg:h-auto h-auto ursor-pointer  xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4 mb-10 rounded-full border secondary-border-color  flex align-middle justify-center items-center'>
                    <button
                        onClick={() => {
                            navigate(`/find-job`)
                            window.scrollTo(0, 0);
                        }}
                        className='text-center text-lg font-family-500 font-normal secondary-link-color'>
                        Return to the Search Results
                    </button>
                </a>
                <span className={`${bgColored ? "text-white" : "secondary-heading-color"} xl:block lg:block md:block sm:block hidden text-lg mb-7 font-family-500 font-medium `}>
                    {
                        parseInt(Difference_In_Days)
                    } Days ago
                </span>
                {
                    cityListError ?
                        <span className='font-family-500 text-base my-2 text-red-500 font-medium'>
                            {cityListError}
                        </span>
                        :
                        ""
                }
                <ul className='flex flex-col mb-5 gap-5'>
                    {
                        citiesList?.map((item) => {
                            const filterCountry = selectedCity?.filter((cityId) =>
                                cityId == item.city_id
                            )
                            return (
                                <li
                                    id={item?.city_id}
                                    key={item?.city_id}
                                    onClick={(e) => {
                                        handleCityySelection(item?.city_id)
                                    }}
                                    className="flex cursor-pointer align-middle justify-start items-center h-max gap-3" value={item?.city_id}>
                                    <span className={`w-4 h-4 border-2 ${filterCountry?.length > 0 ? "border-gray-300 bg-gray-300" : "border-gray-300 "}  rounded-full`}>

                                    </span>
                                    <span className='secondary-heading-color font-family-500 text-base'>
                                        {item?.name}
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    userInfo?.token && userInfo?.userRole == 1
                        ?
                        <>
                            <p className='flex align-middle items-center xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4 mb-7'>
                                <span className='w-10'>
                                    {
                                        route == "dashboard"
                                            ?
                                            <svg className='mr-5' width="18" height="35" viewBox="0 0 18 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 20.1691L2.66906 0H13.0501L8.30445 10.0855H17.4991L2.66906 35L5.63504 20.1691H0Z" fill="#FFB538" />
                                            </svg>
                                            :
                                            <svg className='mr-5' width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.660156 15.1721L2.56663 0.765625H9.98163L6.59191 7.96958H13.1595L2.56663 25.7656L4.68519 15.1721H0.660156Z" fill="white" />
                                            </svg>
                                    }
                                </span>
                                <span
                                    onClick={loader ? null : ApplyForJob}
                                    className={`${bgColored ? "text-white" : "secondary-heading-color"} text-lg cursor-pointer font-family-500 font-medium`}>
                                    Apply Now
                                </span>
                            </p>
                            <p className='flex align-middle items-center '>
                                <span className='w-10'>
                                    {
                                        route == "dashboard"
                                            ?
                                            <svg width="35" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.9903 3.2181C16.0355 1.18263 13.3209 0.00442802 10.4536 0C4.88466 0 0 5.18133 0 11.0872C0 16.843 3.25005 22.7711 9.39934 28.2311C12.2485 30.7561 15.3739 32.9509 18.7158 34.7745C18.8983 34.8686 19.1148 34.8686 19.2974 34.7745C22.6381 32.9509 25.7625 30.7557 28.6107 28.2311C34.7631 22.7711 38.0131 16.843 38.0131 11.0872C38.0131 5.18133 33.1285 0 27.5595 0C24.6923 0.00442802 21.9776 1.18263 20.0228 3.2181C19.656 3.60008 19.3159 4.01224 19.0066 4.4525C18.6973 4.01224 18.3572 3.60008 17.9903 3.2181ZM27.5607 1.54867C24.6595 1.55355 21.9417 2.96875 20.2738 5.34278L19.0066 7.14645L17.7394 5.34278C16.0715 2.96875 13.3537 1.55355 10.4525 1.54867C5.82391 1.5494 1.54867 5.94989 1.54867 11.0872C1.54867 16.2368 4.46329 21.7769 10.4265 27.0721C13.0597 29.4057 15.9364 31.4485 19.0065 33.1658C22.075 31.4487 24.9506 29.4059 27.5827 27.0728C33.5496 21.7774 36.4645 16.237 36.4645 11.0872C36.4645 5.94989 32.1892 1.5494 27.5607 1.54867Z"
                                                    fill="#BB6BD9"
                                                ></path>
                                            </svg>
                                            :
                                            <svg width="35" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M17.9903 3.2181C16.0355 1.18263 13.3209 0.00442802 10.4536 0C4.88466 0 0 5.18133 0 11.0872C0 16.843 3.25005 22.7711 9.39934 28.2311C12.2485 30.7561 15.3739 32.9509 18.7158 34.7745C18.8983 34.8686 19.1148 34.8686 19.2974 34.7745C22.6381 32.9509 25.7625 30.7557 28.6107 28.2311C34.7631 22.7711 38.0131 16.843 38.0131 11.0872C38.0131 5.18133 33.1285 0 27.5595 0C24.6923 0.00442802 21.9776 1.18263 20.0228 3.2181C19.656 3.60008 19.3159 4.01224 19.0066 4.4525C18.6973 4.01224 18.3572 3.60008 17.9903 3.2181ZM27.5607 1.54867C24.6595 1.55355 21.9417 2.96875 20.2738 5.34278L19.0066 7.14645L17.7394 5.34278C16.0715 2.96875 13.3537 1.55355 10.4525 1.54867C5.82391 1.5494 1.54867 5.94989 1.54867 11.0872C1.54867 16.2368 4.46329 21.7769 10.4265 27.0721C13.0597 29.4057 15.9364 31.4485 19.0065 33.1658C22.075 31.4487 24.9506 29.4059 27.5827 27.0728C33.5496 21.7774 36.4645 16.237 36.4645 11.0872C36.4645 5.94989 32.1892 1.5494 27.5607 1.54867Z"
                                                    fill="#fff"
                                                ></path>
                                            </svg>
                                    }
                                </span>
                                <span
                                    onClick={
                                        loader ? null :
                                            fetchUserInformation
                                    }
                                    className={`${bgColored ? "text-white" : "secondary-heading-color"} text-lg cursor-pointer font-family-500 font-medium`}>
                                    Add Job to Wishlist
                                </span>
                            </p>
                        </>
                        :
                        null
                }

            </div>
        </div>
    )
}

export default SingleJobDetail