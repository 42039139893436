import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GoogleButton from '../assets/images/google-button.png';
import axios from '../api/axios';
import { RxUpload } from 'react-icons/rx'
import { BiLoaderAlt } from 'react-icons/bi'
import { WithContext as ReactTags } from 'react-tag-input';

import {
    auth,
    signInWithPopup,
    GoogleAuthProvider,
    googleProvider,
} from "./Config";

const CandidateSignupForm = () => {
    const navigate = useNavigate()

    const COUNTRIES_API_URL = "/countries";
    const COMPANY_EMAILS_API = "/dd";
    const TAGS_API_URL = "/tags";
    const INTERESTEDIN_API_URL = "/intin";
    const REGISTER_URL = "/register";
    const PROFILE_RESUME_UPLOAD_URL = "/user/resume";
    const LOGIN_TOKEN_URL = "/google_login";
    const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{4,})");


    const [userNameError, setUserNameError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [firstNameError, setFirstNameError] = useState("")
    const [lastNameError, setLastNameError] = useState("")
    const [LinkedInError, setLinkedInError] = useState("")
    const [countryError, setCountryError] = useState("")
    const [fileError, setFileError] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [tagsError, setTagsError] = useState("")
    const [interestedError, setInterestedError] = useState("")
    const [agreeError, setAgreeError] = useState(false)
    const [apiResponseError, setApiResponseError] = useState("")
    const [emailExistsError, setEmailExistsError] = useState("")
    const [tellUsError, setTellUsError] = useState("")
    const [creationSuccessMessage, setCreationSuccessMessage] = useState("")
    const [loader, setLoader] = useState(false)


    const [uploadedResume, setUploadedResume] = useState()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [country, setCountry] = useState("")
    const [intrestedIn, setIntrestedIn] = useState([])
    const [tags, setTags] = useState([])
    const [linkedin, setLinkedin] = useState("")
    const [agree, setAgree] = useState(false)
    const [phone, setPhone] = useState("")
    const [phoneCode, setPhoneCode] = useState()
    const [phoneLength, setPhoneLength] = useState()
    const [countriesList, setCountriesList] = useState()
    const [companyEmails, setCompanyEmails] = useState([]);
    const [tagsList, setTagsList] = useState([])
    const [interestedInList, setInterestedInList] = useState([])
    const [tellUs, setTellUs] = useState("")

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCountriesList(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchCompanyEmails = async () => {
        try {
            const response = await axios.get(COMPANY_EMAILS_API);
            //   console.log("response?.data ", response?.data);
            setCompanyEmails(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchTagsData = async () => {
        try {
            const response = await axios.get(TAGS_API_URL);
            setTagsList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchInterestedInData = async () => {
        try {
            const response = await axios.get(INTERESTEDIN_API_URL);
            setInterestedInList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCountriesData()
        fetchTagsData()
        fetchCompanyEmails()
        fetchInterestedInData()
    }, [])

    useEffect(() => {
        if (phoneCode == "+1") {
            setPhoneLength(10)
        } else if (phoneCode == "+92") {
            setPhoneLength(10)
        } else if (phoneCode == "+389") {
            setPhoneLength(8)
        } else if (phoneCode == "+355") {
            setPhoneLength(8)
        } else if (phoneCode == "+387") {
            setPhoneLength(8)
        } else if (phoneCode == "+55") {
            setPhoneLength(11)
        }
    }, [phoneCode])

    useEffect(() => {
        if (country) {
            const filteredCountry = countriesList?.filter((item) =>
                item.id == country
            )
            console.log('filter ', filteredCountry)
            if (filteredCountry.length > 0) {
                setPhoneCode(filteredCountry[0]?.phone_code)
            }
        }
    }, [country])

    const handleFileInput = (e) => {
        console.log('e.target.files[0] ', e.target.files[0])
        let file = e.target.files[0];
        setUploadedResume(file);
        setFileError("")
    };
    const onlyLettersAndSpaces = (str) => {
        return /^[A-Za-z\s]*$/.test(str);
    }

    const validateFirstName = () => {
        if (firstName == "") {
            setFirstNameError("First Name can not be empty")
        } else if (!onlyLettersAndSpaces(firstName)) {
            setFirstNameError("Only Alphabets are Allowed")
        } else {
            setFirstNameError("")
        }
    }

    const validateLastName = () => {
        if (lastName == "") {
            setLastNameError("Last Name can not be empty")
        } else if (!onlyLettersAndSpaces(lastName)) {
            setLastNameError("Only Alphabets are Allowed")
        } else {
            setLastNameError("")
        }
    }


    const validateEmail = () => {
        const address = email.split("@").pop();
        if (email == "") {
            setUserNameError("Username can not be empty")
        } else if (!regex.test(email)) {
            setUserNameError("Username must be a valid Email Address")
        }
        // else if (companyEmails?.includes(address)) {
        //     setUserNameError("Username must be a valid Email Address")
        // } 
        else {
            setUserNameError("");
        }
    }

    const validatePassword = () => {
        if (password == "") {
            setPasswordError("Password can not be empty")
        } else if (!mediumRegex.test(password)) {
            // alert('in med')
            setPasswordError("Please add uppercase, special characters to make Password Strong")
        } else if (!strongRegex.test(password)) {
            setPasswordError("Please add uppercase, special characters to make Password Strong")
            // alert('in strong')
        }
        else {
            // alert('in else')
            setPasswordError("")
        }
    }


    // const validateLInkedInURL = () => {
    //     if (linkedin?.toLowerCase() == "" || !linkedin?.toLowerCase()?.includes("www.linkedin.com")) {
    //         setLinkedInError("Url must be a valid Url")
    //     } else {
    //         setLinkedInError("")
    //     }
    // }

    const validateCountry = (e) => {
        if (e == "Country" || e == "") {
            setCountryError("Please select a valid Country")
        } else {
            setCountryError("")
        }
    }

    const validatePhoneNumber = () => {
        if (phone == "") {
            setPhoneError("Phone can not be empty")
        } else if (phone.length < phoneLength) {
            setPhoneError(`Please enter a valid phone having ${phoneLength} numbers`)
        } else {
            setPhoneError("")
        }
    }

    const validateFileUploaded = () => {
        if (!uploadedResume) {
            setFileError("Please Upload a File")
        } else {
            setFileError("")
        }
    }

    const validateTagsSelection = () => {
        console.log(tags.length)
        if (tags.length == 0) {
            setTagsError("Please select at least 1 Tech Tag")
        } else {
            setTagsError("")
        }
    }
    const validateInterestedTagsSelection = () => {
        if (intrestedIn.length == 0) {
            setInterestedError("Please select at least 1 Interested In Tag")
        } else {
            setInterestedError("")
        }
    }

    const validateIsAgree = () => {
        if (agree) {
            setAgreeError(false)
        } else {
            setAgreeError(true)
        }
    }

    const validateTellYourSelf = () => {
        if (tellUs == "") {
            setTellUsError("Please tell us about yourself!")
        } else {
            setTellUsError("")
        }
    }

    const checkValidationOfAllFields = () => {
        validateFirstName()
        validateLastName()
        validateCountry(country)
        validatePassword()
        validateEmail()
        // validateLInkedInURL()
        validatePhoneNumber()
        validateFileUploaded()
        validateTagsSelection()
        validateInterestedTagsSelection()
        validateTellYourSelf()
        validateIsAgree()
    }

    const registerUser = async () => {
        checkValidationOfAllFields()
        if (
            firstNameError == "" &&
            lastNameError == "" &&
            userNameError == "" &&
            countryError == "" &&
            phoneError == "" &&
            // LinkedInError == "" &&
            phoneError == "" &&
            tellUsError == "" &&
            uploadedResume
        ) {
            setLoader(true)
            const intrestTagsArr = []
            const techTagsArr = []
            const intrestedInClone = intrestedIn
            const tech_tagsClone = tags
            for (let i = 0; i < intrestedInClone.length; i++) {
                // intrestTagsArr.push(intrestedInClone[i].id)
                intrestTagsArr.push(parseInt(intrestedInClone[i].id))
            }
            for (let i = 0; i < tech_tagsClone.length; i++) {
                // techTagsArr.push(tech_tagsClone[i].id)
                techTagsArr.push(parseInt(tech_tagsClone[i].id))
            }

            try {
                const file = uploadedResume
                let post_data = {
                    "name": `${firstName} ${lastName}`,
                    "email": email,
                    password: `A${password}`,
                    "first_name": firstName,
                    "last_name": lastName,
                    "country_id": country,
                    // "resume": {
                    //     "name": file?.name,
                    //     "lastModified": file?.lastModified,
                    //     "size": file?.size,
                    //     "type": file?.type,
                    //     "webkitRelativePath": file?.webkitRelativePath,
                    //     "lastModifiedDate": file?.lastModifiedDate,
                    // },
                    // "resume":"pdf/doc",
                    // "file": uploadedResume, // resume pdf/doc, later it will be access domain/upload/file
                    "mobile": `${phoneCode}${phone}`,
                    "tech_tags": techTagsArr,
                    "interested_in": intrestTagsArr,
                    "linkedin_url": linkedin,
                    "tell_us": tellUs,
                    "is_term_accept": agree ? 1 : 0,
                    "user_type": 1 // 1 for candidate, 2 for employer
                }


                // const updateUserInfo = await fetch(`https://enthostalenthub.com/et/public/api${REGISTER_URL}`, {
                //     method: "POST",
                //     headers: { "Content-Type": "multipart/form-data" },
                //     body: { post_data },
                // });

                const response = await axios.post(
                    REGISTER_URL,
                    // JSON.stringify(post_data),
                    // formData,
                    post_data,
                    {
                        // headers: { "Content-Type": "application/json" },
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                )
                console.log('response ', response)
                if (!response.data.error) {
                    const userId = response?.data?.data?.id
                    const formData = new FormData();
                    // Update the formData obzject
                    formData.append("resume", file);
                    formData.append("user_id", userId);
                    formData.append("token", response?.data?.token);
                    const responseOfImage = await axios.post(
                        PROFILE_RESUME_UPLOAD_URL,
                        formData,
                        {
                            // headers: { "Content-Type": "application/json" },
                            headers: {
                                "Content-Type": "multipart/form-data",
                                "Authorization": `Bearer ${response?.data?.token}`
                            },
                        }
                    )
                    console.log("responseOfImage", responseOfImage)
                    if (
                        !responseOfImage.data.error
                    ) {
                        const obj = {
                            userRole: "1",
                            token: response.data.token,
                            userId: userId
                        }
                        localStorage.setItem('userInfo', JSON.stringify(obj))
                        setCreationSuccessMessage("Your account has been created")
                        navigate('/candidate-dashboard')
                    }
                } else {
                    const errors = response.data.error
                    setEmailExistsError(errors?.email[0])
                    console.log('errors ', errors?.email[0])
                }

            } catch (err) {
                console.log('error ', err.response.data.message)
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
        } else {
            // alert('asdfasdfd')
        }

        setTimeout(() => {
            setLoader(false)
            setEmailExistsError("")
            setApiResponseError("")
            setCreationSuccessMessage("")
        }, 10000)

    }

    const loginWithGoogle = async (e) => {
        e.preventDefault();
        signInWithPopup(auth, googleProvider)
            .then(async (result) => {
                console.log("result", result)
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const accessToken = result?._tokenResponse?.oauthIdToken;
                const userName = result?._tokenResponse?.displayName;
                //const email = result.user.email;
                //const fullName = result.user.displayName;
                if (accessToken) {
                    try {
                        let post_data = {
                            "email": email,
                            "token": accessToken
                        }

                        const response = await axios.post(
                            LOGIN_TOKEN_URL,
                            JSON.stringify(post_data),
                            {
                                headers: { "Content-Type": "application/json" },
                            }
                        )
                        if (!response.data.error) {
                            const obj = {
                                userRole: "1",
                                token: response.data.token,
                                userName: userName
                            }
                            localStorage.setItem('userInfo', JSON.stringify(obj))
                            navigate('/candidate-dashboard')
                        } else {
                            if (response?.data?.error?.password) {
                                setApiResponseError(response?.data?.error?.password)
                            }
                        }
                    } catch (err) {
                        console.log('error ', err.response.data.message)
                        setApiResponseError(err.response.data.message)
                        //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
                    }
                    // const obj = {
                    //     userRole: "1",
                    //     token: accessToken,
                    //     userName: userName
                    // }
                    // localStorage.setItem('userInfo', JSON.stringify(obj))
                    // navigate('/candidate-dashboard')
                }
            })
            .catch((error) => {
                console.log("error", error)

                //const errorCode = error.code;
                //const errorMessage = error.message;

            });
    };

    const suggestionsInterestedIn = interestedInList?.map(item => {
        return {
            id: `${item?.id}`,
            className: "tag-errors",
            text: item?.title
        };
    });

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    // const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const delimiters = [KeyCodes.comma];

    const handleDelete = i => {
        setIntrestedIn(intrestedIn.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        if (intrestedIn?.length < 5) {
            setIntrestedIn([...intrestedIn, tag]);
            setInterestedError("")
        } else {
            setInterestedError("Interested In tags are limited to 5")
        }
    };

    const handleTechTagsDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTechTagsAddition = tag => {
        if (tags?.length < 5) {
            setTags([...tags, tag]);
            setTagsError("")
        } else {
            setTagsError("Technical Skills are limited to 5")
        }
    };

    const suggestionsTechTags = tagsList?.map(item => {
        return {
            id: `${item?.id}`,
            className: "tag-errors",
            text: item?.tag
        };
    });

    console.log('suggestionsTechTags ', suggestionsTechTags)
    return (
        <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
            <h2 className='font-family-700 font-medium text-center primary-heading-color mb-8'>
                Create Account
            </h2>
            <div className="flex align-middle justify-center flex-col items-center w-3-12-5 mx-auto">
                <button onClick={loginWithGoogle} className='google-btn-bg-color w-full relative cursor-pointer rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Continue to Google
                    <img className='absolute top-0 left-0 z-10 cursor-pointer' src={GoogleButton} alt="" />
                </button>
                <span className='my-6 relative border-t-2 border-gray-300 xl:w-full lg:w-4/5 md:w-4/5 mx-auto sm:w-full w-full'>
                    <span className='absolute top-1/2 left-1/2 origin-center text-lg font-family-500 font-normal secondary-heading-color px-6' style={{ transform: "translate(-50%,-50%)", background: "#fff" }}>
                        Or
                    </span>
                </span>
                <div className="flex mb-6 align-middle justify-between  w-full gap-6">
                    <div className='w-full'>
                        <div className="flex relative flex-col w-full">
                            <input
                                type="text"
                                autoComplete='off'
                                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${firstNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                                id="firstname"
                                onBlur={validateFirstName}
                                value={firstName}
                                placeholder={"First Name"}
                            />
                            {
                                firstNameError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    : ""
                            }
                        </div>
                        {
                            firstNameError ?
                                <>
                                    <span className={`text-red-600 block ${firstNameError == "" ? "" : "pt-3"} font-family-500 font-medium text-sm`}>
                                        {
                                            firstNameError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>
                    <div className='w-full'>
                        <div className="flex relative flex-col w-full">
                            <input
                                type="text"
                                autoComplete='off'
                                className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${lastNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                onBlur={validateLastName}
                                id="lastname"
                                value={lastName}
                                placeholder={"Last Name"}
                            />
                            {
                                lastNameError ?
                                    <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                    </svg>
                                    : ""
                            }
                        </div>
                        {
                            lastNameError ?
                                <>
                                    <span className={`text-red-600 ${lastNameError == "" ? "" : "pt-3"} block font-family-500 font-medium text-sm`}>
                                        {
                                            lastNameError
                                        }
                                    </span>
                                </>
                                : ""
                        }
                    </div>
                </div>
                <div className="flex  flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <div className='relative w-full'>
                        <input
                            type="email"
                            autoComplete='off'
                            className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${userNameError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            onBlur={validateEmail}
                            value={email}
                            id="email"
                            placeholder={"User Name"}
                        />
                        {/* {
                            emailExistsError ?
                                <span style={{ left: "105%", transform: "translate(0px, -50%)" }} className='text-sm w-full absolute top-1/2 font-family-500 text-red-600 font-medium'>
                                    {emailExistsError}
                                </span>
                                :
                                null
                        } */}
                        {
                            userNameError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        userNameError ?
                            <>
                                <span className={`text-red-600 ${userNameError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        userNameError
                                    }
                                </span>
                            </>
                            // : emailExistsError ?
                            //     <>
                            //         <span className={`text-red-600 ${emailExistsError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                            //             {
                            //                 emailExistsError
                            //             }
                            //         </span>
                            //     </>
                            :
                            ""
                    }
                </div>
                <div className="flex  flex-col xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10 align-middle justify-between items-center w-full gap-0">
                    <div className='relative w-full'>
                        <input
                            type="password"
                            autoComplete='off'
                            className={`form-control font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${passwordError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} bg-white bg-clip-padding border border-solid  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            onBlur={validatePassword}
                            value={password}
                            id="password"
                            placeholder={"Password"}
                        />
                        {
                            passwordError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        passwordError != "" ?
                            <>
                                <span className={`${passwordError == "" ? "secondary-heading-color" : " text-red-600 "} pt-3 w-full block font-family-500 font-medium text-sm`}>
                                    <ul className={`list-disc pl-5 ${passwordError == "" ? "secondary-heading-color" : " text-red-600 py-1"}  `}>
                                        <li>Password must be at least 8 characters in length</li>
                                        <li>Use a minimum of 1 numeric value</li>
                                        <li>Use at least  1 Upper Case Letter (ABCD)</li>
                                        <li>
                                            Use at least 1 Lower Case Letter (abcd)
                                        </li>
                                        <li>
                                            User at least one special character ( !# % $ * )
                                        </li>
                                    </ul>
                                </span>
                            </>
                            :
                            null
                    }
                </div>
                <div className="flex flex-col mb-6 align-middle justify-between items-center w-full gap-0 ">
                    <div className='relative w-full'>
                        <select id="countries"
                            onChange={(e) => {
                                // validateCountry(e)
                                if (e.target.value != "Country") {
                                    setCountry(e.target.value)
                                    setCountryError("")
                                } else {
                                    setCountryError("Please select a valid Contry")
                                }
                            }}
                            className={`bg-white border font-family-500 font-normal ${countryError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} appearance-none outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}>
                            <option selected className='secondary-heading-color'>Country</option>
                            {
                                countriesList?.map((item, index) => {
                                    return (
                                        <option className='secondary-heading-color' value={item.id} key={index}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <svg className='absolute top-1/2 right-5 ' style={{ transform: "translate(0%,-50%)" }} width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                        </svg>
                        {
                            countryError ?
                                <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        countryError ?
                            <>
                                <span className={`text-red-600 ${countryError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        countryError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>

                <div className='w-full mb-6'>
                    <div className={`flex  align-middle justify-between items-center w-full gap-0 relative border ${phoneError != "" ? "border-red-500 text-red-500" : "border-gray-300"} border-solid rounded-full transition ease-in-out`}>
                        <select
                            onChange={(e) => {
                                setPhoneCode(e.target.value)
                            }}
                            defaultValue={phoneCode}
                            value={
                                phoneCode
                            }
                            id="mobileCode"
                            className={`xl:w-3/12 form-control font-family-500 font-normal ${phoneError != "" ? "focus:ring-red-500 active:ring-red-500 focus-within:ring-red-500 focus-within:border-red-500  active:border-red-500 focus-visible:ring-red-500 focus-visible:border-red-500 focus:border-red-500 text-red-500" : "focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color"}  lg:w-3/12 md:w-5/12 sm:w-5/12 w-5/12 bg-white appearance-none outline-none rounded-full border-none  block pl-4 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl `}>
                            <option className='secondary-heading-color' selected>code</option>
                            {
                                countriesList?.map((item, index) => {
                                    return (
                                        <option className='secondary-heading-color' value={item.phone_code} key={index}>{item.phone_code}</option>
                                    )
                                })
                            }
                        </select>
                        <input
                            maxLength={phoneLength}
                            minLength={phoneLength}
                            onChange={(e) => {
                                setPhone(e.target.value)
                            }}
                            value={phone}
                            onBlur={validatePhoneNumber}
                            type="text"
                            autoComplete='off'
                            className={`form-control font-family-500 font-normal block w-full pr-6 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${phoneError != "" ? "focus:border-red-500 placeholder:text-red-500 text-red-500" : "secondary-heading-color focus:border-gray-300"} bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            id="mobile"
                            placeholder={"Mobile"}
                        />
                        {
                            phoneError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        phoneError ?
                            <>
                                <span className={`text-red-600 ${phoneError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        phoneError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>
                <div className={`flex flex-col ${interestedError != "" ? "error-borders" : ""}  mb-6 align-middle justify-between rounded-3xl items-center w-full `}>
                    <div className='relative w-full'>
                        <ReactTags
                            tags={intrestedIn}
                            suggestions={suggestionsInterestedIn}
                            delimiters={delimiters}
                            placeholder="Technical Job Positions I am interested in"
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            inputFieldPosition="bottom"
                            allowDragDrop={false}
                            autocomplete
                        />
                        {
                            interestedError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        interestedError ?
                            <>
                                <span className={`text-red-600 ${interestedError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        interestedError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>
                <div className={`flex flex-col ${tagsError == "" ? "xl:mb-14 lg:mb-14 md:mb-10 sm:mb-10 mb-10" : "xl:mb-5 error-borders rounded-full lg:mb-5 md:mb-10 sm:mb-10 mb-10"} align-middle justify-between items-center w-full  transition ease-in-out`}>
                    <div className='relative w-full'>
                        <ReactTags
                            tags={tags}
                            suggestions={suggestionsTechTags}
                            delimiters={delimiters}
                            placeholder="My Technical Skills"
                            handleDelete={handleTechTagsDelete}
                            handleAddition={handleTechTagsAddition}
                            inputFieldPosition="bottom"
                            allowDragDrop={false}
                            autocomplete
                        />
                        {
                            tagsError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        tagsError ?
                            <>
                                <span className={`text-red-600 ${tagsError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        tagsError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>

                <div className='mb-6 w-full'>
                    <div className={`flex align-middle cursor-pointer justify-between items-center w-full relative border border-solid  ${fileError == "" ? "border-gray-300 secondary-heading-color" : "focus:ring-red-500 active:ring-red-500 focus-within:ring-red-500 focus-within:border-red-500  active:border-red-500 border-red-500 focus-visible:ring-red-500 focus-visible:border-red-500 focus:border-red-500 text-red-500"} rounded-full transition ease-in-out`}>
                        <div className="flex absolute top-0 left-0 w-full h-full align-middle justify-between px-8 py-2 items-center">
                            <span className={`form-control cursor-pointer font-family-500 ${fileError == "" ? "secondary-heading-color" : "text-red-500"}  font-normal block w-full xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}>
                                {
                                    uploadedResume ?
                                        uploadedResume?.name
                                        :
                                        "Upload Resume"
                                }
                            </span>
                            <RxUpload className=' text-xl secondary-secondary-heading-color font-medium font-family-500' width="20px" height="20px" />
                        </div>

                        <input
                            type="file"
                            onChange={(e) => {
                                handleFileInput(e)
                            }}
                            autoComplete='off'
                            className="form-control cursor-pointer relative opacity-0 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                            // onChange={(e) => {
                            //     setUsername(e.target.value)
                            // }}
                            onBlur={() => {
                                if (!uploadedResume) {
                                    setFileError("Please Upload a File")
                                } else {
                                    setFileError("")
                                }
                            }}
                            id="resume"
                            // value={username}
                            placeholder="Upload Resume"
                        />
                    </div>
                    {
                        fileError ?
                            <span className='text-red-600 text-sm font-family-500 pt-3 font-medium block w-full'>
                                {fileError}
                            </span>
                            : ""
                    }
                </div>
                <div className="flex  flex-col mb-6 align-middle justify-between items-center w-full gap-0">
                    <div className='w-full relative'>
                        <input
                            type="text"
                            autoComplete='off'
                            className={`form-control font-family-500 secondary-heading-color font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${LinkedInError != "" ? "border-red-500 focus:border-red-500 placeholder:text-red-500" : "border-gray-300 seceondary-heading-color  focus:border-gray-300 "} bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setLinkedin(e.target.value)
                            }}
                            // onBlur={validateLInkedInURL}
                            value={linkedin}
                            id="linkedInLink"
                            placeholder={"LinkedIn Link"}
                        />
                        {
                            LinkedInError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        LinkedInError ?
                            <>
                                <span className={`text-red-600 ${LinkedInError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        LinkedInError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>

                <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full ">
                    <div className="relative w-full">
                        <textarea
                            onChange={(e) => {
                                setTellUs(e.target.value);
                            }}
                            onBlur={validateTellYourSelf}
                            value={tellUs}
                            id="tell_us"
                            className={`form-control h-28 font-family-500 font-normal block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border border-solid ${tellUsError != ""
                                ? "focus:border-red-500 placeholder:text-red-500 border-red-500"
                                : "focus:border-gray-300 secondary-heading-color border-gray-300"
                                } rounded-3xl-5 transition ease-in-out focus:bg-white secondary-heading-color  focus:outline-none`}
                            placeholder={
                                "Tell us about Yourself"
                            }
                        ></textarea>
                        {tellUsError ? (
                            <svg
                                className="absolute top-5"
                                style={{ right: "5%" }}
                                width="22"
                                height="19"
                                viewBox="0 0 22 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z"
                                    fill="#EF4444"
                                />
                            </svg>
                        ) : (
                            ""
                        )}
                    </div>
                    {
                        tellUsError ?
                            <>
                                <span className={`text-red-600 block ${tellUsError == "" ? "" : "pt-3"} font-family-500 block w-full font-medium text-sm`}>
                                    {
                                        tellUsError
                                    }
                                </span>
                            </>
                            : ""
                    }
                </div>
                <div className={`flex align-middle w-full relative ${agreeError ? "xl:mb-5 lg:mb-5 md:mb-10 sm:mb-5 mb-5" : "xl:mb-5 lg:mb-5 md:mb-10 sm:mb-5 mb-5"} items-center `}>
                    <label className="relative flex align-middle justify-center items-center custom-checkbox">
                        <input
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setAgreeError(false)
                                } else {
                                    setAgreeError(true)
                                }
                                setAgree(e.target.checked)

                            }}
                            value={agree}
                            type="checkbox"
                        // I
                        // value={rememberMe} 
                        />
                        <span className={`checkmark ${agreeError != "" ? "border-red-500" : "border-gray-300"}`} ></span>
                        <span
                            className={`form-check-label ${agreeError == "" ? "secondary-heading-color" : "text-red-500"} xl:leading-none lg:leading-none md:leading-none sm:leading-none leading-tight pl-9 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl font-family-500 font-medium inline-block `}
                            htmlFor="rememberMe">
                            Yes, I understand and Agree to the Terms of Service
                        </span>
                    </label>
                    {
                        agreeError ?
                            <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                            </svg>
                            : ""
                    }
                </div>

                <button
                    onClick={registerUser}
                    disabled={firstNameError || lastNameError || tellUsError || userNameError || tagsError || interestedError || passwordError || countryError || phoneError || agreeError
                        // || LinkedInError
                    }
                    className='primary-bg-color w-full mb-5 rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Create
                    {
                        loader ?
                            <BiLoaderAlt className='loader-spinner ml-2' />
                            :
                            null
                    }
                </button>
                {
                    emailExistsError ?
                        <span className='text-sm  text-center w-full font-family-500 text-red-600 font-medium'>
                            {emailExistsError}
                        </span>
                        :
                        null
                }
                {
                    creationSuccessMessage ?
                        <span className='text-sm  text-center w-full font-family-500 text-green-500 font-medium'>
                            {creationSuccessMessage}
                        </span>
                        :
                        null
                }
                {
                    apiResponseError ?
                        <span className='text-red-600 text-sm font-family-500 font-medium py-3 block w-full'>
                            {apiResponseError}
                        </span>
                        :
                        ""
                }
                <a className='secondary-heading-color w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                    Already have account?
                    <span onClick={() => {
                        navigate('/candidate-login')
                    }} className='call-to-action-text-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                        Log in
                    </span>
                </a>
            </div>
            <div className=" xl:flex lg:flex md:hidden sm:hidden hidden align-middle mt-10 justify-center flex-col items-center xl:w-4/12 lg:w-6/12  mx-auto">
                <hr className=' bg-white border-t-2 border-gray-300 my-0 mx-auto w-full' />
            </div>
        </div >
    )
}

export default CandidateSignupForm