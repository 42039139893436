import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FindAJobBanner from '../../components/FindAJobBanner'
import CallToActionBanner from '../../components/CallToActionBanner'
import { useEffect, useState } from 'react'
import axios from '../../api/axios'
import CompanyInfoCard from '../../components/CompanyInfoCard'
import RecommendedJobs from '../../components/RecommendedJobs'

import { useParams } from 'react-router-dom';
import SingleJobDetail from '../../components/SingleJobDetail'

const FindJob = () => {

    const TOP_COMPANIES = "top_companies"
    const SINGLE_jOB = "job"
    const SINGLE_jOB_SIMILAR_JOBS = "similar_jobs"
    const { job_id } = useParams();
    const CART_API_URL = '/carts'
    const JOBSLIST = "alljobs"


    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)

    const [countriesList, setCountriesList] = useState([])
    const [jobList, setJobList] = useState([])
    const [similarJobs, setSimilarJobs] = useState([])
    const [singleJobInfo, setSingleJobInfo] = useState([])
    const [reloadPage, setReloadPage] = useState(false)
    const [cartJobs, setCartJobs] = useState([])

    const fetchCountryList = async () => {
        // https://enthostalenthub.com/et/public/api/companies
        try {
            const response = await axios.get(TOP_COMPANIES);
            setCountriesList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchSingleJobInfo = async () => {
        try {
            const response = await axios.post(SINGLE_jOB, {
                job_id: job_id,
                token: userInfo?.token
            });
            setSingleJobInfo(response?.data?.data)
            // setCountriesList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchJobList = async () => {
        try {
            const response = await axios.get(JOBSLIST,
                {
                    params: {
                        token: userInfo?.token
                    }
                },
            );
            setJobList(response.data?.reverse())
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchSimilarJobs = async () => {
        try {
            const response = await axios.post(SINGLE_jOB_SIMILAR_JOBS, {
                job_id: job_id,
                token: userInfo?.token
            });
            console.log('response ', response?.data?.data)
            // setSingleJobInfo(response?.data?.data)
            setSimilarJobs(response?.data?.data)
            // setCountriesList(response.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCountryList()
        if (job_id) {
            fetchSingleJobInfo()
            fetchSimilarJobs()
            setJobList([])
        } else {
            fetchJobList()
            setSingleJobInfo([])
            setSimilarJobs([])
        }
    }, [])

    useEffect(() => {
        if (job_id) {
            fetchSingleJobInfo()
            setJobList([])
            fetchSimilarJobs()
        } else {
            setSingleJobInfo([])
            setSimilarJobs([])
            fetchJobList()
        }
    }, [job_id])

    useEffect(() => {
        // console.log('asdfdsasdf')
    }, [singleJobInfo, jobList])

    const setJobListsAfterSearch = (props) => {
        console.log('props  ', props)
        setJobList(props)
    }

    const refetchJobsForHeader = (props) => {
        console.log('props ', props)
        setReloadPage(props)
    }

    const fetchCartJobs = async () => {
        try {
            const response = await axios.get(CART_API_URL, {
                params: {
                    token: userInfo?.token
                }
            });
            setCartJobs(response.data)
        }
        catch (error) {
            console.log(error);
        }
        setReloadPage(false)
    }

    useEffect(() => {
        console.log('reload Page ', reloadPage)
        if (reloadPage) {
            fetchCartJobs()
        }
    }, [reloadPage])



    return (
        <>
            <Header countFromParent={cartJobs?.length} bgColor={false} />
            <FindAJobBanner setJobListsAfterSearch={setJobListsAfterSearch} />
            <CompanyInfoCard route="FinJob" countriesList={countriesList} />
            <div className="xl:pb-20 lg:pb-14 md:pb-10 pb-10">
                <div className="main-container">
                    {
                        jobList?.length > 0 ?
                            jobList?.map((item, index) => {
                                if (index == 0) {
                                    return (
                                        <>
                                            <hr key={index} className='border-t-2 mb-10' />
                                            <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                            <hr key={index} className='border-t-2 mt-10 mb-14' />
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                            <hr key={index} className='border mt-10 mb-14' />
                                        </>
                                    )
                                }
                            })
                            :
                            job_id
                                ?
                                singleJobInfo?.map((item, index) => {
                                    return (
                                        <>
                                            <SingleJobDetail refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                            {/* <RecommendedJobs key={index} route="dashboard" job={item} bgColored={false} /> */}
                                        </>
                                    )
                                })
                                :
                                null
                        // <RecommendedJobs />                    
                    }
                    {
                        job_id ?
                            <>
                                <h3 className='primary-heading-color pt-10 font-family-500 font-semibold'>
                                    Similar Jobs
                                </h3>
                                <hr className='border mt-10 mb-14' />
                            </>
                            :
                            null
                    }
                    {
                        similarJobs?.length > 0
                            ?
                            similarJobs?.map((item, index) => {
                                return (
                                    <>
                                        <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                        <hr key={index} className='border mt-10 mb-14' />
                                    </>
                                )
                            })
                            :
                            null
                    }
                    {/* {
                        jobList?.length > 0 ?
                            <a className='flex align-middle justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                                <button className='text-white text-center text-xl font-family-500 font-medium'>
                                    See more
                                </button>
                            </a>
                            :
                            ""
                    } */}
                </div>
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default FindJob