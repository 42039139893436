import React, { Component, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Line } from 'rc-progress';
import axios from '../api/axios';
const CartJobListing = ({ job, refetchJobsForHeader, refresh }) => {

    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)
    const GET_API_URL = 'candidate'
    const DELETE_JOB_FROM_CART = "cart_rem_job"
    const APPLY_FOR_JOB = "apply_job"
    const navigate = useNavigate()

    const DeleteJobFromCart = async () => {

        const response = await axios.get(
            GET_API_URL,
            {
                params: {
                    token: userInfo?.token
                }
            },
            // JSON.stringify(post_data),
            {
                headers: {
                    "Content-Type": "application/json",
                    // "token": post_data.token,
                    // 'Authorization': `token ${post_data.token}`
                },
            }
        )

        try {
            const emptyCartResponse = await axios.post(DELETE_JOB_FROM_CART, {
                user_id: response?.data?.user?.id,
                token: userInfo?.token,
                job_id: job?.id,
            });
            console.log('emptyCartResponse ', emptyCartResponse?.data)
            refresh()
        }
        catch (error) {
            console.log(error);
        }
        refetchJobsForHeader(true)
    }

    const ApplyForJob = async () => {
        const response = await axios.get(
            GET_API_URL,
            {
                params: {
                    token: userInfo?.token
                }
            },
            // JSON.stringify(post_data),
            {
                headers: {
                    "Content-Type": "application/json",
                    // "token": post_data.token,
                    // 'Authorization': `token ${post_data.token}`
                },
            }
        )
        try {
            const apply = await axios.post(APPLY_FOR_JOB, {
                user_id: response?.data?.user?.id,
                token: userInfo?.token,
                city_id: 0,
                job_id: job?.id,
            });
            const emptyCartResponse = await axios.post(DELETE_JOB_FROM_CART, {
                user_id: response?.data?.user?.id,
                token: userInfo?.token,
                job_id: job?.id,
            });
            navigate('/thank-you')
        }
        catch (error) {
            console.log(error);
        }
    }


    const checkCityValidation = () => {
        if (job?.job_cities?.length > 1) {
            navigate(`/find-job/${job?.id}`)
            window.scrollTo(0, 600)
        } else {
            ApplyForJob()
        }
    }
    return (
        <>
            <div className="flex align-middle justify-between xl:flex-row lg:flex-row md:flex-row sm:flex-col flex-col items-center gap-10 w-full">
                <div className='flex align-middle justify-center items-center flex-col w-full'>
                    <div className='flex flex-wrap align-middle justify-between items-center xl:gap-0 lg:gap-3 md:gap-3 sm:gap-5 gap-5  w-full'>
                        <h4 className={`secondary-heading-color font-medium xl:mb-0 lg:mb-0 md:mb-3 sm:mb-3 mb-5 font-family-500`}>
                            {job?.title}
                        </h4>
                        <div className='flex align-middle justify-center items-center gap-5'>
                            <img src={job?.logo ? `https://enthostalenthub.com/et/public/upload/${job?.logo}` : CompanyLogo1} className="mr-0 w-9 h-9 object-cover" alt="" />
                            <span className={` secondary-heading-color font-family-500 text-xl font-medium`}>
                                {
                                    job?.company_name ? job?.company_name : "Employer"
                                }
                            </span>
                        </div>
                        <a className='bg-white w-max  px-6 xl:py-0 lg:py-2 md:py-2 xl:h-11 py-2 lg:h-auto h-auto ursor-pointer  xl:mb-4 lg:mb-4 md:mb-4 sm:mb-4 mb-10 rounded-full border secondary-border-color  flex align-middle justify-center items-center'>
                            <button onClick={() => {
                                navigate(`/find-job/${job?.id}`)
                                window.scrollTo(0, 600);
                            }} className='text-center text-lg font-family-500 font-normal secondary-link-color'>
                                View detailed Job Position
                            </button>
                        </a>
                    </div>
                    <div className='flex w-full align-middle justify-start items-start flex-col '>
                        <h4 className={`secondary-heading-color font-medium xl:mb-3 lg:mb-3 md:mb-3 sm:mb-3 mb-5 font-family-500`}>
                            Salary : ${job?.salary}
                        </h4>
                        <p className='secondary-heading-color font-family-300 font-normal text-base'>
                            {
                                job?.description
                            }
                        </p>
                    </div>
                    {
                        job?.job_skills
                            ?
                            <ul className='flex w-full pt-5 pb-5 align-middle items-center gap-4 xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center flex-wrap list-none'>
                                {
                                    job?.job_skills?.map((item, index) => {
                                        return (
                                            <li key={index} className={`bg-gray-200 rounded-md px-4 h-6  secondary-heading-color flex align-middle justify-center items-center text-lg font-family-500 cursor-pointer font-normal`}>
                                                {
                                                    item?.tag
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            :
                            ""
                    }
                    <p className='flex align-middle w-full justify-end items-center gap-5'>
                        <span
                            onClick={checkCityValidation}
                            className='secondary-link-color cursor-pointer font-family-500 font-medium w-max flex justify-end'>
                            Apply for this Job
                        </span>
                        <span
                            onClick={DeleteJobFromCart}
                            className='secondary-heading-color cursor-pointer font-family-500 font-medium w-max flex justify-end'>
                            Delete from the List
                        </span>
                    </p>
                </div>
                <div className='flex align-middle justify-center h-full items-center'>
                    <div className='flex p-8 bg-gray-100 '>
                        <div className='flex align-middle xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col justify-between gap-5 items-center'>
                            <div className='w-2/5'>
                                <CircularProgressbar value="63" text="63%" />
                            </div>
                            <ul className='block w-full'>
                                <li className='flex w-full align-middle justify-start items-start flex-col my-2'>
                                    <span className='call-to-action-text-color font-family-500 font-semibold text-sm'>
                                        ATS FINDINGS
                                    </span>
                                    <span className='flex w-full align-middle justify-start items-center gap-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.4984 1.40625L9.50156 15.9047L3 9.40781L0 12.4078L9.99844 22.4062L24 4.40625L20.4984 1.40625Z" fill="#0AB84D" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            6/7
                                        </span>
                                        <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#0AB84D" />
                                        <svg width="24" height="25" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.64016 2.82469L7.50016 6.68469L11.3402 2.84469C11.425 2.7544 11.5272 2.68218 11.6406 2.63235C11.754 2.58252 11.8763 2.5561 12.0002 2.55469C12.2654 2.55469 12.5197 2.66004 12.7073 2.84758C12.8948 3.03512 13.0002 3.28947 13.0002 3.55469C13.0025 3.67729 12.9797 3.79907 12.9333 3.91257C12.8869 4.02606 12.8178 4.12887 12.7302 4.21469L8.84016 8.05469L12.7302 11.9447C12.895 12.1059 12.9916 12.3243 13.0002 12.5547C13.0002 12.8199 12.8948 13.0743 12.7073 13.2618C12.5197 13.4493 12.2654 13.5547 12.0002 13.5547C11.8727 13.56 11.7456 13.5387 11.6268 13.4922C11.508 13.4457 11.4002 13.3751 11.3102 13.2847L7.50016 9.42469L3.65016 13.2747C3.56567 13.362 3.46473 13.4316 3.35316 13.4797C3.2416 13.5278 3.12163 13.5532 3.00016 13.5547C2.73495 13.5547 2.48059 13.4493 2.29306 13.2618C2.10552 13.0743 2.00016 12.8199 2.00016 12.5547C1.99783 12.4321 2.02058 12.3103 2.06701 12.1968C2.11344 12.0833 2.18257 11.9805 2.27016 11.8947L6.16016 8.05469L2.27016 4.16469C2.10535 4.00345 2.0087 3.7851 2.00016 3.55469C2.00016 3.28947 2.10552 3.03512 2.29306 2.84758C2.48059 2.66004 2.73495 2.55469 3.00016 2.55469C3.24016 2.55769 3.47016 2.65469 3.64016 2.82469Z" fill="#F32131" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                    </span>
                                </li>
                                <li className='flex w-full align-middle justify-start items-start flex-col my-2'>
                                    <span className='call-to-action-text-color font-family-500 font-semibold text-sm'>
                                        RECRUITING FINDINGS
                                    </span>
                                    <span className='flex w-full align-middle justify-start items-center gap-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.4984 1.40625L9.50156 15.9047L3 9.40781L0 12.4078L9.99844 22.4062L24 4.40625L20.4984 1.40625Z" fill="#0AB84D" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            4/7
                                        </span>
                                        <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#0AB84D" />
                                        <svg width="24" height="25" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.64016 2.82469L7.50016 6.68469L11.3402 2.84469C11.425 2.7544 11.5272 2.68218 11.6406 2.63235C11.754 2.58252 11.8763 2.5561 12.0002 2.55469C12.2654 2.55469 12.5197 2.66004 12.7073 2.84758C12.8948 3.03512 13.0002 3.28947 13.0002 3.55469C13.0025 3.67729 12.9797 3.79907 12.9333 3.91257C12.8869 4.02606 12.8178 4.12887 12.7302 4.21469L8.84016 8.05469L12.7302 11.9447C12.895 12.1059 12.9916 12.3243 13.0002 12.5547C13.0002 12.8199 12.8948 13.0743 12.7073 13.2618C12.5197 13.4493 12.2654 13.5547 12.0002 13.5547C11.8727 13.56 11.7456 13.5387 11.6268 13.4922C11.508 13.4457 11.4002 13.3751 11.3102 13.2847L7.50016 9.42469L3.65016 13.2747C3.56567 13.362 3.46473 13.4316 3.35316 13.4797C3.2416 13.5278 3.12163 13.5532 3.00016 13.5547C2.73495 13.5547 2.48059 13.4493 2.29306 13.2618C2.10552 13.0743 2.00016 12.8199 2.00016 12.5547C1.99783 12.4321 2.02058 12.3103 2.06701 12.1968C2.11344 12.0833 2.18257 11.9805 2.27016 11.8947L6.16016 8.05469L2.27016 4.16469C2.10535 4.00345 2.0087 3.7851 2.00016 3.55469C2.00016 3.28947 2.10552 3.03512 2.29306 2.84758C2.48059 2.66004 2.73495 2.55469 3.00016 2.55469C3.24016 2.55769 3.47016 2.65469 3.64016 2.82469Z" fill="#F32131" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                    </span>
                                </li>
                                <li className='flex w-full align-middle justify-start items-start flex-col my-2'>
                                    <span className='call-to-action-text-color font-family-500 font-semibold text-sm'>
                                        SKILLS MATCH
                                    </span>
                                    <span className='flex w-full align-middle justify-start items-center gap-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.4984 1.40625L9.50156 15.9047L3 9.40781L0 12.4078L9.99844 22.4062L24 4.40625L20.4984 1.40625Z" fill="#0AB84D" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            17/24
                                        </span>
                                        <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#0AB84D" />
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.7248 21C2.34147 21 2.05814 20.8333 1.8748 20.5C1.69147 20.1667 1.69147 19.8333 1.8748 19.5L11.1248 3.5C11.3081 3.16667 11.5998 3 11.9998 3C12.3998 3 12.6915 3.16667 12.8748 3.5L22.1248 19.5C22.3081 19.8333 22.3081 20.1667 22.1248 20.5C21.9415 20.8333 21.6581 21 21.2748 21H2.7248ZM11.9998 10C11.7165 10 11.4791 10.0957 11.2878 10.287C11.0958 10.479 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0958 14.5207 11.2878 14.712C11.4791 14.904 11.7165 15 11.9998 15C12.2831 15 12.5208 14.904 12.7128 14.712C12.9041 14.5207 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.9041 10.479 12.7128 10.287C12.5208 10.0957 12.2831 10 11.9998 10ZM11.9998 18C12.2831 18 12.5208 17.904 12.7128 17.712C12.9041 17.5207 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.9041 16.4793 12.7128 16.288C12.5208 16.096 12.2831 16 11.9998 16C11.7165 16 11.4791 16.096 11.2878 16.288C11.0958 16.4793 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0958 17.5207 11.2878 17.712C11.4791 17.904 11.7165 18 11.9998 18Z" fill="#D27C2C" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                    </span>
                                </li>
                                <li className='flex w-full align-middle justify-start items-start flex-col my-2'>
                                    <span className='call-to-action-text-color font-family-500 font-semibold text-sm'>
                                        PREMIUM FORMAT CHECKS
                                    </span>
                                    <span className='flex w-full align-middle justify-start items-center gap-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.4984 1.40625L9.50156 15.9047L3 9.40781L0 12.4078L9.99844 22.4062L24 4.40625L20.4984 1.40625Z" fill="#0AB84D" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                        <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#0AB84D" />
                                        <svg width="24" height="25" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.64016 2.82469L7.50016 6.68469L11.3402 2.84469C11.425 2.7544 11.5272 2.68218 11.6406 2.63235C11.754 2.58252 11.8763 2.5561 12.0002 2.55469C12.2654 2.55469 12.5197 2.66004 12.7073 2.84758C12.8948 3.03512 13.0002 3.28947 13.0002 3.55469C13.0025 3.67729 12.9797 3.79907 12.9333 3.91257C12.8869 4.02606 12.8178 4.12887 12.7302 4.21469L8.84016 8.05469L12.7302 11.9447C12.895 12.1059 12.9916 12.3243 13.0002 12.5547C13.0002 12.8199 12.8948 13.0743 12.7073 13.2618C12.5197 13.4493 12.2654 13.5547 12.0002 13.5547C11.8727 13.56 11.7456 13.5387 11.6268 13.4922C11.508 13.4457 11.4002 13.3751 11.3102 13.2847L7.50016 9.42469L3.65016 13.2747C3.56567 13.362 3.46473 13.4316 3.35316 13.4797C3.2416 13.5278 3.12163 13.5532 3.00016 13.5547C2.73495 13.5547 2.48059 13.4493 2.29306 13.2618C2.10552 13.0743 2.00016 12.8199 2.00016 12.5547C1.99783 12.4321 2.02058 12.3103 2.06701 12.1968C2.11344 12.0833 2.18257 11.9805 2.27016 11.8947L6.16016 8.05469L2.27016 4.16469C2.10535 4.00345 2.0087 3.7851 2.00016 3.55469C2.00016 3.28947 2.10552 3.03512 2.29306 2.84758C2.48059 2.66004 2.73495 2.55469 3.00016 2.55469C3.24016 2.55769 3.47016 2.65469 3.64016 2.82469Z" fill="#F32131" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                    </span>
                                </li>
                                <li className='flex w-full align-middle justify-start items-start flex-col my-2'>
                                    <span className='call-to-action-text-color font-family-500 font-semibold text-sm'>
                                        COVER LETTER CHECKS
                                    </span>
                                    <span className='flex w-full align-middle justify-start items-center gap-2'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.4984 1.40625L9.50156 15.9047L3 9.40781L0 12.4078L9.99844 22.4062L24 4.40625L20.4984 1.40625Z" fill="#0AB84D" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                        <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#0AB84D" />
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.7248 21C2.34147 21 2.05814 20.8333 1.8748 20.5C1.69147 20.1667 1.69147 19.8333 1.8748 19.5L11.1248 3.5C11.3081 3.16667 11.5998 3 11.9998 3C12.3998 3 12.6915 3.16667 12.8748 3.5L22.1248 19.5C22.3081 19.8333 22.3081 20.1667 22.1248 20.5C21.9415 20.8333 21.6581 21 21.2748 21H2.7248ZM11.9998 10C11.7165 10 11.4791 10.0957 11.2878 10.287C11.0958 10.479 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0958 14.5207 11.2878 14.712C11.4791 14.904 11.7165 15 11.9998 15C12.2831 15 12.5208 14.904 12.7128 14.712C12.9041 14.5207 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.9041 10.479 12.7128 10.287C12.5208 10.0957 12.2831 10 11.9998 10ZM11.9998 18C12.2831 18 12.5208 17.904 12.7128 17.712C12.9041 17.5207 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.9041 16.4793 12.7128 16.288C12.5208 16.096 12.2831 16 11.9998 16C11.7165 16 11.4791 16.096 11.2878 16.288C11.0958 16.4793 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0958 17.5207 11.2878 17.712C11.4791 17.904 11.7165 18 11.9998 18Z" fill="#D27C2C" />
                                        </svg>
                                        <span className='secondary-heading-color font-semibold text-xs font-family-700'>
                                            1/7
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='my-10 border-t-2 border-gray-300' />
        </>

    )
}

export default CartJobListing