import CallToActionBanner from "../../components/CallToActionBanner"
import CountryList from "../../components/CountryList"
import CountryPageBanner from "../../components/CountryPageBanner"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import USABanner from '../../assets/images/usa-banner.png';
import CountryReasonCard from "../../components/CountryReasonCard"
import WhyUSA from '../../assets/images/why-usa.png'
import USAHiring from '../../assets/images/hiring-usa.png'
import USATeam from '../../assets/images/usa-team.png'
import ServicesCard from "../../components/ServicesCard"


const USA = () => {

    {
        /**
            Purpose: The purpose of this program is to show USA Country Page
            Author:   Arslan Mukhtar
            Date Created:    2022-12-19
            Last Date Modified:  2022-12-19
            Modified By: Arslan Mukhtar
        */
    }

    return (
        <>
            <Header bgColor={true} />
            <CountryPageBanner image={USABanner} />
            <div className="main-container">
                <h1 className="text-center xl:mt-20 lg:mt-14 md:mt-10 sm:mt-10 mt-10  xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6  font-semibold xl:text-5xl lg:text-5xl font-family-700 primary-heading-color">
                    Work with us to access untapped tech talent.
                </h1>
                <p className="text-center xl:w-3/5 lg:w-4/5 md:w-full sm:w-full w-full mx-auto primary-heading-color font-normal font-family-300 text-2xl">
                    Based in Canada, with offices in central Europe, Latin America and select global locations, we bring highly qualified talent to our clients across Nort America.
                </p>
            </div>
            <CountryReasonCard position="left" image={WhyUSA} title={"Why USA"} description="North Macedonia is the birth place of some great people like Mother Teresa, Alexander the Great among others notable leaders and scholars. Lake Ohrid a UNESCO heritage site is located in the southwest of the country and is know for its ecosystem and is a major tourist attraction year-round. You don’t need to be know Macedonian in order to hire Tech Talent for your organization." />
            <CountryReasonCard position="right" image={USAHiring} title={"Simplifying Hiring in USA"} description="Opening an office in the Balkan countries is a lengthy process and usually takes 3-6 months minimum to incorporate a company. If your goal is to hire Tech Talent who speaks fluent English and can integrate into your organization then you don’t need to go through the hassle of starting your own office." />
            <CountryReasonCard position="left" image={USATeam} title={"Our Team"} description="Enthos Talent local team in North Macedonia can help your organization in recruiting, hiring, payroll processing, asset management, and all the tasks that need to performed to setup a remote team member." />
            <div className="main-container">
                <h2 className="text-center font-family-700 font-semibold xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10 primary-heading-color">
                    Services In USA
                </h2>
            </div>
            <ServicesCard />
            <div className="main-container">
                <div className="xl:py-20 lg:py-14 md:py-10 py-10">
                    <h2 className='primary-heading-color font-semibold text-center xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                        Countries, where we work
                    </h2>
                    <CountryList />
                </div>
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default USA