import HomeBanner from '../../components/HomeBanner'
import Header from '../../components/Header'
import CountryList from '../../components/CountryList'
import Footer from '../../components/Footer'
import CallToActionBanner from '../../components/CallToActionBanner'
import CountryReasonCard from '../../components/CountryReasonCard'
import Recruiting from '../../assets/images/recruiting.png'
import EmployeOfRecord from '../../assets/images/employe-of-record.png'
import BackgroundReferenceCheck from '../../assets/images/background-reference-check.png'
import AssetsManagement from '../../assets/images/assets-management.png'
import Vector from '../../assets/images/Vector.png'
const ServicePage = () => {
    return (
        <>
            <Header bgColor={false} />
            <HomeBanner />
            <div className="main-container">
                <div className="xl:py-20 lg:py-14 md:py-10 py-10">
                    <h1 className="text-center xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6  font-semibold xl:text-5xl lg:text-5xl font-family-700 primary-heading-color">
                        Work with us to access untapped tech talent.
                    </h1>
                    <p className='primary-heading-color font-family-300 font-normal xl:w-5/12 lg:w-6/12 md:w-full sm:w-full w-full mx-auto text-center text-2xl'>
                        Based in Canada, with offices in central Europe, Latin America and select global locations, we bring highly qualified talent to our clients across Nort America.
                    </p>
                </div>
            </div>
            <div className="relative service-grid pt-10 xl:pb-20 lg:pb-14 md:pb-10 sm:pb-10 pb-10" style={{ backgroundImage: `url(${Vector})` }}>
                <div className="main-container">
                    <h1 className="text-center xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6 font-semibold xl:text-5xl lg:text-5xl font-family-700 primary-heading-color">
                        Enthos Talent Advantages
                    </h1>
                    <p className='primary-heading-color xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10 font-family-300 font-normal w-full mx-auto text-center text-2xl'>
                        Work is expanding in a borderless direction. Work with us and postion your company for success.
                    </p>
                    <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 align-middle justify-center items-center xl:gap-6 lg:gap-5 md:gap-4 sm:gap-4 gap-10">

                        <div className="service-card bg-white py-10 xl:px-8 lg:px-6 md:px-6 sm:px-6 px-6 flex h-full flex-col ">
                            <h4 className='text-2xl font-family-500 font-semibold primary-heading-color xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6'>
                                No middle-term or third-party
                            </h4>
                            <p className='secondary-heading-color text-2xl font-family-500 font-normal mb-0'>
                                We’re based in Canada and our employees across global offices recruit set up, employ, and manage amazing teams for you.
                            </p>
                        </div>

                        <div className="service-card bg-white py-10 xl:px-8 lg:px-6 md:px-6 sm:px-6 px-6 flex h-full flex-col ">
                            <h4 className='text-2xl font-family-500 font-semibold primary-heading-color xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6'>
                                Our team work when you do.
                            </h4>
                            <p className='secondary-heading-color text-2xl font-family-500 font-normal mb-0'>
                                This Workforce accomodates time zones across north America. working within office hours to align with your teams.
                            </p>
                        </div>

                        <div className="service-card bg-white py-10 xl:px-8 lg:px-6 md:px-6 sm:px-6 px-6 flex h-full flex-col ">
                            <h4 className='text-2xl font-family-500 font-semibold primary-heading-color xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6'>
                                Meet the tech talent you never knew was out there.
                            </h4>
                            <p className='secondary-heading-color text-2xl font-family-500 font-normal mb-0'>
                                These teams are highly qualified and cager to work. Our global recruiting teams attract and retain the best and brightest in each region.
                            </p>
                        </div>

                        <div className="service-card bg-white py-10 xl:px-8 lg:px-6 md:px-6 sm:px-6 px-6 flex h-full flex-col ">
                            <h4 className='text-2xl font-family-500 font-semibold primary-heading-color xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6'>
                                Bring on the people you need easily.
                            </h4>
                            <p className='secondary-heading-color text-2xl font-family-500 font-normal mb-0'>
                                This isn’t just payrolling. We sourve talent, perform checks and references, handle compliance requirements and monitor our service end-to-end.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="main-container">
                <h1 className="text-center xl:mt-20 lg:mt-14 md:mt-10 sm:mt-10 mt-10 font-semibold xl:text-5xl lg:text-5xl font-family-700 primary-heading-color">
                    Enthos Talent Team bridges the Hiring gap
                </h1>
            </div>
            <CountryReasonCard position="right" image={Recruiting} title={"Recruiting"} description="Establishing a company, hiring an accounting firm, paying for a virtual or a physical space are all expenses that an organization does not need to incur when all you want is to hire a Tech Professional who will work remotely from the comfort of his/her home." />
            <CountryReasonCard position="left" image={EmployeOfRecord} title={"Employer of Record"} description="With Enthos Talent team you don’t have to worry about recruiting, hiring, payroll processing, asset management, and all the tasks that need to performed to setup a remote team member." />
            <CountryReasonCard position="right" image={BackgroundReferenceCheck} title={"Background and Reference Check"} description="Establishing a company, hiring an accounting firm, paying for a virtual or a physical space are all expenses that an organization does not need to incur when all you want is to hire a Tech Professional who will work remotely from the comfort of his/her home." />
            <CountryReasonCard position="left" image={AssetsManagement} title={"Asset Management"} description="Canada with its diverse population has not only become the center of integration for various cultures but is at the forefront of creating an environment of growth, prosperity and inclusiveness. This unique mix has also given Canada a unique position where it is able to accommodate and assimilate talent from all over the world and has created an ecosystem of growth and entrepreneurship. " />
            <div className="main-container">
                <div className="xl:pb-20 lg:pb-14 md:pb-10 pb-10">
                    <h2 className='primary-heading-color font-semibold text-center xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                        Countries, where we work
                    </h2>
                    <CountryList />
                </div>
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default ServicePage