import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FavCandidateListTable from '../../components/FavCandidateListTable'
import ProtectedRoute from '../../components/ProtectedRoute'

const FavCandidateList = () => {
    return (
        <>
            <ProtectedRoute>
                <Header bgColor={true} />
                <FavCandidateListTable />
                <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default FavCandidateList