import React, { Component, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Line } from 'rc-progress';
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { RxUpload } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import ProtectedRoute from '../../components/ProtectedRoute'

const MatchResume = () => {

    const [stepCounter, setStepCounter] = useState(1);
    const [requirementError, setRequirementsError] = useState("")
    const [requirements, setRequirements] = useState("")

    const validateRequirements = () => {
        if (requirements == "") {
            setRequirementsError("Short Description is Required")
        } else {
            setRequirementsError("")
        }
    }

    const navigate = useNavigate()

    return (
        <>
            <ProtectedRoute>
                <Header bgColor={true} />
                <div className='main-container'>
                    <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
                        <div className="flex align-middle justify-center flex-col items-center signup-w-3-12-5 mx-auto">
                            <div className="flex relative steps-row match justify-between w-full align-middle  mb-10 xl:items-center lg:items-center md:items-center overflow-hidden sm:items-center items-start">
                                <div
                                    disabled={stepCounter > 1}
                                    className="flex cursor-pointer align-middle first justify-start items-center flex-col "
                                >
                                    <span className="text-2xl bg-white secondary-heading-color secondary-border-color font-family-700 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full">
                                        1
                                    </span>
                                    <span className="text-xl font-family-700 font-medium text-center secondary-heading-color">
                                        Upload Resume
                                    </span>
                                </div>
                                <div
                                    // onClick={() => {
                                    //     setStepCounter(2)
                                    // }}
                                    className={`cursor-pointer ${stepCounter == 2
                                        ? "step-two"
                                        : stepCounter == 3
                                            ? "step-three"
                                            : ""
                                        } flex align-middle second justify-center items-center flex-col before-after relative`}
                                >
                                    <span
                                        className={`  text-2xl bg-white secondary-heading-color ${stepCounter == 1
                                            ? "steps-main-border-color"
                                            : "secondary-border-color"
                                            }  font-family-700 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full`}
                                    >
                                        2
                                    </span>
                                    <span className="text-xl font-family-700 font-medium text-center secondary-heading-color">
                                        Add Job
                                    </span>
                                </div>
                                <div
                                    // onClick={() => {
                                    //     setStepCounter(3)
                                    // }}
                                    className="flex  cursor-pointer align-middle third justify-end items-center flex-col"
                                >
                                    <span
                                        className={` text-2xl bg-white secondary-heading-color ${stepCounter == 1 || stepCounter == 2
                                            ? "steps-main-border-color"
                                            : "secondary-border-color"
                                            } font-family-700 font-medium w-14 h-14 mb-3 flex steps align-middle justify-center items-center rounded-full`}
                                    >
                                        3
                                    </span>
                                    <span className="text-xl font-family-700 font-medium text-center secondary-heading-color">
                                        View Results
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            stepCounter == 1
                                ?
                                <div className='flex align-middle mx-auto justify-center xl:w-max lg:w-max md:w-full w-full flex-col gap-8'>
                                    <div className='uploader relative  rounded-3xl border border-gray-400  text-center'>
                                        <input type="file"
                                            className="form-control z-10 cursor-pointer relative p-10 opacity-0 font-family-500 font-normal block w-full  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        />
                                        <div className="flex absolute top-0 left-0 w-full h-full align-middle justify-center px-8 py-2 items-center">
                                            <RxUpload className=' text-xl mr-2 secondary-secondary-heading-color font-medium font-family-500' width="20px" height="20px" />
                                            <span className={`form-control cursor-pointer font-family-500 secondary-heading-color font-normal block w-max xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}>
                                                Upload Resume
                                            </span>
                                        </div>
                                    </div>
                                    <div className='relative border border-gray-400 w-9/12 mx-auto'>
                                        <span className='absolute top-1/2 left-1/2 font-medium  px-3 py-1 bg-white text-base secondary-heading-color font-family-500' style={{ transform: "translate(-50%,-50%)" }}>
                                            Or
                                        </span>
                                    </div>
                                    <div className='uploader relative  rounded-3xl border border-gray-400  text-center'>
                                        <input type="file"
                                            className="form-control z-10 cursor-pointer relative p-10 opacity-0 font-family-500 font-normal block w-full  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color bg-white bg-clip-padding rounded-full transition ease-in-out focus:bg-white focus:border-gray-300 focus:outline-none"
                                        />
                                        <div className="flex absolute top-0 left-0 w-full h-full align-middle justify-center px-8 py-2 items-center">
                                            <span className={`form-control cursor-pointer font-family-500 secondary-heading-color font-normal block w-max xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}>
                                                Choose from uploaded Resumes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                stepCounter == 2 ?
                                    <div className='mx-auto  w-full '>
                                        <div className="block  border-2 border-gray-300 xl:w-1/3 lg:w-1/3 md:w-full w-full  mx-auto p-2 relative flex-col mb-6 align-middle justify-between items-center  ">
                                            <div className="relative w-full">
                                                <span className='text-xl block font-family-500 border-b-2 pb-1 border-gary-400 secondary-heading-color mb-3 font-medium'>
                                                    Paste a Job Description bellow
                                                </span>
                                                <textarea
                                                    value={requirements}
                                                    onChange={(e) => {
                                                        setRequirements(e.target.value)
                                                        setRequirementsError("")
                                                    }}
                                                    onBlur={validateRequirements}
                                                    id="requirements"
                                                    placeholder={"Job Description..."}
                                                    className={`form-control ${requirementError != "" ? "text-red-500 placeholder:text-red-500 " : "secondary-heading-color "} h-32 font-family-500 font-normal block w-full px-2 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl bg-white bg-clip-padding   rounded-3xl-5 transition ease-in-out focus:bg-white  focus:outline-none`}
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="flex align-middle justify-center flex-col items-center w-max mx-auto">
                                            <button
                                                className='primary-bg-color w-full rounded-full disabled:opacity-80 disabled:cursor-not-allowed px-20 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                                                Scan
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    stepCounter == 3 ?
                                        <div className='flex align-middle  justify-center h-full items-center'>
                                            <div className='flex p-8 border-2 border-gray-300 rounded-lg'>
                                                <div className='flex align-middle xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col justify-between gap-10 items-center'>
                                                    <div className='w-2/5'>
                                                        <CircularProgressbar value="63" text="63%" />
                                                    </div>
                                                    <ul className='block w-full'>
                                                        <li className='flex w-full align-middle justify-start items-start flex-col my-3'>
                                                            <span className='secondary-heading-color font-family-500 font-semibold text-sm'>
                                                                ATS FINDINGS
                                                            </span>
                                                            <span className='flex w-full align-middle justify-start items-center gap-2'>
                                                                <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#589DAD" />
                                                            </span>
                                                        </li>
                                                        <li className='flex w-full align-middle justify-start items-start flex-col my-3'>
                                                            <span className='secondary-heading-color font-family-500 font-semibold text-sm'>
                                                                RECRUITING FINDINGS
                                                            </span>
                                                            <span className='flex w-full align-middle justify-start items-center gap-2'>
                                                                <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#589DAD" />
                                                            </span>
                                                        </li>
                                                        <li className='flex w-full align-middle justify-start items-start flex-col my-3'>
                                                            <span className='secondary-heading-color font-family-500 font-semibold text-sm'>
                                                                SKILLS MATCH
                                                            </span>
                                                            <span className='flex w-full align-middle justify-start items-center gap-2'>
                                                                <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#589DAD" />
                                                            </span>
                                                        </li>
                                                        <li className='flex w-full align-middle justify-start items-start flex-col my-3'>
                                                            <span className='secondary-heading-color font-family-500 font-semibold text-sm'>
                                                                PREMIUM FORMAT CHECKS
                                                            </span>
                                                            <span className='flex w-full align-middle justify-start items-center gap-2'>
                                                                <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#589DAD" />
                                                            </span>
                                                        </li>
                                                        <li className='flex w-full align-middle justify-start items-start flex-col my-3'>
                                                            <span className='secondary-heading-color font-family-500 font-semibold text-sm'>
                                                                COVER LETTER CHECKS
                                                            </span>
                                                            <span className='flex w-full align-middle justify-start items-center gap-2'>
                                                                <Line className='w-full' percent={75} strokeWidth={4} strokeColor="#589DAD" />
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                        }
                    </div>
                </div>
                <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default MatchResume