import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LandingPage from '../pages/LandingPage'
import ServicePage from '../pages/ServicePage'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import EmployLogin from '../pages/Login/employerLogin'
import CandidateLogin from '../pages/Login/CandidateLogin'
import CandidateSignup from '../pages/Signup/CandidateSignup'
import ForgotPass from '../pages/ForgotPass'
import EmployeeSignup from '../pages/Signup/employeeSignup'
import ChangePassword from '../pages/Profile/ChangePassword'
import Profile from '../pages/Profile'
import Canada from '../pages/Countries/canada'
import USA from '../pages/Countries/usa'
import Pakistan from '../pages/Countries/pakistan'
import NorthMacedonia from '../pages/Countries/north-macedonia'
import Albania from '../pages/Countries/Albania'
import Bosnia from '../pages/Countries/Bosnia'
import Brazil from '../pages/Countries/Brazil'
import PostAJob from '../pages/PostAJob'
import EmployerDashboard from '../pages/EmployerDashboard'
import CandidateDashboard from '../pages/CandidateDashboard'
import CompanyInformtion from '../pages/CompanyInformation'
import ConctactUs from '../pages/ContactUs'
import ResetPassword from '../pages/ResetPassword'
import CandidateList from '../pages/EmployerDashboard/CandidateList'
import FavCandidateList from '../pages/EmployerDashboard/FavCandidateList'
import FindJob from '../pages/FindJob'
import CandidateCart from '../pages/CandidateCart'
import ThankYou from '../pages/ThankYou'
import MatchResume from '../pages/MatchResume'
import SendTest from '../pages/EmployerDashboard/SendTest'
import ScheduleShortCall from '../pages/EmployerDashboard/ScheduleShortCall'
import CandidateJourney from '../pages/EmployerDashboard/CandidateJourneyPage'


export default function index() {

    return (
        <div className="main-div">
            <BrowserRouter>
                <Routes>

                    <Route path="/" element={<LandingPage />} />
                    <Route path="/candidateOrEmployer" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/employer-login" element={<EmployLogin />} />
                    <Route path="/candidate-login" element={<CandidateLogin />} />
                    <Route path="/candidate-signup" element={<CandidateSignup />} />
                    <Route path="/employer-signup" element={<EmployeeSignup />} />
                    <Route path="/forgot-pass" element={<ForgotPass />} />
                    <Route path="/reset_password" element={<ResetPassword />} />
                    <Route path="/profile/change-password" element={<ChangePassword />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/canada" element={<Canada />} />
                    <Route path="/unitedstates" element={<USA />} />
                    <Route path="/pakistan" element={<Pakistan />} />
                    <Route path="/macedonia" element={<NorthMacedonia />} />
                    <Route path="/albania" element={<Albania />} />
                    <Route path="/bosnia" element={<Bosnia />} />
                    <Route path="/brazil" element={<Brazil />} />
                    <Route path="/brazil" element={<Brazil />} />
                    <Route path="/employer/postajob" element={<PostAJob />} />
                    <Route path="/employer/sendtest" element={<SendTest />} />
                    <Route path="/employer/schedule-short-call" element={<ScheduleShortCall />} />
                    <Route path="/employer/candidate-journey" element={<CandidateJourney />} />
                    <Route path="/employer/offer-job" element={<CandidateJourney />} />
                    <Route path="/employer-dashboard" element={<EmployerDashboard />} />
                    <Route path="/candidate-dashboard" element={<CandidateDashboard />} />
                    <Route path="/company-info/:company_id" element={<CompanyInformtion />} />
                    <Route path="/contact-us" element={<ConctactUs />} />
                    <Route path="/candidate-list" element={<CandidateList />} />
                    <Route path="/fav-candidate-list" element={<FavCandidateList />} />
                    <Route path="/find-job" element={<FindJob />} />
                    <Route path="/find-job/:job_id" element={<FindJob />} />
                    <Route path="/services" element={<ServicePage />} />
                    <Route path="/cart" element={<CandidateCart />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                    <Route path="/match-your-resume" element={<MatchResume />} />


                    {/*<Route path="/find-talent" element={<FindTalentSignup />} />
                    <Route path="/match-resume-step-one" element={<MatchResumeStepOne />} /> */}

                </Routes>
            </BrowserRouter>
        </div>
    )
}
