import React, { Component, useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CandidateProfileSection from './CandidateProfileSection'
import EmployerProfileSection from './EmployerProfileSection'
import axios from '../api/axios';
import ProtectedRoute from './ProtectedRoute'

const ProfileSection = () => {

    // const GET_API_URL = 'get_user'
    const GET_API_URL = 'candidate'

    const [userRole, setUserRole] = useState()
    const [userInformation, setUserInformation] = useState([])
    const navigate = useNavigate()
    const userData = localStorage.getItem("userInfo")
    const user = JSON.parse(userData)
    useEffect(() => {
        setUserRole(user)
    }, [])

    useEffect(() => {
        console.log('userInformation ', userInformation)
    }, [userInformation])

    const fetchUserInformation = async () => {
        if (userRole) {
            try {
                let post_data = {
                    "token": userRole?.token,
                }

                const response = await axios.get(
                    GET_API_URL,
                    {
                        params: {
                            token: post_data.token
                        }
                    },
                    // JSON.stringify(post_data),
                    {
                        headers: {
                            "Content-Type": "application/json",
                            // "token": post_data.token,
                            // 'Authorization': `token ${post_data.token}`
                        },
                    }
                )
                setUserInformation(response?.data)
                // const obj = {
                //     userRole: "1",
                //     token: response.data.token
                // }
                // localStorage.setItem('userInfo', JSON.stringify(obj))
                // navigate('/profile')
            } catch (err) {
                console.log('error ', err.response.data.message)
                // setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
        }
    }


    useEffect(() => {
        if (userRole) {
            fetchUserInformation()
        }
    }, [userRole])

    return (
        <ProtectedRoute >
            <div className="xl:py-20 lg:py-14 md:py-10 sm:py-10 py-10">
                <div className="main-container">
                    {
                        userRole?.userRole === "1" ?
                            <CandidateProfileSection userRole={userRole} userInformation={userInformation} />
                            :
                            <EmployerProfileSection userRole={userRole} userInformation={userInformation} />
                    }
                </div>
            </div>
        </ProtectedRoute>
    )
}

export default ProfileSection