import React, { Component, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from '../api/axios';
const FindAJobBanner = ({ setJobListsAfterSearch }) => {

    const GET_JOB_TYPES = "/jobtypes";
    const COUNTRIES_API_URL = "/countries";
    const SEARCH_API = "search"
    const GET_STATE_BY_COUNTRY_ID = "country"

    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(new Date());
    const [jobType, setJobType] = useState("")
    const [jobLocation, setJobLocation] = useState("")
    const [searchText, setSearchText] = useState("")
    const [jobTypeList, setJobTypeList] = useState([])
    const [countriesList, setCountriesList] = useState([])
    const [statesList, setStateList] = useState([])
    const [citiesList, setCitiesList] = useState([])
    const [selectedState, setSelectedState] = useState("")
    const [selectedCity, setSelectedCity] = useState("")

    const [jobTypeError, setJobTypeError] = useState("")
    const [jobLocationError, setJobLocationError] = useState("")
    const [jobStateError, setJobStateError] = useState("")
    const [jobCityError, setJobCityError] = useState("")
    const [searchTextError, setSearchTextError] = useState("")
    const [loader, setLoader] = useState(false)

    const validateJobType = (e) => {
        if (e == "Job Type" || e == "") {
            setJobTypeError("Please select a valid Job Type")
        } else {
            setJobTypeError("")
        }
    }

    const validateJobLocation = (e) => {
        if (e == "Job Location" || e == "") {
            setJobLocationError("Please select a valid Job Location")
        } else {
            setJobLocationError("")
        }
    }

    const validateJobState = (e) => {
        if (e == "Job State" || e == "") {
            setJobStateError("Please select a valid State")
        } else {
            setJobStateError("")
        }
    }

    const validateJobCity = (e) => {
        if (e == "Job City" || e == "") {
            setJobCityError("Please select a valid State")
        } else {
            setJobCityError("")
        }
    }

    const validateSearchText = () => {
        if (searchText == "") {
            setSearchTextError("Please Enter Search Text")
        } else {
            setSearchTextError("")
        }
    }


    const fetchJobTypes = async () => {
        try {
            const response = await axios.get(GET_JOB_TYPES);
            // console.log('response.data ', response.data)
            // setTagsList(response.data)
            setJobTypeList(response?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCountriesList(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    const clearFilters = () => {
        setSearchText("")
        setJobLocation("Job Location")
        setJobType("Job Type")
        setSelectedState("Job State")
        setSelectedCity("Job City")

    }

    useEffect(() => {
        fetchJobTypes()
        fetchCountriesData()
    }, [])


    const fetchSelectedCountryStates = async (e) => {
        const selectedCountryId = countriesList?.filter((item) => item?.id == e.target.value)
        if (selectedCountryId?.length > 0) {
            try {
                const response = await axios.post(GET_STATE_BY_COUNTRY_ID, {
                    country_id: selectedCountryId[0]?.id
                });
                console.log('response?.data ', response?.data)
                setStateList(response?.data?.data[0]?.states)
                setCitiesList(response?.data?.data[0]?.cities)
                // setCountriesList(response.data)
            }
            catch (error) {
                console.log(error);
            }
        }
    }


    const searchJob = async () => {
        setLoader(true)
        console.log('searchTextError ', searchTextError)
        console.log('jobTypeError ', jobTypeError)
        console.log('jobLocationError ', jobLocationError)
        console.log('jobStateError ', jobStateError)
        console.log('jobCityError ', jobCityError)
        try {
            const response = await axios.post(SEARCH_API,
                {
                    keyword: searchText != "" ? searchText : "",
                    created_date: startDate,
                    job_type: jobType != "Job Type" ? jobType : "",
                    country_id: jobLocation != "Job Location" ? jobLocation : "",
                    state_id: selectedState != "Job State" ? selectedState : "",
                    city_id: selectedCity != "Job City" ? selectedCity : ""
                }
            );
            console.log('response?.data ', response?.data?.data)
            clearFilters()
            setJobListsAfterSearch(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }

        setTimeout(() => {
            setLoader(false)
        }, 4000)
    }


    return (
        <div className="flex-col login-banner xl:py-28 lg:py-24 md:py-20 sm:py-14 py-12  flex xl:justify-start lg:justify-start md:justify-start sm:justify-center justify-center align-middle " style={{ background: "linear-gradient(97.92deg, #589DAD 1.23%, #6BADBC 67.32%, #7CB3BF 96.95%)" }}>
            <div className="main-container">
                <div className="flex flex-col xl:pt-14 xl:pb-20  lg:py-10 md:py-10 sm:py-10 py-6 w-full align-middle justify-center ">
                    <h3 className='text-center font-family-500 font-medium mb-6 text-white '>
                        Start your search
                    </h3>
                    <h1 className='font-family-700 font-medium text-center text-white ' style={{ fontSize: "72px" }}>
                        Find your dream job
                    </h1>
                </div>
                <div className="grid xl:gap-y-10 xl:gap-x-14 lg:gap-5 md:gap-5 sm:gap-5 gap-5 align-middle justify-center xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.7449 23.271L18.582 17.0827C20.1666 15.2641 21.0348 12.9759 21.0348 10.5937C21.0348 5.02814 16.3446 0.5 10.5799 0.5C4.81515 0.5 0.125 5.02814 0.125 10.5937C0.125 16.1593 4.81515 20.6875 10.5799 20.6875C12.744 20.6875 14.8064 20.0573 16.5696 18.861L22.7794 25.0963C23.0389 25.3565 23.388 25.5 23.7621 25.5C24.1162 25.5 24.4522 25.3697 24.7072 25.1327C25.249 24.6293 25.2663 23.7946 24.7449 23.271ZM10.5799 3.13315C14.8409 3.13315 18.3074 6.47988 18.3074 10.5937C18.3074 14.7076 14.8409 18.0543 10.5799 18.0543C6.31884 18.0543 2.85236 14.7076 2.85236 10.5937C2.85236 6.47988 6.31884 3.13315 10.5799 3.13315Z" fill="#4F4F4F" />
                            </svg>
                            {/* <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg> */}
                        </div>
                        <input
                            onBlur={validateSearchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                            }}
                            value={searchText}
                            type="search"
                            // className="focus:border-gray-300 hover:border-gary-400 outline-none rounded-full xl:h-12 lg:h-10 md:h-10 sm:h-10 h-10 flex align-middle justify-center items-center w-full border font-family-700 font-medium secondary-heading-color border-gray-400 px-12"
                            className={`form-control font-family-500 secondary-heading-color font-normal block w-full px-12 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl ${searchTextError != "" ? "border-red-500 text-red-500 focus:border-red-500 placeholder:text-red-500" : "border-gray-300 seceondary-heading-color  focus:border-gray-300 "} bg-white bg-clip-padding border border-solid rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            placeholder="Key words, company, skills..."
                        />
                        {
                            searchTextError ?
                                <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    <div className="relative w-full">
                        <DatePicker
                            placeholderText='Date'
                            className="focus:border-gray-300 cursor-pointer hover:border-gary-400 outline-none rounded-full xl:h-12 lg:h-10 md:h-10 sm:h-10 h-10 flex align-middle justify-center items-center w-full border font-family-700 font-medium secondary-heading-color border-gray-400 px-12"
                            selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="relative w-full">
                        <select
                            value={jobType}
                            onChange={(e) => {
                                // validateCountry(e)
                                if (e.target.value != "Job Type") {
                                    setJobType(e.target.value)
                                    setJobTypeError("")
                                } else {
                                    setJobType(e.target.value)
                                    setJobTypeError("Please select a valid Job Type")
                                }
                            }}
                            onBlur={(e) => { validateJobType(jobType) }}
                            id="jobType"
                            className={`bg-white border font-family-500 font-normal ${jobTypeError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                        >
                            <option
                                className="secondary-heading-color"
                                selected
                                value="Job Type"
                            >
                                Job Type
                            </option>
                            {
                                jobTypeList?.map((item, index) => {
                                    return (
                                        <option className="secondary-heading-color" key={item?.id} value={item?.title}>
                                            {item?.title}
                                        </option>
                                    )
                                })
                            }

                        </select>
                        {
                            jobTypeError ?
                                <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    <div className="relative w-full">
                        <select
                            value={jobLocation}
                            onChange={(e) => {
                                // validateCountry(e)
                                if (e.target.value != "Job Location") {
                                    setJobLocation(e.target.value)
                                    setJobLocationError("")
                                    fetchSelectedCountryStates(e)
                                } else {
                                    setJobLocation(e.target.value)
                                    setJobLocationError("Please select a valid Job Location")
                                }
                            }}
                            onBlur={(e) => { validateJobLocation(jobLocation) }}
                            id="jobLocation"
                            // className={`bg-white border font-family-500 font-normal ${jobTypeError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                            className={`bg-white border font-family-500 font-normal ${jobLocationError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                        >
                            <option
                                className="secondary-heading-color"
                                selected
                                value="Job Location"
                            >
                                Job Location
                            </option>
                            {
                                countriesList?.map((item, index) => {
                                    return (
                                        <option className="secondary-heading-color" key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        {
                            jobLocationError ?
                                <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        statesList?.length > 0
                            ?
                            <div className="relative w-full">
                                <select
                                    value={selectedState}
                                    onChange={(e) => {
                                        if (e.target.value != "Job State") {
                                            setSelectedState(e.target.value)
                                            setJobStateError("")
                                        } else {
                                            setSelectedState(e.target.value)
                                            setJobStateError("Please select a valid Job State")
                                        }
                                    }}
                                    onBlur={(e) => { validateJobState(selectedState) }}
                                    id="jobState"
                                    // className={`bg-white border font-family-500 font-normal ${jobTypeError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                                    className={`bg-white border font-family-500 font-normal ${jobStateError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                                >
                                    <option
                                        className="secondary-heading-color"
                                        selected
                                        value="Job State"
                                    >
                                        State / Province
                                    </option>
                                    {
                                        statesList?.map((item, index) => {
                                            return (
                                                <option className="secondary-heading-color" key={item?.id} value={item?.id}>
                                                    {item?.state}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    jobStateError ?
                                        <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                        </svg>
                                        : ""
                                }
                            </div>
                            :
                            null
                    }
                    {
                        citiesList?.length > 0
                            ?
                            <div className="relative w-full">
                                <select
                                    value={selectedCity}
                                    onChange={(e) => {
                                        // validateCountry(e)
                                        if (e.target.value != "Job City") {
                                            setSelectedCity(e.target.value)
                                            setJobCityError("")
                                        } else {
                                            setSelectedCity(e.target.value)
                                            setJobCityError("Please select a valid Job City")
                                        }
                                    }}
                                    onBlur={(e) => { validateJobCity(selectedCity) }}
                                    id="jobCity"
                                    // className={`bg-white border font-family-500 font-normal ${jobTypeError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                                    className={`bg-white border font-family-500 font-normal ${jobCityError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 focus:ring-gray-300 active:ring-gray-300 focus-within:ring-gray-300 focus-within:border-gray-300  active:border-gray-300 focus-visible:ring-gray-300 focus-visible:border-gray-300 focus:border-gray-300 secondary-heading-color "} outline-none rounded-full block w-full  px-8 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl`}
                                >
                                    <option
                                        className="secondary-heading-color"
                                        selected
                                        value="Job City"
                                    >
                                        City
                                    </option>
                                    {
                                        citiesList?.map((item, index) => {
                                            return (
                                                <option className="secondary-heading-color" key={item?.id} value={item?.id}>
                                                    {item?.city}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    jobCityError ?
                                        <svg className='absolute top-1/2' style={{ right: "10%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                        </svg>
                                        : ""
                                }
                            </div>
                            :
                            null
                    }
                </div>
                <div className="flex pt-5 align-middle xl:justify-end lg:justify-end md:justify-end sm:justify-end justify-center items-center xl:flex-row lg:flex-row md:flex-row sm:flex-row flex-col gap-5">
                    <a onClick={
                        loader ? null :
                            searchJob
                    } className={`text-white xl:w-max lg:w-max ${loader ? "cursor-not-allowed opacity-60" : "cursor-pointer opacity-100"} sm:w-max md:w-max w-full rounded-full px-11 py-2 text-center primary-bg-color font-semibold font-family-700 cursor-pointer xl:text-xl lg:text-xl md:text-base text-base`}>
                        Find Job
                    </a>
                    <a onClick={() => {
                        clearFilters()
                    }} className='secondary-heading-color xl:w-max lg:w-max sm:w-max md:w-max w-full text-center bg-white rounded-full px-11 py-2 font-semibold font-family-700 cursor-pointer xl:text-xl lg:text-xl md:text-base text-base'>
                        Clear filters
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FindAJobBanner