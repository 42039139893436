import React, { Component, useEffect, useState } from 'react'
import axios from '../../api/axios'
import CallToActionBanner from "../../components/CallToActionBanner"
import DashboardFilters from "../../components/DashboardFilters"
import DashboardHeader from "../../components/DashboardHeader"
import Footer from "../../components/Footer"
import ProtectedRoute from "../../components/ProtectedRoute"
import CandidateDashboardCounter from '../../components/CandidateDashboardCouter';
import CandidateDashboardRecommendedJobs from '../../components/CandidateDashboardRecommendedJobs'
import RecommendedJobs from '../../components/RecommendedJobs'
import CandidateScheduledActivities from '../../components/CandidateScheduledActivities'
const CandidateDashboard = () => {

    {
        /**
            Purpose: The purpose of this program is to show candidate dashboard information
            Author:   Arslan Mukhtar
            Date Created:    2022-12-19
            Last Date Modified:  2022-12-21
            Modified By: Arslan Mukhtar
        */
    }

    const JOBSLIST = "alljobs"
    const GET_API_URL = 'candidate'
    const JOBS_APPLIED = "jobs_applied_count"
    const CART_API_URL = '/carts'


    const [jobList, setJobList] = useState([])
    const [jobsAppliedFor, setJobsAppliedFor] = useState([])
    const [appliedJobs, setAppliedJobs] = useState([])
    const [jobsAppliedCount, setJobsAppliedCount] = useState()
    const localUser = localStorage.getItem('userInfo')
    const [cartJobs, setCartJobs] = useState([])
    const [reloadPage, setReloadPage] = useState(false)
    const userInfo = JSON.parse(localUser)

    const fetchJobList = async () => {
        try {
            const response = await axios.get(JOBSLIST,
                {
                    params: {
                        token: userInfo?.token
                    }
                },
            );
            setJobList(response.data?.reverse())
        }
        catch (error) {
            console.log(error);
        }
    }


    const fetchJobsApplied = async () => {
        const userData = localStorage.getItem("userInfo")
        const user = JSON.parse(userData)
        const response = await axios.get(
            GET_API_URL,
            {
                params: {
                    token: user?.token
                }
            },
            // JSON.stringify(post_data),
            {
                headers: {
                    "Content-Type": "application/json",
                    // "token": post_data.token,
                    // 'Authorization': `token ${post_data.token}`
                },
            }
        )
        try {
            let post_data = {
                "user_id": response?.data?.user?.id,
                "token": user?.token
            }
            const jobsApplied = await axios.post(
                JOBS_APPLIED,
                JSON.stringify(post_data),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            console.log('jobsApplied ', jobsApplied?.data)
            setJobsAppliedCount(jobsApplied?.data?.count)
            setJobsAppliedFor(jobsApplied?.data?.jobs_applied)
            if (!jobsApplied.data.error) {
                // setApiResponseError(favCandidate?.data?.msg)
            } else {
                // setApiResponseError(favCandidate?.data?.msg)
            }
        } catch (err) {
            console.log('error ', err)
            // setApiResponseError(err)
            //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
        }

    }

    const filterJobsAppliedFor = (jobs, jobsApplied) => {
        const fArray = []
        for (let x = 0; x < jobsApplied?.length; x++) {
            const filtered = jobs?.filter((item) => item?.id == jobsApplied[x]?.job_id)
            const obj = filtered[0]
            obj.applied_date = jobsApplied[x]?.created_at
            fArray.push(obj)
        }
        setAppliedJobs(fArray)
    }

    useEffect(() => {
        fetchJobList()
        fetchJobsApplied()
    }, [])

    useEffect(() => {
        if (jobList?.length > 0) {
            filterJobsAppliedFor(jobList, jobsAppliedFor)
        }
    }, [jobList, jobsAppliedFor])

    const refetchJobsApplied = () => {
        fetchJobsApplied()
    }
    const refetchJobsForHeader = (props) => {
        console.log('props ', props)
        setReloadPage(props)
    }


    const fetchCartJobs = async () => {
        try {
            const response = await axios.get(CART_API_URL, {
                params: {
                    token: userInfo?.token
                }
            });
            setCartJobs(response.data)
        }
        catch (error) {
            console.log(error);
        }
        setReloadPage(false)
    }

    useEffect(() => {
        console.log('reload Page ', reloadPage)
        if (reloadPage) {
            fetchCartJobs()
        }
    }, [reloadPage])

    return (
        <ProtectedRoute >
            <DashboardHeader countFromParent={cartJobs?.length} bgColor={true} />
            <CandidateDashboardCounter jobsAppliedCount={jobsAppliedCount} />
            <div className="main-container">
                <div className="block mb-8 align-middle justify-between">
                    <h3 className='font-family-500 py-3  font-semibold text-3xl primary-heading-color'>Job Offers</h3>
                    <hr className=" bg-white border-t-2 xl:w-1/4 lg:w-1/4 md:w-2/5 sm:w-2/5 w-full border-gray-300 my-0 block" />
                </div>
                {
                    jobList?.length > 0 ?
                        jobList?.map((item, index) => {
                            if (index < 3) {

                                return (
                                    <>
                                        <CandidateDashboardRecommendedJobs key={index} activity="offers" job={item} bgColored={false} />
                                        <hr key={index} className='border mt-5 mb-5' />
                                    </>
                                )
                            }
                        })
                        :
                        null
                }
                {/* <a className='flex align-middle xl:my-20 lg:my-14 md:my-10 sm:my-10 my-10 justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                    <button className='text-white text-center text-xl font-family-500 font-medium'>
                        See more
                    </button>
                </a> */}

                <div className='block'>
                    <h3 className='font-family-500 py-3  font-semibold text-3xl primary-heading-color'>Scheduled Activities</h3>
                    <hr className=" bg-white border-t-2 xl:w-1/4 lg:w-1/4 md:w-2/5 sm:w-2/5 w-full border-gray-300 my-0 block" />
                </div>
                {
                    jobList?.length > 0 ?
                        jobList?.map((item, index) => {
                            if (index == 0) {
                                return (
                                    <>
                                        <CandidateScheduledActivities key={index} activity="call" job={item} />
                                    </>
                                )
                            } else if (index == 1) {
                                return (
                                    <>
                                        <CandidateScheduledActivities key={index} activity="test" job={item} />
                                    </>
                                )
                            } else if (index == 2) {
                                return (
                                    <>
                                        <CandidateScheduledActivities key={index} activity="interview" job={item} />
                                    </>
                                )
                            }
                        })
                        :
                        null
                    // <RecommendedJobs />                    
                }
                <div className="block mb-8 align-middle justify-between">
                    <h3 className='font-family-500 py-3  font-semibold text-3xl primary-heading-color'>Jobs Applied</h3>
                    <hr className=" bg-white border-t-2 xl:w-1/4 lg:w-1/4 md:w-2/5 sm:w-2/5 w-full border-gray-300 my-0 block" />
                </div>
                {
                    appliedJobs?.length > 0 ?
                        appliedJobs?.map((item, index) => {
                            if (index == (appliedJobs.length - 1)) {
                                return (
                                    <>
                                        <CandidateDashboardRecommendedJobs refetchJobsApplied={refetchJobsApplied} key={index} activity="applied" job={item} bgColored={false} />
                                        <hr key={index} className=' border-none mt-5 mb-5' />
                                    </>
                                )
                            } {
                                return (
                                    <>
                                        <CandidateDashboardRecommendedJobs refetchJobsApplied={refetchJobsApplied} key={index} activity="applied" job={item} bgColored={false} />
                                        <hr key={index} className='border mt-5 mb-5' />
                                    </>
                                )
                            }
                        })
                        :
                        null
                }
                <div className='mb-10'>
                    <div className="block mb-8 align-middle justify-between">
                        <h3 className='font-family-500 py-3  font-semibold text-3xl primary-heading-color'>Recommended Jobs</h3>
                        <hr className=" bg-white border-t-2 xl:w-1/4 lg:w-1/4 md:w-2/5 sm:w-2/5 w-full border-gray-300 my-0 block" />
                    </div>
                    {
                        jobList?.length > 0 ?
                            jobList?.map((item, index) => {
                                return (
                                    <>
                                        <RecommendedJobs refetchJobsForHeader={refetchJobsForHeader} key={index} route="dashboard" job={item} bgColored={false} />
                                        <hr key={index} className='border mt-5 mb-5' />
                                    </>
                                )
                            })
                            :
                            null
                        // <RecommendedJobs />                    
                    }
                </div>
                {/* <a className='flex align-middle xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10 justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                    <button className='text-white text-center text-xl font-family-500 font-medium'>
                        See more
                    </button>
                </a> */}
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </ProtectedRoute>
    )
}

export default CandidateDashboard