import { initializeApp } from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signOut,
    signInWithPopup,
    signInWithCustomToken,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"

const FirebaseCredentials = {
    apiKey: "AIzaSyA34-KWyBKhubTV8TR2b0bI2esfKSnqopk",
    authDomain: "enthos-4a204.firebaseapp.com",
    databaseURL: "https://enthos-4a204.firebaseio.com",
    projectId: "enthos-4a204",
    storageBucket: "enthos-4a204.appspot.com",
    messagingSenderId: 71014313710,
};

// if a Firebase instance doesn't exist, create one
const firebaseApp = initializeApp(FirebaseCredentials);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage()

export {
    auth,
    FirebaseCredentials,
    createUserWithEmailAndPassword,
    signInWithCustomToken,
    database,
    googleProvider,
    getDownloadURL,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    storage,
    signInWithPopup,
    signInWithEmailAndPassword,
    ref,
    signOut,
    uploadBytes
}; 