import React, { Component } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import ProtectedRoute from '../../components/ProtectedRoute'

const ResetPassword = () => {
    return (
        <>
            <ProtectedRoute >
                <Header bgColor={false} />
                <ResetPasswordForm />
                <CallToActionBanner bgColored={false} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#143975" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default ResetPassword