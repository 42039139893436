import React, { Component, useEffect, useState } from 'react'
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DashboardFilters = () => {
    const navigate = useNavigate()

    const COUNTRIES_API_URL = "/countries";
    const [countries, setCouuntries] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const fetchCountriesData = async () => {
        try {
            const response = await axios.get(COUNTRIES_API_URL);
            setCouuntries(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCountriesData()
    }, [])


    return (
        <ul className='flex gap-3 my-5 align-middle xl:justify-between lg:justify-between md:justify-start sm:justify-start justify-start items-center xl:flex-nowrap lg:flex-nowrap md:flex-wrap sm:flex-wrap flex-wrap'>
            <li className='relative'>
                <select id="jobTitle"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>Job Title</option>
                    {/* {
                            countriesList?.map((item, index) => {
                                return (
                                    <option value={item.id} key={index}>{item.name}</option>
                                )
                            })
                        } */}
                </select>
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
            <li className='relative'>
                <select id="jobStatus"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>Job Status</option>
                    <option value="active" key="1">
                        Active
                    </option>
                    <option value="disabled" key="2">
                        Disabled
                    </option>
                </select>
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
            <li className='relative'>
                <DatePicker
                    className="bg-gray-200 w-full cursor-pointer border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color"
                    selected={startDate} onChange={(date) => setStartDate(date)} />
                {/* <select id="datePosted"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>Date Posted</option>
                </select> */}
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
            <li className='relative'>
                <DatePicker
                    className="bg-gray-200 w-full cursor-pointer border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color"
                    selected={endDate} onChange={(date) => setEndDate(date)} />
                {/* <select id="expiryDate"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>ExpiryDate</option>
                </select> */}
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
            <li className='relative'>
                <select id="applicationCount"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>Application Status</option>

                </select>
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
            <li className='relative'>
                <select id="newApplications"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>New Applicants</option>
                </select>
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
            <li className='relative'>
                <select id="Location"
                    className="bg-gray-200 w-full border font-family-500 font-normal appearance-none border-gray-200 outline-none rounded-full focus:ring-gray-200 active:ring-gray-200 focus-within:ring-gray-200 focus-within:border-gray-200  active:border-gray-200 focus-visible:ring-gray-200 focus-visible:border-gray-200 focus:border-gray-200 block pl-5 pr-9 py-2 h-11 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl secondary-heading-color">
                    <option selected>Location</option>
                    {
                        countries?.map((item, index) => {
                            return (
                                <option key={index} value={item?.name} >
                                    {
                                        item?.name
                                    }
                                </option>
                            )
                        })
                    }
                </select>
                <svg className='absolute top-1/2 right-3 ' style={{ transform: "translate(0%,-50%)" }} width="15" height="15" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3407 10.9082C10.6915 11.562 10.1659 11.1979 9.88444 10.9082L0.946022 1.94097C0.854334 1.84899 0.768446 1.76691 0.710289 1.64929C0.382917 0.98716 0.973585 0.273907 1.63114 0.542436C1.73323 0.584125 1.80264 0.641825 1.87936 0.718451L10.6112 9.44019L19.2251 0.813626C19.3197 0.718892 19.4096 0.630505 19.5325 0.572332C19.8952 0.400811 20.2751 0.554591 20.4749 0.838011C20.6254 1.05149 20.6737 1.33852 20.5354 1.61548C20.4586 1.76922 20.3319 1.88055 20.2124 2.00057L11.3407 10.9082Z" fill="#4F4F4F" />
                </svg>
            </li>
        </ul>
    )
}

export default DashboardFilters