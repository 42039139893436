import React, { Component, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axios';
import { BiLoaderAlt } from 'react-icons/bi'

const ChangePasswordForm = () => {

    {
        /**
            Purpose: The purpose of this program is to update password / change password
            Author:   Arslan Mukhtar
            Date Created:    2022-12-20
            Last Date Modified:  2022-12-21
            Modified By: Arslan Mukhtar
        */
    }

    const navigate = useNavigate()

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const CHANGE_PASSWORD = "/user/up";

    const [oldPassword, setOldPassword] = useState("")
    const [oldPasswordError, setOldPasswordError] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordError, setNewPasswordError] = useState("")
    const [apiResponseError, setApiResponseError] = useState("")
    const [loader, setLoader] = useState(false)


    const validateOldPassword = () => {
        if (oldPassword == "") {
            setOldPasswordError("Old Password can not be empty")
        } else {
            setOldPasswordError("")
        }
    }



    const validateNewPassword = () => {
        if (newPassword == "") {
            setNewPasswordError("New Password can not be empty")
        } else if (!mediumRegex.test(newPassword)) {
            setNewPasswordError("Please add uppercase, special characters to make Password Strong")
        } else if (!strongRegex.test(newPassword)) {
            setNewPasswordError("")
        } else {
            setNewPasswordError("")
        }
    }


    const changePassword = async () => {
        const userData = localStorage.getItem("userInfo")
        const user = JSON.parse(userData)
        setLoader(true)
        if (oldPasswordError == "" && newPasswordError == "") {
            const post_data = {
                "old_password": oldPassword,
                "new_password": newPassword,
                'token': user?.token
            }

            try {
                const response = await axios.post(
                    CHANGE_PASSWORD,
                    JSON.stringify(post_data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                if (!response.data.error) {
                    navigate('/profile')
                }
            } catch (err) {
                console.log('error ', err.response.data.message)
                setApiResponseError(err.response.data.message)
                //throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
            }
            setTimeout(() => {
                setLoader(false)
                setApiResponseError("")
            }, 10000)

        }
    }


    return (
        <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
            <h2 className='font-family-700 font-medium text-center primary-heading-color mb-8'>
                Change my Password
            </h2>
            <div className="flex align-middle justify-center flex-col items-center xl:w-1/4 lg:w-1/4 md:w-2/4 xl:px-0 lg:px-0 md:px-0 sm:px-6 px-6 sm:w-full w-full mx-auto">
                <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
                    <div className='relative w-full'>
                        <input
                            type="password"
                            autoComplete='off'
                            className={`form-control font-family-500 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border-solid ${oldPasswordError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} border  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setOldPassword(e.target.value)
                            }}
                            onBlur={validateOldPassword}
                            value={oldPassword}
                            id="oldpass"
                            placeholder={oldPasswordError != "" ? oldPasswordError : "Old Password"}
                        />
                        {
                            oldPasswordError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        oldPasswordError ?
                            <>
                                <span className={`text-red-600 ${oldPasswordError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        oldPasswordError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>
                <div className="flex relative flex-col mb-6 align-middle justify-between items-center w-full">
                    <div className='w-full relative'>
                        <input
                            type="password"
                            autoComplete='off'
                            className={`form-control font-family-500 font-medium block w-full px-8 py-2 xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl  bg-white bg-clip-padding border-solid ${newPasswordError != "" ? "border-red-500 placeholder:text-red-500 text-red-500  focus:border-red-500" : "border-gray-300 secondary-heading-color  focus:border-gray-300"} border  rounded-full transition ease-in-out focus:bg-white focus:outline-none`}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                            }}
                            onBlur={validateNewPassword}
                            value={newPassword}
                            id="newpass"
                            // value={username}
                            placeholder={newPasswordError != "" ? newPasswordError : "New Password"}
                        />
                        {
                            newPasswordError ?
                                <svg className='absolute top-1/2' style={{ right: "5%", transform: "translate(0px, -50%)" }} width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.02865 18.75C0.62934 18.75 0.334201 18.5764 0.143229 18.2292C-0.0477431 17.8819 -0.0477431 17.5347 0.143229 17.1875L9.77865 0.520833C9.96962 0.173611 10.2734 0 10.6901 0C11.1068 0 11.4106 0.173611 11.6016 0.520833L21.237 17.1875C21.428 17.5347 21.428 17.8819 21.237 18.2292C21.046 18.5764 20.7509 18.75 20.3516 18.75H1.02865ZM10.6901 7.29167C10.395 7.29167 10.1477 7.39132 9.94844 7.59062C9.74844 7.79062 9.64844 8.03819 9.64844 8.33333V11.4583C9.64844 11.7535 9.74844 12.0007 9.94844 12.2C10.1477 12.4 10.395 12.5 10.6901 12.5C10.9852 12.5 11.2328 12.4 11.4328 12.2C11.6321 12.0007 11.7318 11.7535 11.7318 11.4583V8.33333C11.7318 8.03819 11.6321 7.79062 11.4328 7.59062C11.2328 7.39132 10.9852 7.29167 10.6901 7.29167ZM10.6901 15.625C10.9852 15.625 11.2328 15.525 11.4328 15.325C11.6321 15.1257 11.7318 14.8785 11.7318 14.5833C11.7318 14.2882 11.6321 14.041 11.4328 13.8417C11.2328 13.6417 10.9852 13.5417 10.6901 13.5417C10.395 13.5417 10.1477 13.6417 9.94844 13.8417C9.74844 14.041 9.64844 14.2882 9.64844 14.5833C9.64844 14.8785 9.74844 15.1257 9.94844 15.325C10.1477 15.525 10.395 15.625 10.6901 15.625Z" fill="#EF4444" />
                                </svg>
                                : ""
                        }
                    </div>
                    {
                        newPasswordError ?
                            <>
                                <span className={`text-red-600 ${newPasswordError == "" ? "" : "pt-3"} block font-family-500 w-full font-medium text-sm`}>
                                    {
                                        newPasswordError
                                    }
                                </span>
                            </>
                            :
                            ""
                    }
                </div>
                <button
                    onClick={changePassword}
                    disabled={oldPasswordError || newPasswordError || loader}
                    className='primary-bg-color w-full mb-5 rounded-full px-8 py-2 text-white xl:text-xl lg:text-xl md:text-xl sm:text-2xl text-2xl font-family-500 font-medium flex align-middle justify-center items-center'>
                    Save
                    {
                        loader ?
                            <BiLoaderAlt className='loader-spinner ml-2' />
                            :
                            null
                    }
                </button>
                <a
                    onClick={() => {
                        navigate('/profile')
                    }}
                    className='secondary-heading-color disabled:opacity-80 disabled:cursor-not-allowed w-full text-center block mx-auto font-family-500 font-medium  xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl'>
                    <span
                        //  onClick={() => {
                        //     navigate('/candidate-login')
                        // }}
                        className='call-to-action-text-color font-family-500 font-medium xl:text-lg lg:text-lg md:text-lg sm:text-xl text-xl pl-2'>
                        Cancel
                    </span>
                </a>
                {
                    apiResponseError ?
                        <span className='text-red-600 text-sm font-family-500 font-medium pb-3 block w-full'>
                            {apiResponseError}
                        </span>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default ChangePasswordForm