import CallToActionBanner from "../../components/CallToActionBanner"
import CountryList from "../../components/CountryList"
import CountryPageBanner from "../../components/CountryPageBanner"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import CanadaBanner from '../../assets/images/canada-banner.png';
import CountryReasonCard from "../../components/CountryReasonCard"
import WhyCanada from '../../assets/images/why-canada.png'
import CanadaHiring from '../../assets/images/hiring-canada.png'
import CanadaTeam from '../../assets/images/canada-team.png'
import ServicesCard from "../../components/ServicesCard"


const Canada = () => {


    {
        /**
            Purpose: The purpose of this program is to show Canada Country Page
            Author:   Arslan Mukhtar
            Date Created:    2022-12-19
            Last Date Modified:  2022-12-19
            Modified By: Arslan Mukhtar
        */
    }

    return (
        <>
            <Header bgColor={true} />
            <CountryPageBanner image={CanadaBanner} />
            <div className="main-container">
                <h1 className="text-center xl:mt-20 lg:mt-14 md:mt-10 sm:mt-10 mt-10  xl:mb-10 lg:mb-8 md:mb-6 sm:mb-6 mb-6  font-semibold xl:text-5xl lg:text-5xl font-family-700 primary-heading-color">
                    Work with us to access untapped tech talent.
                </h1>
                <p className="text-center xl:w-3/5 lg:w-4/5 md:w-full sm:w-full w-full mx-auto primary-heading-color font-normal font-family-300 text-2xl">
                    Based in Canada, with offices in central Europe, Latin America and select global locations, we bring highly qualified talent to our clients across Nort America.
                </p>
            </div>
            <CountryReasonCard position="left" image={WhyCanada} title={"Why Canada"} description="Canada with its diverse population has not only become the center of integration for various cultures but is at the forefront of creating an environment of growth, prosperity and inclusiveness. This uniquemix has also given Canada a unique position where it is able to accommodate and assimilate talent from all over the world and has created an ecosystem of growth and entrepreneurship. " />
            <CountryReasonCard position="right" image={CanadaHiring} title={"Simplifying Hiring in Canada"} description="Establishing a company, hiring an accounting firm, paying for a virtual or a physical space are all expenses that an organization does not need to incur when all you want is to hire a Tech Professional who will work remotely from the comfort of his/her home." />
            <CountryReasonCard position="left" image={CanadaTeam} title={"Our Team"} description="With Enthos Talent team you don’t have to worry about recruiting, hiring, payroll processing, asset management, and all the tasks that need to performed to setup a remote team member. " />
            <div className="main-container">
                <h2 className="text-center font-family-700 font-semibold xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10 primary-heading-color">
                    Services In Canada
                </h2>
            </div>
            <ServicesCard />
            <div className="main-container">
                <div className="xl:py-20 lg:py-14 md:py-10 py-10">
                    <h2 className='primary-heading-color font-semibold text-center xl:mb-20 lg:mb-14 md:mb-10 mb-10'>
                        Countries, where we work
                    </h2>
                    <CountryList />
                </div>
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </>
    )
}

export default Canada