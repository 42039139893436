import React, { Component, useEffect, useState } from 'react'
import CallToActionBanner from '../../components/CallToActionBanner'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import axios from '../../api/axios'
import CartJobListing from '../../components/CartJobListing'
import { useNavigate } from 'react-router-dom'
import ProtectedRoute from '../../components/ProtectedRoute'

const CandidateCart = ({ job }) => {

    const CART_API_URL = '/carts'
    const JOBSLIST = "alljobs"
    const GET_API_URL = 'candidate'
    const EMPTY_CART = "cart_empty"
    const APPLY_FOR_JOB = "apply_job"

    const navigate = useNavigate()

    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)

    const [cartJobs, setCartJobs] = useState([])
    const [jobList, setJobList] = useState([])
    const [finalJobList, setFinalJobList] = useState([])
    const [reloadPage, setReloadPage] = useState(false)

    const fetchCartJobs = async () => {
        try {
            const response = await axios.get(CART_API_URL, {
                params: {
                    token: userInfo?.token
                }
            });
            setCartJobs(response.data)
        }
        catch (error) {
            console.log(error);
        }
        setReloadPage(false)
    }


    const fetchJobList = async () => {
        try {
            const response = await axios.get(JOBSLIST,
                {
                    params: {
                        token: userInfo?.token
                    }
                },
            );
            setJobList(response.data?.reverse())
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCartJobs()
        fetchJobList()
    }, [])

    const ClearAllCart = async () => {

        const response = await axios.get(
            GET_API_URL,
            {
                params: {
                    token: userInfo?.token
                }
            },
            // JSON.stringify(post_data),
            {
                headers: {
                    "Content-Type": "application/json",
                    // "token": post_data.token,
                    // 'Authorization': `token ${post_data.token}`
                },
            }
        )

        try {
            const emptyCartResponse = await axios.post(EMPTY_CART, {
                user_id: response?.data?.user?.id,
                token: userInfo?.token
            });
            refetchJobsForHeader(true)
            console.log('emptyCartResponse ', emptyCartResponse?.data)
        }
        catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        console.log('cartJobs ', cartJobs)
        if (jobList?.length > 0) {
            const fList = []
            for (let x = 0; x < cartJobs?.length; x++) {
                const filtered = jobList?.filter((item) => {
                    if (item?.id == cartJobs[x]?.job_id) {
                        return item
                    }
                })
                fList.push(filtered[0])
            }
            setFinalJobList(fList)
        }
    }, [cartJobs, jobList])


    const ApplyForJob = async () => {
        const response = await axios.get(
            GET_API_URL,
            {
                params: {
                    token: userInfo?.token
                }
            },
            // JSON.stringify(post_data),
            {
                headers: {
                    "Content-Type": "application/json",
                    // "token": post_data.token,
                    // 'Authorization': `token ${post_data.token}`
                },
            }
        )
        console.log('finalJobList ', finalJobList)
        try {
            let jobIds = []
            for (let x = 0; x < finalJobList?.length; x++) {
                const filtered = jobList?.filter((item) => {
                    if (item?.id == finalJobList[x]?.job_id) {
                        return item
                    }
                })
                // console.log('filtered[0]?.job_cities ', filtered)
                jobIds.push(finalJobList[x]?.id)
            }

            const apply = await axios.post(APPLY_FOR_JOB, {
                user_id: response?.data?.user?.id,
                token: userInfo?.token,
                city_id: 0,
                job_id: jobIds,
            });
            const emptyCartResponse = await axios.post(EMPTY_CART, {
                user_id: response?.data?.user?.id,
                token: userInfo?.token
            });
            navigate('/thank-you')
        }
        catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        console.log('reload Page ', reloadPage)
        if (reloadPage) {
            fetchCartJobs()
        }
    }, [reloadPage])

    const refetchJobsForHeader = (props) => {
        console.log('props ', props)
        setReloadPage(props)
    }

    return (
        <>
            <ProtectedRoute>
                <Header countFromParent={cartJobs?.length} bgColor={true} />
                <div className='xl:py-20 lg:py-20 md:py-10 sm:py-10  py-10'>
                    <div className='main-container'>
                        <h2 className='font-family-700 flex align-middle justify-center items-center gap-5 font-medium text-center primary-heading-color mb-8'>
                            {/* <svg width="48" height="46" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.056 29.0649C17.8911 29.3698 18.3209 30.2939 18.016 31.129L16.4461 35.4285H40.3183C41.2073 35.4285 41.928 36.1492 41.928 37.0382C41.928 37.9272 41.2073 38.6478 40.3183 38.6478H15.9859C13.9736 38.6478 12.574 36.647 13.2642 34.7567L14.9919 30.0248C15.2968 29.1898 16.221 28.76 17.056 29.0649Z" fill="#FFB538" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.382715 1.96708C0.73567 1.15116 1.68323 0.775853 2.49916 1.12881L9.28933 4.06614C10.1745 4.44906 10.8112 5.24735 10.9875 6.19557L15.1753 28.7112H39.0431L44.4128 12.2879L16.9707 10.9349C16.0828 10.8911 15.3985 10.1359 15.4423 9.24795C15.4861 8.36003 16.2414 7.67573 17.1293 7.71951L44.9926 9.0933C46.9013 9.18741 48.1977 11.0712 47.6038 12.8876L42.0305 29.9336C41.641 31.1248 40.5298 31.9306 39.2765 31.9306H14.9078C13.5119 31.9306 12.3145 30.9353 12.0592 29.563L7.85383 6.95279L1.22099 4.08352C0.405068 3.73057 0.0297589 2.78301 0.382715 1.96708Z" fill="#FFB538" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9456 43.548C36.0679 43.548 36.9777 42.6382 36.9777 41.5159C36.9777 40.3936 36.0679 39.4838 34.9456 39.4838C33.8233 39.4838 32.9135 40.3936 32.9135 41.5159C32.9135 42.6382 33.8233 43.548 34.9456 43.548ZM34.9456 45.9947C37.4191 45.9947 39.4243 43.9894 39.4243 41.5159C39.4243 39.0423 37.4191 37.0371 34.9456 37.0371C32.472 37.0371 30.4668 39.0423 30.4668 41.5159C30.4668 43.9894 32.472 45.9947 34.9456 45.9947Z" fill="#FFB538" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8674 43.548C20.9897 43.548 21.8995 42.6382 21.8995 41.5159C21.8995 40.3936 20.9897 39.4838 19.8674 39.4838C18.7452 39.4838 17.8354 40.3936 17.8354 41.5159C17.8354 42.6382 18.7452 43.548 19.8674 43.548ZM19.8674 45.9947C22.341 45.9947 24.3462 43.9894 24.3462 41.5159C24.3462 39.0423 22.341 37.0371 19.8674 37.0371C17.3939 37.0371 15.3887 39.0423 15.3887 41.5159C15.3887 43.9894 17.3939 45.9947 19.8674 45.9947Z" fill="#FFB538" />
                            </svg> */}
                            <svg width="35" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.9903 3.2181C16.0355 1.18263 13.3209 0.00442802 10.4536 0C4.88466 0 0 5.18133 0 11.0872C0 16.843 3.25005 22.7711 9.39934 28.2311C12.2485 30.7561 15.3739 32.9509 18.7158 34.7745C18.8983 34.8686 19.1148 34.8686 19.2974 34.7745C22.6381 32.9509 25.7625 30.7557 28.6107 28.2311C34.7631 22.7711 38.0131 16.843 38.0131 11.0872C38.0131 5.18133 33.1285 0 27.5595 0C24.6923 0.00442802 21.9776 1.18263 20.0228 3.2181C19.656 3.60008 19.3159 4.01224 19.0066 4.4525C18.6973 4.01224 18.3572 3.60008 17.9903 3.2181ZM27.5607 1.54867C24.6595 1.55355 21.9417 2.96875 20.2738 5.34278L19.0066 7.14645L17.7394 5.34278C16.0715 2.96875 13.3537 1.55355 10.4525 1.54867C5.82391 1.5494 1.54867 5.94989 1.54867 11.0872C1.54867 16.2368 4.46329 21.7769 10.4265 27.0721C13.0597 29.4057 15.9364 31.4485 19.0065 33.1658C22.075 31.4487 24.9506 29.4059 27.5827 27.0728C33.5496 21.7774 36.4645 16.237 36.4645 11.0872C36.4645 5.94989 32.1892 1.5494 27.5607 1.54867Z"
                                    fill="#FFB538"
                                ></path>
                            </svg>
                            My WishList
                        </h2>
                        {
                            finalJobList?.length > 0
                                ?
                                finalJobList?.map((item, index) => {
                                    return (
                                        <CartJobListing refetchJobsForHeader={refetchJobsForHeader} refresh={fetchCartJobs} job={item} key={index} />
                                    )
                                })
                                :
                                <p
                                    onClick={() => {
                                        navigate('/find-job')
                                    }}
                                    className='secondary-heading-color cursor-pointer text-center block w-full my-2'>
                                    No Jobs Found!
                                </p>
                        }
                        {
                            finalJobList?.length > 0
                                ?
                                <>
                                    <a
                                        onClick={() => {
                                            navigate('/find-job')
                                        }}
                                        className='flex align-middle mb-5 justify-center px-22 rounded-full w-max border call-to-action-border-color bg-white mx-auto cursor-pointer h-11'>
                                        <button className='call-to-action-color  text-center text-xl font-family-500 font-medium'>
                                            Find another Job
                                        </button>
                                    </a>
                                    <a className='flex align-middle justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                                        <button
                                            onClick={ApplyForJob}
                                            className='text-white text-center text-xl font-family-500 font-medium'>
                                            Apply All
                                        </button>
                                    </a>
                                    <a
                                        onClick={() => {
                                            ClearAllCart()
                                            // navigate('/find-job')
                                        }}
                                        className='flex align-middle mt-5 justify-center px-22 rounded-full w-max border call-to-action-border-color bg-white mx-auto cursor-pointer h-11'>
                                        <button className='call-to-action-color  text-center text-xl font-family-500 font-medium'>
                                            Clear All
                                        </button>
                                    </a>
                                </>
                                :
                                null
                        }
                    </div>
                </div>

                <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
                <Footer />
            </ProtectedRoute>
        </>
    )
}

export default CandidateCart