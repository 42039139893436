import React, { Component, useEffect, useState } from 'react'
import axios from '../../api/axios'
import CallToActionBanner from "../../components/CallToActionBanner"
import DashboardCounter from "../../components/DashboardCouter"
import DashboardFilters from "../../components/DashboardFilters"
import DashboardHeader from "../../components/DashboardHeader"
import Footer from "../../components/Footer"
import ProtectedRoute from "../../components/ProtectedRoute"
import DashboardRecommendedJobs from '../../components/DashboardRecommendedJobs'

const EmployerDashboard = () => {
    const JOBSLIST = "alljobs"
    const [jobList, setJobList] = useState([])
    const localUser = localStorage.getItem('userInfo')
    const userInfo = JSON.parse(localUser)

    const fetchJobList = async () => {
        try {
            const response = await axios.get(JOBSLIST,
                {
                    params: {
                        token: userInfo?.token
                    }
                },
            );
            setJobList(response.data?.reverse())
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchJobList()
    }, [])

    return (
        <ProtectedRoute >
            <DashboardHeader bgColor={true} />
            <DashboardCounter />
            <div className="main-container">
                <div className="flex mb-6 align-middle justify-between">
                    <h3 className='font-family-500  font-semibold text-3xl primary-heading-color'>Open Positions</h3>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </div>
                        <input type="search" class="focus:border-gray-300 hover:border-gary-400 outline-none  rounded-full h-10 flex align-middle justify-center items-center border font-family-500 font-medium secondary-heading-colorI border-gray-300 px-10" required placeholder="Search Jobs" />
                    </div>
                </div>
                <hr className=" bg-white border-t-2 w-full border-gray-300 my-0 block" />
                <DashboardFilters />
                <hr className=" bg-white border-t-2 mb-10 w-full border-gray-300 my-0 block" />
                {
                    jobList?.length > 0 ?
                        jobList?.map((item, index) => {
                            return (
                                <>
                                    <DashboardRecommendedJobs key={index} job={item} bgColored={false} />
                                    <hr key={index} className='border mt-10 mb-14' />
                                </>
                            )
                        })
                        :
                        null
                    // <RecommendedJobs />                    
                }
                {/* <a className='flex align-middle xl:mb-20 lg:mb-14 md:mb-10 sm:mb-10 mb-10 justify-center px-22 rounded-full w-max secondary-border-color primary-bg-color mx-auto cursor-pointer h-11'>
                    <button className='text-white text-center text-xl font-family-500 font-medium'>
                        See more
                    </button>
                </a> */}
            </div>
            <CallToActionBanner bgColored={true} mainHeading="Enthos Talent Makes growing
                    remote teams effortlessly" secondaryHeading="Ready to get Started?" textColor="#fff" />
            <Footer />
        </ProtectedRoute>
    )
}

export default EmployerDashboard