import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImage from '../assets/images/banner.png';
const CountryPageBanner = ({ image }) => {
    const navigate = useNavigate()
    return (
        <img  src={image} className="w-full country-banner" alt="" />
    )
}

export default CountryPageBanner