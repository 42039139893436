import React, { Component } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyLogo1 from '../assets/images/company-card.png'
import CompanyImage1 from '../assets/images/company-image.png'
const ServicesCard = () => {

    const servicesArray = [
        {
            title: "Recruiting",
            description: "With Enthos Talent team you don’t have to worry about recruiting, hiring, payroll processing, asset management, and all the tasks that need to performed to setup a remote team member."
        },
        {
            title: "Employer of Record",
            description: "With Enthos Talent team you don’t have to worry about recruiting, hiring, payroll processing, asset management, and all the tasks that need to performed to setup a remote team member."
        },
        {
            title: "Background and Reference Check",
            description: "With Enthos Talent team you don’t have to worry about recruiting, hiring, payroll processing, asset..."
        },
        {
            title: "Asset Management",
            description: "With Enthos Talent team you don’t have to worry about recruiting, hiring, payroll processing, asset management, and all the tasks that need to performed to setup a..."
        }
    ]

    return (
        <div className="inner-main-container ">
            <div className="grid xl:grid-cols-4 xl:gap-8 lg:grid-cols-4 lg:gap-6  md:grid-cols-3 md:gap-6  sm:grid-cols-2 sm:gap-10  grid-cols-1 gap-10">
                {
                    servicesArray?.map((item, index) => {
                        return (
                            <div className="services-card xl:py-9 xl:px-4 lg:py-7 lg:px-4 md:py-7 md:px-4 sm:py-10 sm:px-5 py-10 px-5  bg-white">
                                <h3 className='primary-heading-color xl:mb-7 lg:mb-6 md:mb-5 sm:mb-5 mb-5 font-family-500 font-semibold '>
                                    {item?.title}
                                </h3>
                                <p className='secondary-heading-color text-xl font-family-500 font-normal'>
                                    {
                                        item?.description
                                    }
                                </p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ServicesCard